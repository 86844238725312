import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import { useEffectAsync } from 'helpers/utils';
import { useDefault } from 'hooks';
import BackDrop from 'components/templates/BackDrop';
import UnsolvedTickets from 'components/dashboards/unsolved-tickets/UnsolvedTickets';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';

const Programs = () => {
  const [careersDisappear, setCareersDisappear] = useState();

  // const [top10, setTop10] = useState({});
  const [salary, setSalary] = useState();

  const { list } = useDefault();
  let columns = [
    {
      accessor: 'name',
      Header: 'Nombre',
      headerProps: { className: 'ps-2 text-900', style: { height: '46px' } },
      cellProps: {
        className: 'py-2 white-space-nowrap pe-3 pe-xxl-4 ps-2'
      },
      Cell: rowData => {
        const { name, avatar } = rowData.row.original;
        return (
          <Flex alignItems="center" className="position-relative py-1">
            {avatar.img ? (
              <Avatar src={avatar.img} size="xl" className="me-2" />
            ) : (
              <Avatar size="xl" name={avatar.name} className="me-2" />
            )}
            <h6 className="mb-0">{name}</h6>
          </Flex>
        );
      }
    },
    {
      accessor: 'subject',
      Header: 'SNIES',
      headerProps: { style: { minWidth: '14.625rem' }, className: 'text-900' },
      cellProps: {
        className: 'py-2 pe-4'
      }
    },
    {
      accessor: 'city',
      Header: 'Ciudad',
      headerProps: { style: { minWidth: '14.625rem' }, className: 'text-900' },
      cellProps: {
        className: 'py-2 pe-4'
      }
    },
    {
      accessor: 'university',
      Header: 'Universidad',
      headerProps: { style: { minWidth: '14.625rem' }, className: 'text-900' },
      cellProps: {
        className: 'py-2 pe-4'
      }
    }
  ];
  let columns2 = [
    {
      accessor: 'name',
      Header: 'Nombre',
      headerProps: { className: 'ps-2 text-900', style: { height: '46px' } },
      cellProps: {
        className: 'py-2 white-space-nowrap pe-3 pe-xxl-4 ps-2'
      },
      Cell: rowData => {
        const { name, avatar } = rowData.row.original;
        return (
          <Flex alignItems="center" className="position-relative py-1">
            {avatar.img ? (
              <Avatar src={avatar.img} size="xl" className="me-2" />
            ) : (
              <Avatar size="xl" name={avatar.name} className="me-2" />
            )}
            <h6 className="mb-0">{name}</h6>
          </Flex>
        );
      }
    },
    {
      accessor: 'subject',
      Header: 'SNIES',
      headerProps: { style: { minWidth: '14.625rem' }, className: 'text-900' },
      cellProps: {
        className: 'py-2 pe-4'
      }
    },
    {
      accessor: 'salary',
      Header: 'Salario',
      headerProps: { style: { minWidth: '14.625rem' }, className: 'text-900' },
      cellProps: {
        className: 'py-2 pe-4'
      }
    },
    {
      accessor: 'city',
      Header: 'Ciudad',
      headerProps: { style: { minWidth: '14.625rem' }, className: 'text-900' },
      cellProps: {
        className: 'py-2 pe-4'
      }
    },
    {
      accessor: 'university',
      Header: 'Universidad',
      headerProps: { style: { minWidth: '14.625rem' }, className: 'text-900' },
      cellProps: {
        className: 'py-2 pe-4'
      }
    }
  ];

  useEffectAsync(async () => {
    const CareersDisappear = () => {
      list('dashboard_university/data_program_disappear').then(data => {
        if (data) {
          setCareersDisappear(data.disappear);
        }
      });
    };
    const SalaryPrograms = () => {
      list('dashboard_university/data_program_salary').then(data => {
        if (data) {
          setSalary(data.salary);
        }
      });
    };
    CareersDisappear();
    SalaryPrograms();
  }, []);
  if (salary === undefined || careersDisappear === undefined)
    return <BackDrop param={' '} />;
  if (careersDisappear !== undefined)
    return (
      <>
        <Row className="g-3 mb-3">
          <Col xl={12}>
            <UnsolvedTickets
              title="Carreras a Desaparecer"
              data={careersDisappear}
              columns={columns}
            />
          </Col>
          {/* <Col xl={12}>
            <UnsolvedTickets title="Programas Top 10" data={top10.tops} />
          </Col> */}
          <Col xl={12}>
            <UnsolvedTickets
              title="Salario por Programas"
              data={salary}
              columns={columns2}
            />
          </Col>
        </Row>
      </>
    );
};

export default Programs;
