import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

export const http = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api`
});

export function useAxios() {
  const http = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_URL}/api`
  });

  const intervalId = useRef(null);
  const [connected, setConnected] = useState(true);

  http.interceptors.response.use(
    response => {
      if (!connected) {
        console.log('reconnected');
        setConnected(true);
        toast.success('The connection to the server has been re-established!');
      }
      return response;
    },
    error => {
      if (error.code === 'ERR_NETWORK' && connected) {
        console.log('disconnected');
        setConnected(false);
        toast.error('The connection to the server has been lost!');
        intervalId.current = window.setInterval(
          () => fetchData(error.code),
          5000
        ); // iniciar el intervalo
      }
      return Promise.reject(error);
    }
  );

  const fetchData = async res => {
    if (res !== 'ERR_NETWORK') {
      if (intervalId.current !== null) {
        clearInterval(intervalId.current);
        intervalId.current = null;
      }
    } else {
      await http('/data');
    }
  };

  useEffect(() => {
    return () => {
      if (intervalId.current !== null) {
        clearInterval(intervalId.current);
      }
    };
  }, []);

  return http;
}
