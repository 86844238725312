import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import Buttons from '../templates/Buttons';
import PropTypes from 'prop-types';

const DimensionsForm = ({ submit, item, items }) => {
  const [availabledMsg, setAvailabledMsg] = useState('');
  const [availabled, setAvailabled] = useState(true);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors }
  } = useForm({ defaultValues: { id: null } });

  //*EVENT CLICK SAVE AND UPDATE
  const onSubmit = data => {
    submit(data);
    reset();
  };

  //* EVENT GET ALL RGISTER
  useEffect(() => {
    if (item._id) {
      setValue('id', item._id);
      setValue('name', item.name);
    }
  }, [watch('id')]);

  useEffect(() => {
    if (!item?._id || item?.name !== watch('name')) {
      setAvailabled(true);
      setAvailabledMsg('');
      items.forEach(dat => {
        if (dat.name === watch('name')) {
          setAvailabled(false);
          setAvailabledMsg('Este nombre está en uso actualmente!');
        }
      });
    }
  }, [watch('name')]);

  return (
    <Row>
      <Col>
        <Form noValidate onSubmit={handleSubmit(onSubmit)} className="px-1">
          <div id="name-row" className="mb-3">
            <Form.Group>
              <Form.Label className="mb-1">Nombre (*)</Form.Label>
              <Form.Control
                size="sm"
                type="text"
                id="name"
                name="name"
                placeholder="Nombre del Sector"
                isInvalid={!!errors.name}
                {...register('name', {
                  required: {
                    value: true,
                    message: 'este campo es requerido!'
                  },
                  minLength: {
                    limit: 1,
                    value: 1,
                    message: 'Mínimo 1 carácter!'
                  }
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name && errors.name.message}
              </Form.Control.Feedback>
              {availabled ? null : (
                <h6 className="text-danger fs--2 mt-2 mb-0">{availabledMsg}</h6>
              )}
            </Form.Group>
          </div>

          <Buttons
            btn={'create'}
            caption={watch('id') ? 'Actualizar Datos' : 'Guardar Datos'}
          />
        </Form>
      </Col>
    </Row>
  );
};

DimensionsForm.propTypes = {
  submit: PropTypes.func.isRequired,
  item: PropTypes.object,
  items: PropTypes.array
};

export default DimensionsForm;
