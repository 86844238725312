import React, { useEffect, useState, createContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { useDefault, http } from 'hooks';
import headers from 'config/header';

import Buttons from 'components/templates/Buttons';
import Form_ from 'components/forms/auth/UserForm';
import { SwitchDt } from 'components/SwitchDt';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import Avatar from 'components/common/Avatar';
import { Form } from 'react-bootstrap';
import { useEffectAsync } from 'helpers/utils';

const ROLE = ['super', 'admin', 'guest', 'student'];

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [item, setItem] = useState({});
  const [items, setItems] = useState([]);
  const [doctypes, setDoctypes] = useState([]);

  const {
    setLoading,
    handleEModal,
    handleDModal,
    handleCModal,
    list,
    add,
    update,
    remove,
    status
  } = useDefault();

  const navigate = useNavigate();

  //==============================
  const columns = [
    {
      accessor: 'name',
      Header: 'Nombre',
      Cell: rowData => {
        const { name, lastname } = rowData.row.original;
        return (
          <div className="d-flex align-items-center">
            <Avatar size="l" name={name} />
            <div className="ms-2">{name + ' ' + lastname}</div>
          </div>
        );
      }
    },
    {
      accessor: 'phone',
      Header: 'Teléfono'
    },
    {
      accessor: 'email',
      Header: 'Correo',
      Cell: rowData => {
        const { email } = rowData.row.original;
        return (
          <Link
            className="link-primary font-sans-serif fs--1 d-inline-block"
            to={`mailto:${email}`}
          >
            {/* {email.split('@')[0]}@... */}
            {email}
          </Link>
        );
      }
    },
    {
      accessor: 'role',
      Header: 'Rol',
      Cell: rowData => {
        const { role } = rowData.row.original;
        return (
          <Form.Select
            size="sm"
            id="role"
            defaultValue={role}
            className="w-75 border-0 bg-transparent cursor-pointer"
            onChange={e => {
              rowData.row.original.role = e.target.value;
              changeRole(rowData.row.original);
            }}
          >
            {ROLE.map(dat => (
              <option key={dat}>{dat}</option>
            ))}
          </Form.Select>
        );
      }
    },
    {
      accessor: 'status',
      Header: 'Estado',
      Cell: rowData => {
        const { status } = rowData.row.original;
        return status ? (
          <SoftBadge pill bg="success">
            Habilitado
            <FontAwesomeIcon icon="check" className="ms-2" />{' '}
            {/* ban / redo / stream */}
          </SoftBadge>
        ) : (
          <SoftBadge pill bg="secondary" className="me-2">
            Deshabilitado
            <FontAwesomeIcon icon="ban" className="ms-2" />
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'btn',
      Header: 'Acciones',
      Cell: rowData => {
        const { _id, status } = rowData.row.original;
        return (
          <Flex className="gap-1 mt-1.5">
            {' '}
            {/** justifyContent={'end'} */}
            <SwitchDt
              id={_id}
              status={status === 0 ? false : true}
              pt={false}
              tx={'Deshabilitar'}
              tx0={'Habilitar'}
              handledEvent={() => {
                setItem(rowData.row.original);
                handleCModal();
              }}
            />
            <Buttons
              btn={'change'}
              handleEvent={() => {
                rowData.row.original.pt = true;
                setItem(rowData.row.original);
                handleEModal();
              }}
            />
            <Buttons
              btn={'update'}
              handleEvent={() => {
                rowData.row.original.pt = false;
                setItem(rowData.row.original);
                handleEModal();
              }}
            />
            <Buttons
              btn={'delete'}
              handleEvent={() => {
                setItem(rowData.row.original);
                handleDModal();
              }}
            />
          </Flex>
        );
      }
    }
  ];
  //==============================

  useEffectAsync(async () => {
    try {
      setLoading(true);
      const { data } = await http('user/profile', headers());
      setAuth(data);
      setIsAuthenticated(true);
    } catch (e) {
      setAuth({});
    } finally {
      setLoading(false);
    }
  }, []);

  const signIn = async object_ => {
    try {
      const { data } = await http.post('user/login', object_);
      localStorage.setItem('token', data.data.user_token);
      setAuth(data.data);
      setIsAuthenticated(true);
      setLoading(false);
      navigate('/dashboard');
    } catch (e) {
      toast.error(e.response.data.message);
    }
  };

  const signOut = () => {
    localStorage.removeItem('token');
    setAuth({});
    setIsAuthenticated(false);
  };
  //==============================

  //* FUNTION FOR SAVE OR MODIFY REGISTER
  const submit = object_ => {
    if (object_.id) {
      if (object_.university_profile === '') object_.university_profile = null;
      object_._id = object_.id;

      update('user/update', object_).then(res => {
        res &&
          setItems(
            items.map(stateObj => (stateObj._id === res._id ? res : stateObj))
          );
        setItem({});
      });
    } else {
      if (object_.university_profile === '') object_.university_profile = null;
      add('user/add', object_).then(res => res && setItems([...items, res]));
    }
  };

  //* GET ALL REGISTER

  useEffect(() => {
    list('user/all').then(
      res =>
        res && setItems(res.filter(stateObj => stateObj.email !== 'master'))
    );
    list('documenttype/all').then(res => res && setDoctypes(res));
  }, [isAuthenticated]);

  //* DELETE
  const removeOne = () => {
    remove(`user/delete/${item._id}`).then(() => {
      setItems(items.filter(stateObj => stateObj._id !== item._id));
      setItem({});
    });
  };

  const changeRole = async object_ => {
    try {
      const { data } = await http.put('user/update', object_, headers());

      //! Async width .State.
      const updateObj = items.map(stateObj =>
        stateObj._id === data._id ? data.data : stateObj
      );
      setItems(updateObj);
      toast.success('El rol ha sido actualizado con éxito!');
    } catch (e) {
      console.log(e);
    }
  };

  const changeStatus = async () => {
    item.status = item.status ? 0 : 1;
    status(`user/update`, item).then(res => {
      res &&
        setItems(
          items.map(stateObj => (stateObj._id === res._id ? res : stateObj))
        );
      setItem({});
    });
  };

  //* CHANGE PASSWORD
  const changePassword = async object_ => {
    try {
      const { data } = await http.post(
        // `user/modify-user/${auth._id}`,
        `user/update/${auth._id}`,
        object_,
        headers()
      );

      !data.error ? toast.success(data.msg) : toast.error(data.msg);
      return data.error;
    } catch (e) {
      console.log(e);
    }
  };

  const sendEmail = async object_ => {
    try {
      await http.post(`user/reset_password`, object_);

      toast.success(
        `Hemos enviado un correo a ${object_.email} con el link para restablecer su contraseña!`,
        { theme: 'colored' }
      );
    } catch (e) {
      toast.error(e.response.data.message);
    }
  };

  const resetPassword = async object_ => {
    try {
      await http.post(`user/recover_password`, object_);

      toast.success(`Su contraseña ha sido cambiada!`, {
        theme: 'colored'
      });
      setTimeout(() => {
        navigate('/');
      }, 5000);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        // .Sign.
        auth,
        setAuth,
        signIn,
        signOut,
        isAuthenticated,
        // .gestion-user.
        item,
        items,
        setItem,
        setItems,
        columns,
        Form_,
        caption: 'Usuario',
        captions: 'Usuarios',
        confirm: true,
        submit,
        doctypes,
        removeOne,
        changeStatus,
        changePassword,
        sendEmail,
        resetPassword
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { AuthProvider };
export default AuthContext;
