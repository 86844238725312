import React from 'react';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
// import SubtleBadge from 'components/common/SubtleBadge';
import { Col, Form, Row } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const PrioritySelect = ({ title, color, data, className, style }) => {
  return (
    <div
      style={style}
      className={classNames('d-flex align-items-center gap-2', className)}
    >
      <div style={{ '--falcon-circle-progress-bar': data }}>
        <svg
          className="circle-progress-svg"
          width="26"
          height="26"
          viewBox="0 0 120 120"
        >
          <circle
            className="progress-bar-rail"
            cx="60"
            cy="60"
            r="54"
            fill="none"
            strokeLinecap="round"
            strokeWidth="12"
          ></circle>
          <circle
            className="progress-bar-top"
            cx="60"
            cy="60"
            r="54"
            fill="none"
            strokeLinecap="round"
            stroke={color}
            strokeWidth="12"
          ></circle>
        </svg>
      </div>
      <h6 className="mb-0 text-700">{title}</h6>
    </div>
  );
};

PrioritySelect.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  data: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

const AgentSelect = ({ agent, className, style }) => {
  return (
    <Form.Select
      style={style}
      className={className}
      size="sm"
      defaultValue={agent}
    >
      {['Select Agent', 'Anindya', 'Nowrin', 'Khalid'].map(item => (
        <option key={item}>{item}</option>
      ))}
    </Form.Select>
  );
};

AgentSelect.propTypes = {
  agent: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object
};

export const columns = [
  {
    accessor: 'name',
    Header: 'Nombre',
    headerProps: { className: 'ps-2 text-900', style: { height: '46px' } },
    cellProps: {
      className: 'py-2 white-space-nowrap pe-3 pe-xxl-4 ps-2'
    },
    Cell: rowData => {
      const { name, avatar } = rowData.row.original;
      return (
        <Flex alignItems="center" className="position-relative py-1">
          {avatar.img ? (
            <Avatar src={avatar.img} size="xl" className="me-2" />
          ) : (
            <Avatar size="xl" name={avatar.name} className="me-2" />
          )}
          <h6 className="mb-0">{name}</h6>
        </Flex>
      );
    }
  },
  {
    accessor: 'subject',
    Header: 'SNIES',
    headerProps: { style: { minWidth: '14.625rem' }, className: 'text-900' },
    cellProps: {
      className: 'py-2 pe-4'
    }
    // Cell: rowData => {
    //   const { subject } = rowData.row.original;
    //   return (
    //     <Link to="/support-desk/tickets-preview" className="fw-semibold">
    //       {subject}
    //     </Link>
    //   );
    // }
  },
  {
    accessor: 'price',
    Header: 'Precio',
    headerProps: { style: { minWidth: '14.625rem' }, className: 'text-900' },
    cellProps: {
      className: 'py-2 pe-4'
    }
  },
  {
    accessor: 'city',
    Header: 'Ciudad',
    headerProps: { style: { minWidth: '14.625rem' }, className: 'text-900' },
    cellProps: {
      className: 'py-2 pe-4'
    }
  }
  // {
  //   accessor: 'status',
  //   Header: 'Status',
  //   headerProps: { className: 'text-900' },
  //   cellProps: {
  //     className: 'fs-9 pe-4'
  //   },
  //   Cell: rowData => {
  //     const { status } = rowData.row.original;
  //     return (
  //       <SubtleBadge bg={status.type} className="me-2">
  //         {status.content}
  //       </SubtleBadge>
  //     );
  //   }
  // }
  // {
  //   accessor: 'priority',
  //   Header: 'Priority',
  //   headerProps: { className: 'text-900' },
  //   cellProps: {
  //     className: 'pe-4'
  //   },
  //   Cell: rowData => {
  //     const { priority } = rowData.row.original;
  //     return (
  //       <PrioritySelect
  //         title={priority.title}
  //         color={priority.color}
  //         data={priority.data}
  //       />
  //     );
  //   }
  // }
  // {
  //   accessor: 'agent',
  //   Header: 'Agent',
  //   headerProps: { className: 'text-end text-900' },
  //   Cell: rowData => {
  //     const { agent } = rowData.row.original;
  //     return <AgentSelect agent={agent} className="w-auto ms-auto" />;
  //   }
  // }
];

export const CardLayout = ({ data }) => {
  return (
    <div className="d-flex flex-column gap-3">
      {data.map((ticket, index) => (
        <div
          key={index}
          className="bg-white dark__bg-1100 d-md-flex d-xl-inline-block d-xxl-flex align-items-center p-x1 rounded-3 shadow-sm card-view-height w-100"
        >
          <div className="d-flex align-items-start align-items-sm-center">
            {/* <Form.Check
              type="checkbox"
              id="inboxBulkSelect"
              className="fs-9 form-check me-2 me-xxl-3 mb-0"
            >
              <Form.Check.Input
                type="checkbox"
                checked={isSelectedItem(ticket.id)}
                onChange={() => toggleSelectedItem(ticket.id)}
              />
            </Form.Check> */}

            {ticket.avatar.img ? (
              <Avatar src={ticket.avatar.img} size="3xl" />
            ) : (
              <Avatar size="3xl" name={ticket.avatar.name} />
            )}

            <div className="ms-1 ms-sm-3">
              <p
                // className="fw-semibold mb-3 mb-sm-2"
                className={`${
                  localStorage.getItem('isDark') === 'true'
                    ? 'fw-semibold mb-3 mb-sm-2 text-400'
                    : 'fw-semibold mb-3 mb-sm-2 text-700'
                }`}
              >
                {ticket.subject}
              </p>
              <Row className="align-items-center gx-0 gy-2">
                <Col xs="auto" className="me-2">
                  <h6 className="mb-0">
                    <FontAwesomeIcon icon="user" transform="shrink-3 up-1" />
                    <span>{ticket.name}</span>
                  </h6>
                </Col>
                {/* <Col xs="auto" className="lh-1 me-3">
                  <SubtleBadge bg={ticket.status.type}>
                    {ticket.status.content}
                  </SubtleBadge>
                </Col> */}
                <Col xs="auto">
                  <h6 className="mb-0 text-500">${ticket.price}</h6>
                </Col>
              </Row>
            </div>
          </div>
          <div className="border-bottom mt-4 mb-x1"></div>
          {ticket.price && (
            <div className="d-flex justify-content-between ms-auto">
              <Col xs="auto">
                <h6 className="mb-0 text-500">${ticket.price}</h6>
              </Col>
            </div>
          )}
          {ticket.salary && (
            <div className="d-flex justify-content-between ms-auto">
              <Col xs="auto">
                <h6 className="mb-0 text-500">{ticket.salary}</h6>
              </Col>
            </div>
          )}
          <div className="d-flex justify-content-between ms-auto">
            <Col xs="auto">
              <h6 className="mb-0 text-500">{ticket.city}</h6>
            </Col>
          </div>
          {ticket.university && (
            <div className="d-flex justify-content-between ms-auto">
              <Col xs="auto">
                <h6 className="mb-0 text-500">{ticket.university}</h6>
              </Col>
            </div>
          )}
          {ticket.modality && (
            <div className="d-flex justify-content-between ms-auto">
              <Col xs="auto">
                <h6 className="mb-0 text-500">{ticket.modality}</h6>
              </Col>
            </div>
          )}
          {ticket.level && (
            <div className="d-flex justify-content-between ms-auto">
              <Col xs="auto">
                <h6 className="mb-0 text-500">{ticket.level}</h6>
              </Col>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

CardLayout.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object)
  // isSelectedItem: PropTypes.func,
  // toggleSelectedItem: PropTypes.func
};
