import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useAgreements } from 'hooks';
import Buttons from '../templates/Buttons';
import PropTypes from 'prop-types';

const AgreementsForm = ({ submit, item, items }) => {
  const [availabledMsg, setAvailabledMsg] = useState('');
  const [availabled, setAvailabled] = useState(true);
  const [image, setImage] = useState(null);
  const [logo, setLogo] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [availabledImage, setAvailabledImage] = useState(null);
  const { agreements } = useAgreements();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors }
  } = useForm({ defaultValues: { id: null } });

  //*EVENT CLICK SAVE AND UPDATE
  const onSubmit = data => {
    const formData = new FormData();
    if (item._id) {
      formData.append('_id', item._id);
    }
    if (availabled && !image && !availabledImage) {
      formData.append('logo', logo);
      formData.append('agreements', agreements._id);
      formData.append('name', data.name);
      formData.append('about', data.about);
      submit(formData);
      saveImage(
        `${process.env.REACT_APP_BACKEND_URL}/storage/logomarcas/estandar.jpg`
      );
      setImage(null);
      setAvailabledImage(null);
      reset();
    }
  };

  //* EVENT GET ALL RGISTER
  useEffect(() => {
    const fetchLogo = async () => {
      try {
        let logoUrl;
        if (item._id) {
          setValue('id', item._id);
          setValue('name', item.name);
          setValue('about', item.about);
          logoUrl = `${process.env.REACT_APP_BACKEND_URL}/storage/backgrounds/${item.image}`;
        } else {
          logoUrl = `${process.env.REACT_APP_BACKEND_URL}/storage/logomarcas/estandar.jpg`;
        }
        saveImage(logoUrl);
      } catch (error) {
        console.error('Error al obtener el archivo:', error);
      }
    };

    fetchLogo();
  }, [watch('id')]);

  const saveImage = async logoUrl => {
    setImageUrl(logoUrl);
    const response = await fetch(logoUrl);
    const blob = await response.blob();
    const file = new File([blob], 'logo.jpg', { type: 'image/jpeg' });
    setLogo(file);
    setValue('logo', [file]);
  };

  const verifyImage = async logo => {
    setImage(null);
    const file = logo;
    if (file) {
      setImageUrl(URL.createObjectURL(file));
      if (file.type && file.type.startsWith('image/')) {
        try {
          setLogo(file);
        } catch (error) {
          console.error(error);
        }
      } else {
        setImage('Este fichero no es una imagen!');
      }
    }
  };

  useEffect(() => {
    if (!item?._id || item?.name !== watch('name')) {
      setAvailabled(true);
      setAvailabledMsg('');
      if (items !== null) {
        items.forEach(dat => {
          if (dat.name === watch('name')) {
            setAvailabled(false);
            setAvailabledMsg('Este nombre está en uso actualmente!');
          }
        });
      }
    }
  }, [watch('name')]);

  return (
    <Row>
      <Col>
        <Form noValidate onSubmit={handleSubmit(onSubmit)} className="px-1">
          <div id="name-row" className="mb-3">
            <Form.Group>
              <Form.Label className="mb-1">Nombre (*)</Form.Label>
              <Form.Control
                size="sm"
                type="text"
                id="name"
                name="name"
                placeholder="Nombre del Convenio"
                isInvalid={!!errors.name}
                {...register('name', {
                  required: {
                    value: true,
                    message: 'este campo es requerido!'
                  },
                  minLength: {
                    limit: 1,
                    value: 1,
                    message: 'Mínimo 1 carácter!'
                  }
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name && errors.name.message}
              </Form.Control.Feedback>
              {availabled ? null : (
                <h6 className="text-danger fs--2 mt-2 mb-0">{availabledMsg}</h6>
              )}
            </Form.Group>
            <div id="about-row" className="mb-1">
              <Form.Group>
                <Form.Label className="mb-1">Descripción (*)</Form.Label>
                <Form.Control
                  size="sm"
                  as="textarea"
                  id="about"
                  name="about"
                  rows={7}
                  placeholder="Descripción"
                  isInvalid={!!errors.about}
                  {...register('about', {
                    required: {
                      value: true,
                      message: 'este campo es requerido!'
                    },
                    minLength: {
                      limit: 1,
                      value: 1,
                      message: 'Mínimo 1 carácter!'
                    }
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.about && errors.about.message}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <Form.Group>
              <Form.Label className="mb-1">Imagen</Form.Label>
              <Form.Control
                size="sm"
                type="file"
                id="logo"
                name="logo"
                onChange={e => {
                  verifyImage(e.target.files[0]);
                }}
              />
              {image !== null && (
                <h6 key="errorMessage" className="text-danger fs--2 mt-2 mb-0">
                  {image}
                </h6>
              )}
            </Form.Group>
            {imageUrl && image === null && (
              <div
                style={{
                  width: '150px',
                  height: '150px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 'auto',
                  marginTop: '10px'
                }}
              >
                <img
                  src={imageUrl}
                  alt="Preview"
                  style={{
                    objectFit: 'cover',
                    maxWidth: '100%',
                    maxHeight: '100%'
                  }}
                />
              </div>
            )}
            <Form.Label
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 'auto',
                marginTop: '10px',
                color: '#000'
              }}
            >
              {' '}
              Resolución Máxima: 1200x650
            </Form.Label>
            <Form.Label
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 'auto',
                color: '#000'
              }}
            >
              {' '}
              Tamaño máximo: 1mb
            </Form.Label>
            <Form.Label
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 'auto',
                color: '#000'
              }}
            >
              {' '}
              Formato permitido: .png .jpg .jpeg
            </Form.Label>
          </div>

          <Buttons
            btn={'create'}
            caption={watch('id') ? 'Actualizar Datos' : 'Guardar Datos'}
          />
        </Form>
      </Col>
    </Row>
  );
};

AgreementsForm.propTypes = {
  submit: PropTypes.func.isRequired,
  item: PropTypes.object,
  items: PropTypes.array
};

export default AgreementsForm;
