import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useDefault, useReferral } from 'hooks';

import { EModal, DModal, CModal } from 'components/templates/Modal';
import Buttons from 'components/templates/Buttons';
import Datatable from 'components/templates/datatable/Datatable';
import ReferralWidgets from 'components/dashboards/referral/ReferralWidgets';

const ReferralList = ({ authorized }) => {
  if (!authorized) return <Navigate to="/dashboard" />;

  const { emodal, handleEModal, dmodal, handleDModal, cmodal, handleCModal } =
    useDefault();
  const {
    setItem,
    item,
    items,
    submit,
    removeOne,
    changeStatus,
    caption,
    columns,
    Form_,
    confirm
  } = useReferral();

  //* FOR CLEAR FORM ON-CLOSE MODAL'S
  useEffect(() => {
    !emodal && setItem({});
  }, [emodal]);
  useEffect(() => {
    !dmodal && setItem({});
  }, [dmodal]);
  useEffect(() => {
    !cmodal && setItem({});
  }, [cmodal]);

  return (
    <>
      <ReferralWidgets items={items} />
      {Form_ && (
        <Buttons btn={'add'} caption={caption} handleEvent={handleEModal} />
      )}

      <Datatable
        title={`Listado de ${caption}`}
        data={items}
        columns={columns}
      />

      {Form_ && (
        <EModal
          form_={<Form_ submit={submit} item={item} items={items} />}
          title={caption}
          modal={emodal}
          handleModal={handleEModal}
        />
      )}

      <DModal
        item={item}
        modal={dmodal}
        handleModal={handleDModal}
        handleClick={removeOne}
      />

      {confirm && (
        <CModal
          item={item}
          modal={cmodal}
          handleModal={handleCModal}
          handleClick={changeStatus}
        />
      )}
    </>
  );
};

ReferralList.propTypes = {
  useHook: PropTypes.func,
  authorized: PropTypes.bool
};

export default ReferralList;
