import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Alert, Form, InputGroup } from 'react-bootstrap';

import { useDefault, useSurvey } from 'hooks';

import { EModal } from 'components/templates/Modal';
import Tabs from 'components/survey/Tabs';

import Buttons from 'components/templates/Buttons';

const SurveyQuestion = ({ authorized }) => {
  if (!authorized) return <Navigate to="/dashboard" />;

  const { emodal, handleEModal } = useDefault();
  const {
    nivelQuestion,
    modalityQuestion,
    Form_,
    submitQuest,
    questions,
    setQuestions,
    nivel,
    setNivel,
    modality,
    setModality,
    tabs,
    fn_asp,
    punter
  } = useSurvey();

  //* FOR CLEAR FORM ON-CLOSE MODAL'S
  useEffect(() => {
    !emodal && setQuestions({});
  }, [emodal]);

  useEffect(() => fn_asp(), [punter]);

  return (
    <>
      <InputGroup className="gap-2 mb-2">
        <Form.Select
          size="sm"
          id="nivel"
          value={nivel}
          onChange={e => setNivel(e.target.value)}
        >
          <option value="">Nivel</option>
          {nivelQuestion?.map(dat => (
            <option key={dat._id} value={dat._id}>
              {dat.name}
            </option>
          ))}
        </Form.Select>
        <Form.Select
          size="sm"
          id="modality"
          value={modality}
          onChange={e => setModality(e.target.value)}
        >
          <option value="">Modalidad</option>
          {modalityQuestion?.map(dat => (
            <option key={dat._id} value={dat._id}>
              {dat.name}
            </option>
          ))}
        </Form.Select>

        <Buttons btn={'search'} caption={'Buscar'} handleEvent={fn_asp} />
      </InputGroup>

      <Tabs tabs={tabs} />

      <Alert variant="info" show={tabs.length === 0}>
        <Alert.Heading>Muy bien, revisemos como buscar!</Alert.Heading>
        <p>
          En el componente de selección escoje un nivel y una modalidad, luego
          presiona el ícono de buscar, la información será cargada
          automaticamente en este componente!
        </p>
        <hr />
        <p className="mb-0">
          Nota: en el caso de no dejar de ver esta alerta intenta cambiando el
          nivel o la modalidad.
        </p>
      </Alert>

      <EModal
        form_={<Form_ submit={submitQuest} item={questions} />}
        title={'Pregunta'}
        modal={emodal}
        handleModal={handleEModal}
      />
    </>
  );
};

SurveyQuestion.propTypes = {
  authorized: PropTypes.bool
};

export default SurveyQuestion;
