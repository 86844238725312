import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';

import { useForm } from 'react-hook-form';
import Buttons from '../templates/Buttons';
import Flex from 'components/common/Flex';

const CommentForm = ({ submit, item }) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      id: null,
      _idPositive: null,
      _idNegative: null,
      _idRecomendation: null
    }
  });

  const onSubmit = data => {
    const req = [
      { _id: data.id },
      {
        _id: data._idRecomendation,
        comment: data.recomendation,
        type: 'recommendation'
      },
      {
        _id: data._idNegative,
        comment: data.negative,
        type: 'negative'
      },
      {
        _id: data._idPositive,
        comment: data.positive,
        type: 'positive'
      }
    ];

    submit(req);
    reset();
  };

  useEffect(() => {
    if (item.data?.data) {
      setValue('id', item.row?._id);
      setValue('_idPositive', item.data.data[2]?._id);
      setValue('_idNegative', item.data.data[1]?._id);
      setValue('_idRecomendation', item.data.data[0]?._id);
      setValue('positive', item.data.data[2]?.comment || '');
      setValue('negative', item.data.data[1]?.comment || '');
      setValue('recomendation', item.data.data[0]?.comment || '');
    }
  }, [item]);

  return (
    <>
      <Row>
        <Col>
          <Form noValidate onSubmit={handleSubmit(onSubmit)} className="px-1">
            <div id="positive-row">
              <FloatingLabel label="Commentario Positivo">
                <Form.Control
                  disabled={`${item.row?.published ? 'disabled' : ''}`}
                  id="positive"
                  as="textarea"
                  placeholder="deje su comentario positivo aquí!"
                  {...register('positive', {
                    maxLength: {
                      limit: 255,
                      value: 255,
                      message: 'maxLength: 255 caracteres!'
                    }
                  })}
                />
              </FloatingLabel>
              {(errors.positive && (
                <Form.Control.Feedback type="invalid">
                  {errors.positive && errors.positive.message}
                </Form.Control.Feedback>
              )) || (
                <h6 className="text-500 fs--2 mt-2 mb-2">
                  {watch('positive')?.length || 0} / 255
                </h6>
              )}
            </div>

            <div id="negative-row">
              <FloatingLabel label="Comentario Negativo">
                <Form.Control
                  disabled={`${item.row?.published ? 'disabled' : ''}`}
                  id="negative"
                  as="textarea"
                  placeholder="deje su comentario negativo aquí!"
                  {...register('negative', {
                    maxLength: {
                      limit: 255,
                      value: 255,
                      message: 'maxLength: 255 caracteres!'
                    }
                  })}
                />
              </FloatingLabel>
              {(errors.negative && (
                <Form.Control.Feedback type="invalid">
                  {errors.negative && errors.negative.message}
                </Form.Control.Feedback>
              )) || (
                <h6 className="text-500 fs--2 mt-2 mb-2">
                  {watch('negative')?.length || 0} / 255
                </h6>
              )}
            </div>

            <div id="recomendation-row">
              <FloatingLabel label="Recomendación">
                <Form.Control
                  disabled={`${item.row?.published ? 'disabled' : ''}`}
                  id="recomendation"
                  as="textarea"
                  placeholder="deje su recomendación aquí!"
                  {...register('recomendation', {
                    maxLength: {
                      limit: 255,
                      value: 255,
                      message: 'maxLength: 255 caracteres!'
                    }
                  })}
                />
              </FloatingLabel>
              {(errors.recomendation && (
                <Form.Control.Feedback type="invalid">
                  {errors.recomendation && errors.recomendation.message}
                </Form.Control.Feedback>
              )) || (
                <h6 className="text-500 fs--2 mt-2 mb-2">
                  {watch('recomendation')?.length || 0} / 255
                </h6>
              )}
            </div>

            {!item.row?.published ? (
              <Buttons btn={'create'} caption={'Guardar Datos'} />
            ) : (
              <>
                <Flex className="gap-1">
                  <h6 className="fw-bold text-warning">Nota:</h6>
                  <h6 className="text-700 dark:text-400">
                    esta encuesta se encuentra publicada, no puede realizar
                    cambios en los comentatios!
                  </h6>
                </Flex>
              </>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
};

CommentForm.propTypes = {
  submit: PropTypes.func.isRequired,
  item: PropTypes.object
};

export default CommentForm;
