import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Button, CloseButton } from 'react-bootstrap';
import Flex from 'components/common/Flex';

const EModal = ({ form_, title, modal, modalSize, handleModal }) => {
  return (
    <Modal
      backdrop="static"
      keyboard={false}
      show={modal}
      onHide={() => handleModal(false)}
      size={modalSize}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="bg-light border-0 py-2">
        <Modal.Title id="contained-modal-title-vcenter">
          <h6 className="mt-1 fw-1 fs-0">{title}</h6>
        </Modal.Title>
        <CloseButton
          className="btn btn-circle btn-sm transition-base p-0"
          onClick={() => handleModal(false)}
        />
      </Modal.Header>
      <Modal.Body className="py-0">{form_}</Modal.Body>
    </Modal>
  );
};

const DModal = ({ item, modal, handleModal, handleClick }) => {
  return (
    <Modal
      backdrop="static"
      keyboard={false}
      show={modal}
      onHide={() => handleModal(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="bg-light border-0 py-2">
        <Modal.Title id="contained-modal-title-vcenter">
          <h6 className="mt-1 fw-1 fs-0">Confirmación de Borrado!</h6>
        </Modal.Title>
        <CloseButton
          className="btn btn-circle btn-sm transition-base p-0"
          onClick={() => handleModal(false)}
        />
      </Modal.Header>
      <Modal.Body className="py-0">
        <Flex className="mt-2 gap-2">
          <p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="text-danger shadow rounded-pill"
              width={30}
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </p>

          <label className="text-sm text-800">
            ¿Está seguro de que desea eliminar este registro? Todos los datos
            referentes a{' '}
            <span className="text-danger fs--3">
              {item?.name || item?.nombre}
            </span>{' '}
            serán permanentemente eliminados.{' '}
            <span className="text-danger fs--3">
              Esta acción no se puede deshacer.
            </span>
          </label>
        </Flex>

        <Flex justifyContent={'end'} className="mb-2">
          <Button
            size="sm"
            variant="danger"
            className="me-2 rounded-pill me-1 mb-1"
            onClick={handleClick}
          >
            Borrar
          </Button>
          <Button
            size="sm"
            variant="falcon-default" //blanco
            className="me-2 rounded-pill me-1 mb-1"
            onClick={handleModal}
          >
            Cancelar
          </Button>
        </Flex>
      </Modal.Body>
    </Modal>
  );
};

const CModal = ({ item, modal, handleModal, handleClick }) => {
  return (
    <Modal
      backdrop="static"
      keyboard={false}
      show={modal}
      onHide={() => handleModal(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="bg-light border-0 py-2">
        <Modal.Title id="contained-modal-title-vcenter">
          <h6 className="mt-1 fw-1 fs-0">Atención!</h6>
        </Modal.Title>
        <CloseButton
          className="btn btn-circle btn-sm transition-base p-0"
          onClick={() => handleModal(false)}
        />
      </Modal.Header>
      <Modal.Body className="py-0">
        <Flex className="mt-2 gap-2">
          <p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="text-primary shadow rounded-pill"
              width={30}
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </p>

          <label className="text-sm text-800">
            ¿Está seguro de que desea modificar el estado del registro{' '}
            <span className="text-primary fs--3">
              {item?.name || item?.nombre}?
            </span>{' '}
            <span className="fs--3">Esta acción se puede deshacer.</span>
          </label>
        </Flex>

        <Flex justifyContent={'end'} className="mb-2">
          <Button
            size="sm"
            variant="primary"
            className="me-2 rounded-pill me-1 mb-1"
            onClick={handleClick}
          >
            Aceptar
          </Button>
          <Button
            size="sm"
            variant="falcon-default" //blanco
            className="me-2 rounded-pill me-1 mb-1"
            onClick={handleModal}
          >
            Cancelar
          </Button>
        </Flex>
      </Modal.Body>
    </Modal>
  );
};

EModal.propTypes = {
  form_: PropTypes.node,
  title: PropTypes.string,
  modal: PropTypes.bool,
  modalSize: PropTypes.string,
  handleModal: PropTypes.func
};

DModal.propTypes = {
  item: PropTypes.object.isRequired,
  modal: PropTypes.bool.isRequired,
  handleModal: PropTypes.func.isRequired,
  handleClick: PropTypes.func
};

CModal.propTypes = {
  item: PropTypes.object.isRequired,
  modal: PropTypes.bool.isRequired,
  handleModal: PropTypes.func.isRequired,
  handleClick: PropTypes.func
};

EModal.defaultProps = { modalSize: 'auto' };

export { EModal, DModal, CModal };
