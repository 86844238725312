import React, { useEffect, useState, createContext } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import headers from 'config/header';

import { useDefault, usePrograms, http } from 'hooks';

import Form_ from 'components/forms/SpreadsForm';

import Buttons from 'components/templates/Buttons';
import Flex from 'components/common/Flex';

const SpreadsContext = createContext();

const SpreadsProvider = ({ children }) => {
  const [items, setItems] = useState([]);
  const {
    item: spreads,
    setItems: setSpreads,
    items: list,
    name
  } = usePrograms();

  useEffect(() => {
    if (spreads) {
      setItems(spreads.spreads);
    }
  }, [spreads]);

  const { update, add, remove, handleEModal, handleDModal } = useDefault();

  const [item, setItem] = useState({});

  let columns;

  columns = [
    {
      accessor: 'name',
      Header: 'Nombre'
    },
    {
      accessor: `about`,
      Header: 'Sobre',
      Cell: rowData => {
        const { about } = rowData.row.original;
        return (
          <div className="d-flex align-items-center">
            <div className="ms-2 text-truncate" style={{ maxWidth: '400px' }}>
              {about}
            </div>
          </div>
        );
      }
    },
    {
      accessor: 'btn',
      Header: 'Acciones',
      headerProps: { style: { width: '95px' } },
      Cell: rowData => {
        return (
          <Flex className="gap-1 mt-1.5">
            <Buttons
              btn={'update'}
              handleEvent={() => {
                rowData.row.original.pt = false;
                setItem(rowData.row.original);
                handleEModal();
              }}
            />
            <Buttons
              btn={'delete'}
              handleEvent={() => {
                setItem(rowData.row.original);
                handleDModal();
              }}
            />
          </Flex>
        );
      }
    }
  ];

  const submit = object_ => {
    object_.spreads = spreads._id;
    update(`programs/updateSpreads`, object_).then(res => {
      res && setItems(res.spreads);
      setSpreads(
        list.map(stateObj => (stateObj._id === res._id ? res : stateObj))
      );
      setItem({});
    });
  };

  const removeOne = async () => {
    const deleted = {
      id: item._id,
      spreads: spreads._id
    };
    try {
      let config = headers();

      if (config.headers.token.length > 50) {
        const { data } = await http.put(
          `programs/deleteSpreads`,
          deleted,
          config
        );
        //! Async width .State.
        setItems(data.data.spreads);
        setSpreads(
          list.map(stateObj =>
            stateObj._id === data.data._id ? data.data : stateObj
          )
        );
        toast.success('Registro eliminado correctamente!');
      } else toast.error('Invalid token!');
    } catch (e) {
      console.log(e);
    } finally {
      handleDModal();
      setItem({});
    }
  };

  return (
    <SpreadsContext.Provider
      value={{
        removeOne,
        update,
        add,
        remove,
        name,
        submit,
        item,
        setItem,
        caption: 'Diferencial',
        captions: 'Diferenciales',
        columns,
        items,
        Form_,
        confirm: false
      }}
    >
      {children}
    </SpreadsContext.Provider>
  );
};

SpreadsProvider.propTypes = {
  children: PropTypes.node
};

export { SpreadsProvider };
export default SpreadsContext;
