import React, { useState, createContext } from 'react';
import { http } from 'hooks';
import headers from 'config/header';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

const DefaultContext = createContext();

const DefaultProvider = ({ children }) => {
  //*.comomn.
  const [loading, setLoading] = useState(true);
  const [loadBotton, setLoadBotton] = useState(false);
  const [emodal, setEModal] = useState(false);
  const [dmodal, setDModal] = useState(false);
  const [cmodal, setCModal] = useState(false);
  const [fmodal, setFModal] = useState(false);
  const [bmodal, setBModal] = useState(false);

  //* FOR ON-OFF THE MODAL
  const handleEModal = () => {
    setEModal(!emodal);
  };
  const handleDModal = () => {
    setDModal(!dmodal);
  };
  const handleCModal = () => {
    setCModal(!cmodal);
  };
  const handleFModal = () => {
    setFModal(!fmodal);
  };
  const handleBModal = () => {
    setBModal(!bmodal);
  };
  // *FOR ON-OFF THE MODAL

  //-----------------------------
  //.getAll Master
  const list = async url_ => {
    try {
      if (headers() !== '!!token') {
        const { data } = await http(url_, headers());
        if (data.data) return data.data;
        else return data;
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //* FUNCTION FOR CREATE ONE
  const add = async (url_, object_) => {
    try {
      setLoadBotton(true);
      const { data } = await http.post(url_, object_, headers());

      toast.success('Registro creado correctamente!');
      return data.data;
    } catch (e) {
      console.log(e);
    } finally {
      setLoadBotton(false);
    }
  };

  //* FUNCTION FOR UPDATE ONE
  const update = async (url_, object_) => {
    try {
      setLoadBotton(true);
      const { data } = await http.put(url_, object_, headers());

      handleEModal();
      toast.success('Registro actualizado correctamente!');
      return data.data;
    } catch (e) {
      console.log(e);
    } finally {
      setLoadBotton(false);
    }
  };

  //* DELETE
  const remove = async url_ => {
    try {
      const { data } = await http.delete(url_, headers());

      handleDModal();
      toast.success('Registro eliminado correctamente!');
      if (data) return data;
    } catch (e) {
      console.log(e);
    }
  };

  // FUNCTION FOR CHANGE STATUS
  const status = async (url_, object_) => {
    try {
      const { data } = await http.put(url_, object_, headers());

      toast.success('El estado ha sido actualizado con éxito!');
      return data.data;
    } catch (e) {
      console.log(e);
    } finally {
      handleCModal();
    }
  };

  return (
    <DefaultContext.Provider
      value={{
        //.Default.
        loading,
        setLoading,
        loadBotton,
        setLoadBotton,
        emodal,
        handleEModal,
        dmodal,
        handleDModal,
        cmodal,
        handleCModal,
        fmodal,
        handleFModal,
        bmodal,
        handleBModal,

        //.base fn_
        list,
        add,
        update,
        remove,
        status
      }}
    >
      {children}
    </DefaultContext.Provider>
  );
};

DefaultProvider.propTypes = {
  children: PropTypes.node
};

export { DefaultProvider };
export default DefaultContext;
