// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

const Project = ({ project, isLast }) => {
  let { color, duration, title } = project;
  if (title === null) {
    title = '';
  }
  return (
    <Row
      className={classNames('position-relative align-items-center py-2', {
        'border-bottom border-200 ': !isLast
      })}
    >
      <Col className="py-1">
        <Flex className="align-items-center">
          <div className="avatar avatar-xl me-3">
            <div
              className={`avatar-name rounded-circle bg-soft-${color}`}
              style={{ height: 30, width: 30 }}
            >
              <span className={`fs-0 text-${color.split('-')[1] || color}`}>
                {title[0]}
              </span>
            </div>
          </div>
          <Flex>
            <Flex tag="h6" align="center" className="mb-0">
              <a className="text-800 stretched-link" href="#!">
                {title}
              </a>
            </Flex>
          </Flex>
        </Flex>
      </Col>
      <Col>
        <Row className="justify-content-end align-items-center">
          <Col xs="auto pe-0">
            <div
              className={`${
                localStorage.getItem('isDark') === 'true'
                  ? 'fs--1 fw-semi-bold text-400'
                  : 'fs--1 fw-semi-bold text-700'
              }`}
            >
              {duration}
            </div>
          </Col>
          <Col xs="auto pe-0"></Col>
        </Row>
      </Col>
    </Row>
  );
};

Project.propTypes = {
  project: PropTypes.shape({
    color: PropTypes.string.isRequired,
    duration: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }),
  isLast: PropTypes.bool
};

const RunningProjects = ({
  data,
  title,
  // icon,
  setColumns,
  setallStudents,
  handleEModal,
  setTitle,
  columns
}) => {
  let items = [];
  if (data.length > 3) {
    items = data.slice(0, 5);
  } else {
    items = data;
  }
  let col;
  if (columns) {
    col = columns;
  } else {
    col = [
      {
        accessor: 'title',
        Header: 'Nombre'
      },
      {
        accessor: 'duration',
        Header: 'Cantidad'
      }
    ];
  }
  return (
    <Card>
      <FalconCardHeader title={title} light titleTag="h6" />
      {data.length > 0 && (
        <Card.Body className="py-0">
          {items.map((project, index) => (
            <Project
              project={project}
              isLast={index === data.length - 1}
              key={project.id}
            />
          ))}
        </Card.Body>
      )}
      {data.length === 0 && (
        <Card.Body className="py-0">
          {' '}
          <div style={{ textAlign: 'center', marginBottom: '10px' }}>
            <span style={{ fontSize: '13px' }}>
              No hay datos para mostrar!!!
              {/* <FontAwesomeIcon
                icon={icon}
                className="text-dark"
                style={{ fontSize: 'inherit' }}
              /> */}
            </span>
          </div>
        </Card.Body>
      )}
      {data.length > 3 && (
        <FalconCardFooterLink
          title="Ver más..."
          size="sm"
          to="#!"
          onClick={() => {
            setTitle(title);
            setColumns(col);
            setallStudents(data);
            handleEModal();
          }}
        />
      )}
    </Card>
  );
};

RunningProjects.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  setColumns: PropTypes.func,
  setallStudents: PropTypes.func,
  handleEModal: PropTypes.func,
  setTitle: PropTypes.func
};

export default RunningProjects;
