import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { useAuth, useDefault, useDepartments, useUniversity } from 'hooks';

import ReactSelect from 'react-select';

const ProfileSettings = ({ formData, setFormData }) => {
  const { list, update } = useDefault();
  const { auth } = useAuth();
  const { doctypes } = useAuth();
  const { items: departments } = useDepartments();

  const [citys, setCitys] = useState([]);
  const { items: universitys } = useUniversity([]);
  const [cityid, setCityId] = useState(null);
  const [error, setError] = useState(false);

  const { handleSubmit } = useForm({ defaultValues: { id: null } });

  useEffect(() => {
    if (auth._id && auth._id !== 0)
      list(`user/serch_profile/${auth._id}`).then(res => {
        if (res) {
          const departmentoption =
            res.department !== null
              ? departments.find(option => option._id === res.department?._id)
              : false;
          const doctypeoption =
            res.doctype !== null
              ? doctypes.find(option => option._id === res.doctype)
              : false;
          const universityProfile = res.university_profile
            ? universitys.find(
                option => option._id === res.university_profile?._id
              )
            : false;
          setCityId(res.city?._id);

          setFormData(formData => ({
            ...formData,
            _id: res._id || '',
            name: res.name || '',
            lastname: res.lastname || '',
            email: res.email || '',
            role: res.role || '',
            phone: res.phone || '',
            document: res.document || '',
            doctype: !doctypeoption
              ? null
              : {
                  value: doctypeoption._id,
                  label: doctypeoption.name
                },
            department: !departmentoption
              ? null
              : {
                  value: departmentoption._id,
                  label: departmentoption.name
                },
            city: null,
            university_profile: !universityProfile
              ? null
              : {
                  value: universityProfile?._id,
                  label: universityProfile?.name
                }
          }));
        }
      });
  }, []);

  const onSubmit = data => {
    if (
      formData.name === '' ||
      formData.lastname === '' ||
      formData.email === ''
    ) {
      setError(true);
      return;
    }

    data._id = formData._id;
    data.name = formData.name;
    data.lastname = formData.lastname;
    data.email = formData.email;
    data.phone = formData.phone;
    data.document = formData.document;
    if (formData.doctype !== null) data.doctype = formData.doctype.value;
    if (formData.department !== null)
      data.department = formData.department.value;
    if (formData.city !== null) data.city = formData.city.value;
    if (formData.university_profile !== null)
      data.university_profile = formData.university_profile.value;

    update('user/update_profile', data);
  };

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleChangeSelect = e => {
    setFormData({
      ...formData,
      [e.indice]: { value: e.value, label: e.label }
    });
  };

  useEffect(() => {
    formData.city = null;
    if (formData.department !== null && formData.department !== false)
      list(`cities/query?depart=${formData.department.value}`).then(res => {
        if (res) {
          setCitys(res);
          const cityoption = res.find(res => res._id === cityid);
          if (cityoption)
            setFormData({
              ...formData,
              city: {
                value: cityoption._id,
                label: cityoption.name,
                indice: 'city'
              }
            });
        }
      });
  }, [formData.department?.value]);

  return (
    <Card>
      <FalconCardHeader title="Configuración de Perfil" />
      <Card.Body
        className={`${
          localStorage.getItem('isDark') == 'true' ? 'text-400' : 'text-700'
        } bg-light`}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={6} controlId="name">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre"
                value={formData.name}
                name="name"
                onChange={handleChange}
                isInvalid={!!error && formData.name === ''}
              />
              <Form.Control.Feedback type="invalid">
                {error && formData.name === '' && 'El campo es requerido'}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="lastname">
              <Form.Label>Apellidos</Form.Label>
              <Form.Control
                type="text"
                placeholder="Apellidos"
                value={formData.lastname}
                name="lastname"
                onChange={handleChange}
                isInvalid={!!error && formData.lastname === ''}
              />
              <Form.Control.Feedback type="invalid">
                {error && formData.lastname === '' && 'El campo es requerido'}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={6} controlId="email">
              <Form.Label>Correo</Form.Label>
              <Form.Control
                type="email"
                placeholder="Correo"
                value={formData.email}
                name="email"
                onChange={handleChange}
                isInvalid={!!error && formData.email === ''}
              />
              <Form.Control.Feedback type="invalid">
                {error && formData.email === '' && 'El campo es requerido'}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="phone">
              <Form.Label>Teléfono</Form.Label>
              <Form.Control
                type="number"
                placeholder="Teléfono"
                value={formData.phone}
                name="phone"
                onChange={handleChange}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={6} controlId="document">
              <Form.Label>Documento</Form.Label>
              <Form.Control
                type="text"
                placeholder="Documento"
                value={formData.document}
                name="document"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="doctype">
              <Form.Label>Tipo de Documento</Form.Label>
              <ReactSelect
                size="sm"
                options={doctypes.map(find => ({
                  value: find._id,
                  label: find.name,
                  indice: 'doctype'
                }))}
                id="doctype"
                value={formData.doctype}
                placeholder="Seleccionar...."
                name="doctype"
                onChange={handleChangeSelect}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={6} controlId="department0">
              <Form.Label>Departamento</Form.Label>
              <ReactSelect
                size="sm"
                options={departments.map(find => ({
                  value: find._id,
                  label: find.name,
                  indice: 'department'
                }))}
                id="department"
                placeholder="Seleccionar...."
                value={formData.department}
                name="department"
                onChange={handleChangeSelect}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="city0">
              <Form.Label>Cuidad</Form.Label>
              <ReactSelect
                size="sm"
                options={citys.map(find => ({
                  value: find._id,
                  label: find.name,
                  indice: 'city'
                }))}
                id="city"
                placeholder="Seleccionar...."
                value={formData.city}
                name="city"
                onChange={handleChangeSelect}
              />
            </Form.Group>
          </Row>
          <Form.Group className="mb-3" controlId="university">
            <Form.Label>Universidad</Form.Label>
            <ReactSelect
              size="sm"
              options={universitys.map(find => ({
                value: find._id,
                label: find.name,
                indice: 'university_profile'
              }))}
              id="university_profile"
              rows={13}
              placeholder="Seleccionar...."
              value={formData?.university_profile}
              name="university_profile"
              onChange={handleChangeSelect}
            />
          </Form.Group>
          <div className="text-end">
            <Button variant="primary" type="submit">
              Actualizar
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

ProfileSettings.propTypes = {
  formData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    _id: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    document: PropTypes.string.isRequired,
    university_profile: PropTypes.shape({
      value: PropTypes.string.isRequired
    }).isRequired,
    doctype: PropTypes.shape({
      value: PropTypes.string.isRequired
    }).isRequired,
    department: PropTypes.shape({
      value: PropTypes.string.isRequired
    }).isRequired,
    city: PropTypes.shape({
      value: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  setFormData: PropTypes.func.isRequired
};

export default ProfileSettings;
