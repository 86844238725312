import React, { useEffect, useState, createContext } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import { http } from 'hooks';
import headers from 'config/header';

import { useDefault, useUniversity, useUniversityProfile } from 'hooks';

import Form_ from 'components/forms/BondsForm';

import Buttons from 'components/templates/Buttons';
import Flex from 'components/common/Flex';

const BondsContext = createContext();

const BondsProvider = ({ children }) => {
  const [items, setItems] = useState([]);
  let routes = '';
  let bonds = null;
  let setBonds = null;
  let list = null;
  let name = null;
  const {
    item: bonds1,
    setItems: setBonds1,
    items: list1,
    name: name1
  } = useUniversity();
  const {
    item: bonds2,
    setItems: setBonds2,
    items: list2,
    name: name2
  } = useUniversityProfile();
  if (bonds1) {
    bonds = bonds1;
    setBonds = setBonds1;
    list = list1;
    name = name1;
    routes = 'universities';
  } else {
    if (bonds2) {
      bonds = bonds2;
      setBonds = setBonds2;
      list = list2;
      name = name2;
      routes = 'university';
    }
  }

  useEffect(() => {
    if (bonds) {
      setItems(bonds.bonds);
    }
  }, [bonds]);

  const { update, add, remove, handleEModal, handleDModal } = useDefault();

  const [item, setItem] = useState({});

  let columns;

  columns = [
    {
      accessor: 'name',
      Header: 'Nombre'
    },
    // {
    //   accessor: 'type',
    //   Header: 'Tipo'
    // },
    {
      accessor: 'about',
      Header: 'Acerca de',
      Cell: rowData => {
        const { about } = rowData.row.original;
        return (
          <div className="d-flex align-items-center">
            <div className="ms-2 text-truncate" style={{ maxWidth: '400px' }}>
              {about}
            </div>
          </div>
        );
      }
    },
    // {
    //   accessor: 'description',
    //   Header: 'Descripción'
    // },
    {
      accessor: 'btn',
      Header: 'Acciones',
      headerProps: { style: { width: '95px' } },
      Cell: rowData => {
        return (
          <Flex className="gap-1 mt-1.5">
            <Buttons
              btn={'update'}
              handleEvent={() => {
                rowData.row.original.pt = false;
                setItem(rowData.row.original);
                handleEModal();
              }}
            />
            <Buttons
              btn={'delete'}
              handleEvent={() => {
                setItem(rowData.row.original);
                handleDModal();
              }}
            />
          </Flex>
        );
      }
    }
  ];

  const submit = object_ => {
    update(`universities/updateBonds`, object_).then(res => {
      res && setItems(res.bonds);
      setBonds(
        list.map(stateObj => (stateObj._id === res._id ? res : stateObj))
      );
      setItem({});
    });
  };

  const removeOne = async () => {
    const deleted = {
      id: item._id,
      bonds: bonds._id
    };
    try {
      let config = headers();

      if (config.headers.token.length > 50) {
        const { data } = await http.put(
          `universities/deleteBonds`,
          deleted,
          config
        );
        //! Async width .State.
        setItems(data.data.bonds);
        setBonds(
          list.map(stateObj =>
            stateObj._id === data.data._id ? data.data : stateObj
          )
        );
        toast.success('Registro eliminado correctamente!');
      } else toast.error('Invalid token!');
    } catch (e) {
      console.log(e);
    } finally {
      handleDModal();
      setItem({});
    }
  };

  return (
    <BondsContext.Provider
      value={{
        removeOne,
        update,
        add,
        bonds,
        remove,
        name,
        submit,
        item,
        routes,
        setItem,
        columns,
        items,
        Form_,
        caption: 'Beca',
        captions: 'Becas',
        confirm: false
      }}
    >
      {children}
    </BondsContext.Provider>
  );
};

BondsProvider.propTypes = {
  children: PropTypes.node
};

export { BondsProvider };
export default BondsContext;
