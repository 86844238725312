const headers = () => {
  const config = {
    headers: {
      // 'Content-Type': 'application/json',
      token: localStorage.getItem('token')
    }
  };

  if (config.headers.token?.length > 50) return config;
  return '!!token';
};

export default headers;
