import React from 'react';
import WeeklySales from 'components/dashboards/default/WeeklySales';
import { Row, Col } from 'react-bootstrap';
import {
  marketShare,
  totalOrder,
  totalSales,
  weeklySalesData,
  products,
  files,
  users,
  runningProjects
} from 'data/dashboard/default';

// import { tickets } from 'data/dashboard/support-desk';

import TotalOrder from 'components/dashboards/default/TotalOrder';
import MarketShare from 'components/dashboards/default/MarketShare';
import TotalSales from 'components/dashboards/default/TotalSales';
import RunningProjects from 'components/dashboards/default/RunningProjects';
import BestSellingProducts from 'components/dashboards/default/BestSellingProducts';
import BestSellingProducts2 from 'components/dashboards/default/BestSellingProducts2';
import SharedFiles from 'components/dashboards/default/SharedFiles';
import ActiveUsers from 'components/dashboards/default/ActiveUsers';
// import TopProducts from 'components/dashboards/default/TopProducts';
// import UnsolvedTickets from 'components/dashboards/unsolved-tickets/UnsolvedTickets';

// import ReportForThisWeek from 'components/dashboards/project-management/report-for-this-week/ReportForThisWeek';
export const topProducts = [
  ['product', '2019', '2018'],
  ['Boots4', 43, 85],
  ['Reign Pro', 83, 73],
  ['Slick', 86, 62],
  ['Falcon', 72, 53],
  ['Sparrow', 80, 50],
  ['Hideaway', 50, 70],
  ['Freya', 80, 90]
];

export const weeklyReport = [
  ['product', 'This Week', 'Last Week'],
  ['Sun', 43, 85],
  ['Mon', 83, 73],
  ['Tue', 86, 62],
  ['Wed', 72, 53],
  ['Thu', 80, 50],
  ['Fri', 50, 70],
  ['Sat', 80, 90],
  ['Sat2', 80, 90]
];

export const tickets = [
  {
    id: 1,
    name: 'Change of refund my last buy | Order #125631',
    avatar: {
      name: 'Change of refund my last buy | Order #125631',
      size: 'xl',
      round: 'circle'
    },
    subject: 'Synapse Design #1125'
  },
  {
    id: 2,
    name: 'Luke',
    avatar: {
      name: 'Luke',
      size: 'xl',
      round: 'circle'
    },
    subject: 'Change of refund my last buy | Order #125631'
  }
];

const Dashboard = () => {
  return (
    <>
      <Row className="g-3 mb-3">
        {/* <Col lg={12}>
          <TopProducts data={topProducts} />
        </Col>
        <Col lg={12}>
          <ReportForThisWeek data={weeklyReport} />
        </Col> */}
        <Col md={6} xxl={3}>
          <WeeklySales data={weeklySalesData} />
        </Col>
        <Col md={6} xxl={3}>
          <TotalOrder data={totalOrder} />
        </Col>
        <Col md={6} xxl={3}>
          <MarketShare data={marketShare} radius={['100%', '87%']} />
        </Col>
        <Col md={6} xxl={3}>
          <ActiveUsers className="h-100" users={users} />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col lg={6}>
          <RunningProjects data={runningProjects} />
        </Col>
        <Col lg={6}>
          <TotalSales data={totalSales} />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col lg={7} xl={8}>
          <BestSellingProducts products={products} />
        </Col>
        <Col lg={7} xl={8}>
          <BestSellingProducts2 products={products} />
        </Col>
        <Col lg={5} xl={4}>
          <SharedFiles files={files} className="h-lg-100" />
        </Col>
      </Row>
      {/* <UnsolvedTickets data={tickets} /> */}

      {/* <Row className="g-3">
        <Col sm={6} xxl={3}>
          <ActiveUsers className="h-100" users={users} />
        </Col>
        <Col sm={6} xxl={3} className="order-xxl-1">
          <BandwidthSaved />
        </Col>
      </Row> */}
    </>
  );
};

export default Dashboard;
