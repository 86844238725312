import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Card, Col, ProgressBar, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { useDefault } from 'hooks';
import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';

const Program = ({ program, isLast }) => {
  const { modalidad, ciudad, cantidad, nombre } = program;
  return (
    <Row
      className={classNames('position-relative align-items-center', {
        'border-bottom border-200 ': !isLast
      })}
    >
      <Col className="py-1">
        <Flex className="align-items-center">
          <div className="avatar avatar-xl me-3">
            <Avatar size="l" name={nombre} />
          </div>
          <Flex>
            <Flex align="left" className="align-items-center">
              <span className="text-800 fs--1 fw-semi-bold">
                {nombre.length > 27 ? nombre.slice(0, 27) + '...' : nombre}
              </span>
              <Badge pill bg="200" className="ms-2 text-primary">
                {modalidad}
              </Badge>
              <Badge pill bg="200" className="ms-2 text-warning">
                {ciudad}
              </Badge>
            </Flex>
          </Flex>
        </Flex>
      </Col>
      <Col>
        <Row className="justify-content-end align-items-center">
          <Col xs="auto pe-0">
            <div className="fs--1 fw-semi-bold">{cantidad}</div>
          </Col>
          <Col xs="5" className="pe-x1">
            <ProgressBar now={cantidad} style={{ height: 5 }} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const Top20Program = ({ data, toShow }) => {
  const { handleEModal } = useDefault();
  return (
    <Card>
      <FalconCardHeader
        title={`Top 40 x Programas`}
        light
        titleTag="h6"
        // endEl={
        //   <Form.Select size="sm" className="me-2">
        //     <option>Working Time</option>
        //     <option>Estimated Time</option>
        //     <option>Billable Time</option>
        //   </Form.Select>
        // }
      />

      <Card.Body className="py-0">
        {data
          ?.slice(0, toShow !== 'f' ? toShow : data.length)
          .map((program, index) => (
            <Program
              color={'primary'}
              program={program}
              isLast={index === 9}
              key={program._id}
            />
          ))}
      </Card.Body>

      {toShow !== 'f' && (
        <FalconCardFooterLink
          title="mostrar listado completo"
          size="sm"
          onClick={handleEModal}
        />
      )}
    </Card>
  );
};

Program.propTypes = {
  program: PropTypes.shape({
    modalidad: PropTypes.string,
    ciudad: PropTypes.string,
    cantidad: PropTypes.number,
    nombre: PropTypes.string
  }),
  isLast: PropTypes.bool
};

Top20Program.propTypes = {
  data: PropTypes.array,
  toShow: PropTypes.oneOfType([PropTypes.oneOf(['f']), PropTypes.number])
};

export default Top20Program;
