import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row } from 'react-bootstrap';

import { useForm } from 'react-hook-form';
import Buttons from '../templates/Buttons';

const ConfigurationForm = ({ submit, item }) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors }
  } = useForm({ defaultValues: { _id: null } });

  const onSubmit = data => {
    submit(data);
    reset();
  };

  useEffect(() => {
    setValue('_id', item._id);
    setValue('quiz_published', item.quiz_published);
    setValue('quiz_start', item.quiz_start);
    setValue('quiz_end', item.quiz_end);
  }, [watch('_id')]);

  return (
    <>
      <Row>
        <Col>
          <Form noValidate onSubmit={handleSubmit(onSubmit)} className="px-1">
            <div id="quiz_published-row">
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  id="quiz_published"
                  className="mb-0"
                  label={
                    !watch('quiz_published') ? 'Habilitar' : 'Deshabilitar'
                  }
                  {...register('quiz_published')}
                />
              </Form.Group>
            </div>

            <div id="quiz_start-row">
              <Form.Group>
                <Form.Label className="mb-1">Fecha Inicial</Form.Label>
                <Form.Control
                  size="sm"
                  type="date"
                  id="quiz_start"
                  name="quiz_start"
                  placeholder="Fecha inicial"
                  isInvalid={!!errors.quiz_start}
                  {...register('quiz_start', {
                    required: {
                      value: true,
                      message: 'este campo es requerido!'
                    }
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.quiz_start && errors.quiz_start.message}
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <div id="quiz_end-row">
              <Form.Group>
                <Form.Label className="mt-2">Fecha Final</Form.Label>
                <Form.Control
                  size="sm"
                  type="date"
                  id="quiz_end"
                  name="quiz_end"
                  placeholder="Fecha final"
                  className="mb-2"
                  isInvalid={!!errors.quiz_end}
                  {...register('quiz_end', {
                    required: {
                      value: true,
                      message: 'este campo es requerido!'
                    }
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.quiz_end && errors.quiz_end.message}
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <Buttons btn={'create'} caption={'Guardar'} />
          </Form>
        </Col>
      </Row>
    </>
  );
};

ConfigurationForm.propTypes = {
  submit: PropTypes.func.isRequired,
  item: PropTypes.object
};

export default ConfigurationForm;
