import React, { useState, useEffect } from 'react';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Card, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useAuth, useDefault } from 'hooks';

import validator from 'validator';
import Buttons from 'components/templates/Buttons';

const ChangePassword = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm();

  const { update } = useDefault();

  const { auth } = useAuth();

  const [errorStrongMsg, setErrorStrongMsg] = useState('');
  const [strong, setStrong] = useState(false);

  const onSubmit = data => {
    if (!strong) {
      document.getElementById('passwordNew').focus();
      return;
    }

    delete data.passwordRepeact;
    data._id = auth._id;
    data.password = data.passwordNew;
    delete data.passwordNew;
    update('user/update', data).then(res => {
      !res && reset();
    });
  };

  useEffect(() => {
    if (watch('passwordNew')) {
      if (
        validator.isStrongPassword(watch('passwordNew'), {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1
        })
      ) {
        setErrorStrongMsg('Contraseña Fuerte');
        setStrong(true);
      } else {
        setErrorStrongMsg('Contraseña Débil');
        setStrong(false);
      }
    } else setErrorStrongMsg('');
  }, [watch('passwordNew'), watch('passwordRepeact')]);

  return (
    <Card
      className={`${
        localStorage.getItem('isDark') == 'true' ? 'text-400' : 'text-700'
      } mb-3`}
    >
      <FalconCardHeader title="Cambiar Contraseña" />
      <Card.Body className="bg-light">
        <Form noValidate onSubmit={handleSubmit(onSubmit)} className="px-1">
          <Row className="mb-3 g-3">
            <Form.Group>
              <Form.Label>Contraseña actual</Form.Label>
              <Form.Control
                size="sm"
                type="password"
                name="passwordCurrent"
                id="passwordCurrent"
                placeholder="Contraseña actual"
                isInvalid={!!errors.passwordCurrent}
                {...register('passwordCurrent', {
                  required: {
                    value: true,
                    message: 'este campo es requerido!'
                  },
                  minLength: {
                    value: 8,
                    message: 'minLength: 8 caracteres!'
                  }
                })}
              />

              {errors.passwordCurrent && (
                <Form.Control.Feedback type="invalid">
                  {errors.passwordCurrent.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Row>

          <div id="passwordNew-row" className="mb-3 g-3">
            <Form.Group>
              <Form.Label>Nueva Contraseña</Form.Label>
              <Form.Control
                size="sm"
                type="password"
                name="passwordNew"
                id="passwordNew"
                placeholder="Contraseña"
                isInvalid={!!errors.passwordNew}
                {...register('passwordNew', {
                  required: {
                    value: true,
                    message: 'este campo es requerido!'
                  },
                  minLength: {
                    value: 8,
                    message: 'Largo: 8 caracteres!'
                  }
                })}
              />

              {errors.passwordNew && !errorStrongMsg && (
                <Form.Control.Feedback type="invalid">
                  {errors.passwordNew.message}
                </Form.Control.Feedback>
              )}
              {errorStrongMsg === '' ? (
                !errors.passwordNew && (
                  <h6 className="text-500 fs--2 mt-2 mb-0">
                    Requerido (Largo: 8, Mayúscula: 1, Minusculas: 1, Número: 1,
                    Símbolo: 1)
                  </h6>
                )
              ) : (
                <h6
                  className={`${
                    strong ? 'text-primary' : 'text-danger'
                  } fs--2 mt-2 mb-0`}
                >
                  {errorStrongMsg}
                </h6>
              )}
            </Form.Group>
          </div>

          <div id="passwordRepeact-row" className="mb-3 g-3">
            <Form.Group className="mb-3">
              <Form.Label>Confirmar Contraseña</Form.Label>
              <Form.Control
                size="sm"
                type="password"
                placeholder="Confirmar contraseña"
                id="passwordRepeact"
                name="passwordRepeact"
                isInvalid={!!errors.passwordRepeact}
                {...register('passwordRepeact', {
                  required: {
                    value: true,
                    message: 'este campo es requerido!'
                  },
                  minLength: {
                    value: 8,
                    message: 'Largo: 8 caracteres!'
                  },
                  validate: value =>
                    value === watch('passwordNew') ||
                    'las contraseñas no coinciden!'
                })}
              />

              <Form.Control.Feedback type="invalid">
                {errors.passwordRepeact && errors.passwordRepeact.message}
              </Form.Control.Feedback>
            </Form.Group>
          </div>

          <Buttons btn="create" caption={'Cambiar Contraseña'} />
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ChangePassword;
