import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';

const SwitchDt = ({ id, status, pt, tx, tx0, handledEvent }) => {
  return (
    <OverlayTrigger
      overlay={
        <Tooltip style={{ position: 'fixed' }} id={id}>
          {status ? tx : tx0}
        </Tooltip>
      }
    >
      <div className="flex gap-1">
        <Form.Check
          type="switch"
          id={id}
          checked={status}
          onChange={handledEvent}
        />
        {pt && (
          <span
            className={`${
              status ? 'text-green-800' : 'text-red-800'
            } text-sm font-medium hidden lg:block`}
          >
            {status ? tx : tx0}
          </span>
        )}
      </div>
    </OverlayTrigger>
  );
};
const SwitchFutura = ({ id, status, pt, tx, tx0, handledEvent }) => {
  return (
    <OverlayTrigger
      overlay={
        <Tooltip style={{ position: 'fixed' }} id={id}>
          {status
            ? tx == 'Habilitado'
              ? 'Deshabilitar'
              : 'Deshacer Beca Futura'
            : tx0 == 'Deshabilitado'
            ? 'Habilitar'
            : 'Hacer Beca Futura'}
        </Tooltip>
      }
    >
      <div className="flex gap-1">
        <Form.Check
          type="switch"
          id={id}
          checked={status}
          onChange={handledEvent}
        />
        {pt && (
          <span
            className={`${
              status ? 'text-green-800' : 'text-red-800'
            } text-sm font-medium hidden lg:block`}
          >
            {status ? tx : tx0}
          </span>
        )}
      </div>
    </OverlayTrigger>
  );
};

SwitchDt.propTypes = {
  id: PropTypes.string.isRequired,
  status: PropTypes.bool.isRequired,
  pt: PropTypes.bool.isRequired,
  tx: PropTypes.string,
  tx0: PropTypes.string,
  handledEvent: PropTypes.func.isRequired
};
SwitchFutura.propTypes = {
  id: PropTypes.string.isRequired,
  status: PropTypes.bool.isRequired,
  pt: PropTypes.bool.isRequired,
  tx: PropTypes.string,
  tx0: PropTypes.string,
  handledEvent: PropTypes.func.isRequired
};

//Notification.defaultProps = { unread: false, flush: false };

export { SwitchDt, SwitchFutura };
