import React, { useEffect, useState, createContext } from 'react';
import Rating from 'react-rating';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useDefault, useAuth, http } from 'hooks';
import headers from 'config/header';

import Form_ from 'components/forms/QuestionForm';
import Datatable_ from 'components/templates/datatable/Datatable';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import Buttons from 'components/templates/Buttons';

const SurveyContext = createContext();

const SurveyProvider = ({ children }) => {
  // ?QUERY LIST
  const [itemList, setItemList] = useState({});
  const [itemsList, setItemsList] = useState([]);
  const [quizTotal, setQuizTotal] = useState(0);
  const [verified, setVerified] = useState(0);
  const [unverified, setUnverified] = useState(0);
  const [publish, setPublish] = useState(0);
  const [noPublish, setnoPublish] = useState(0);
  const [config, setConfig] = useState({});
  const [comment, setComment] = useState({});

  const { isAuthenticated } = useAuth();

  // ?QUERY QUESTION
  const [punter, setPunter] = useState(false);
  const [nivel, setNivel] = useState('');
  const [modality, setModality] = useState('');
  const [nivelQuestion, setNivelQuestion] = useState([]);
  const [modalityQuestion, setModalityQuestion] = useState([]);
  const [questions, setQuestions] = useState({});
  const [tabs, setTabs] = useState([]);

  // ?REFERRALS
  const [referral, setReferral] = useState({});
  const [referrals, setReferrals] = useState([]);

  const [mmodal, setMModal] = useState(false);
  const [vmodal, setVModal] = useState(false);

  const handleMModal = () => {
    setMModal(!mmodal);
  };
  const handleVModal = () => {
    setVModal(!vmodal);
  };

  const fn_asp = () => {
    let tab = [];

    getAspects().then(res => {
      res?.map(dat => {
        tab.push({
          _id: dat[0]._id,
          category: dat[0].name,
          component: (
            <Datatable_ title={' '} data={dat[1]} columns={columnsQuestion} />
          )
        });
      });

      setTabs(tab);
    });
  };

  const {
    list,
    remove,
    update,
    handleCModal,
    handleEModal,
    handleDModal,
    handleFModal
  } = useDefault();

  // Get all Answers fn_getAnswers
  const columnsEm = [
    {
      accessor: 'aspect.questions',
      Header: 'Preguntas'
    },
    {
      accessor: 'eval',
      Header: 'Evaluación'
    }
  ];

  const columnsList = [
    {
      accessor: 'name',
      Header: 'Nombre',
      Cell: rowData => {
        const { student } = rowData.row.original;
        return (
          <div className="d-flex align-items-center gap-3">
            <Avatar size="l" name={student?.name} />

            <div className="flex-1">
              <div className="flex gap-1">
                <span className="text-700 dark:text-400 fw-medium">
                  {student?.name + ' ' + student?.lastname}
                </span>
              </div>
              <span className="fw-bold fs--1 text-700">{student?.email}</span>{' '}
              <br />
              <span className="text-700 dark:text-400">
                {student?.program?.name}
              </span>
            </div>
          </div>
        );
      }
    },
    {
      accessor: 'university',
      Header: 'Universidad',
      Cell: rowData => {
        const { university, student } = rowData.row.original;
        return (
          <div className="flex-1">
            <div className="flex gap-1">
              <div className="rating rating-sm">
                <Rating
                  readonly
                  initialRating={rowData.row.original.eval}
                  fullSymbol={
                    <FontAwesomeIcon
                      icon="star"
                      className="text-warning fs-0 me-1"
                    />
                  }
                  emptySymbol={
                    <FontAwesomeIcon
                      icon="star"
                      className="text-300 fs-0 me-1"
                    />
                  }
                />
              </div>
            </div>
            <span className="text-700 dark:text-400 fw-medium">
              {university.name}
            </span>{' '}
            <br />
            <span className="text-700 dark:text-400">
              {student?.program.level.name +
                ` - ` +
                student?.program.modality.name}
            </span>
          </div>
        );
      }
    },
    {
      accessor: 'published',
      Header: 'Aprobada',
      Cell: rowData => {
        const { _id, published } = rowData.row.original;

        return !published ? (
          <>
            <Flex justifyContent={'center'}>
              <Nav.Link
                className="theme-control-toggle"
                onClick={() => {
                  setItemList(rowData.row.original);
                  handleCModal();
                }}
              >
                <OverlayTrigger
                  overlay={
                    <Tooltip style={{ position: 'fixed' }} id={_id}>
                      Publicar
                    </Tooltip>
                  }
                >
                  <div className="bg-100 theme-control-toggle-label text-danger">
                    <FontAwesomeIcon icon={'times-circle'} className="fs-0" />
                  </div>
                </OverlayTrigger>
              </Nav.Link>
            </Flex>
          </>
        ) : (
          <Flex justifyContent={'center'}>
            <div className="theme-control-toggle-label text-success">
              <FontAwesomeIcon icon={'check-circle'} className="fs-0" />
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'verified',
      Header: 'Verificada',
      Cell: rowData => {
        const { _id, verified } = rowData.row.original;

        return !verified ? (
          <>
            <Flex justifyContent={'center'}>
              <Nav.Link
                className="theme-control-toggle"
                onClick={() => {
                  setItemList(rowData.row.original);
                  handleFModal();
                }}
              >
                <OverlayTrigger
                  overlay={
                    <Tooltip style={{ position: 'fixed' }} id={_id}>
                      Verificar
                    </Tooltip>
                  }
                >
                  <div className="bg-100 theme-control-toggle-label text-danger">
                    <FontAwesomeIcon icon={'times-circle'} className="fs-0" />
                  </div>
                </OverlayTrigger>
              </Nav.Link>
            </Flex>
          </>
        ) : (
          <Flex justifyContent={'center'}>
            <div className="theme-control-toggle-label text-success">
              <FontAwesomeIcon icon={'check-circle'} className="fs-0" />
            </div>
          </Flex>
        );
      }
    },
    // {
    //   accessor: 'verified',
    //   Header: 'Verificada',
    //   Cell: rowData => {
    //     const { verified } = rowData.row.original;
    //     return !verified ? (
    //       <Flex justifyContent={'center'}>
    //         <div className="text-danger">
    //           <FontAwesomeIcon icon={'times-circle'} className="fs-0" />
    //         </div>
    //       </Flex>
    //     ) : (
    //       <Flex justifyContent={'center'}>
    //         <div className="theme-control-toggle-label text-success">
    //           <FontAwesomeIcon icon={'check-circle'} className="fs-0" />
    //         </div>
    //       </Flex>
    //     );
    //   }
    // },
    {
      accessor: 'fecha',
      Header: 'Fecha',
      Cell: rowData => {
        const { createdAt } = rowData.row.original;
        return <span className="text-700">{createdAt.split('T')[0]}</span>;
      }
    },
    {
      accessor: 'actions',
      Header: 'Acciones',
      Cell: rowData => {
        const { _id } = rowData.row.original;
        return (
          <div className="flex">
            <OverlayTrigger
              overlay={
                <Tooltip style={{ position: 'fixed' }} id={_id + `/comment`}>
                  Comentarios
                </Tooltip>
              }
            >
              <svg
                id={_id + `/comment`}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="cursor-pointer"
                width={20}
                onClick={() => {
                  getComments(rowData.row.original);
                  handleMModal();
                }}
              >
                <path
                  fillRule="evenodd"
                  d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75H12a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                  clipRule="evenodd"
                />
              </svg>
            </OverlayTrigger>

            <OverlayTrigger
              overlay={
                <Tooltip style={{ position: 'fixed' }} id={_id + `/evaluation`}>
                  Publicar
                </Tooltip>
              }
            >
              <svg
                id={_id + `/evaluation`}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="cursor-pointer ms-2"
                width={20}
                onClick={() => {
                  getAnswers(rowData.row.original).then(
                    res => res && tabElements(res)
                  );
                  handleVModal();
                }}
              >
                <path d="M5.625 3.75a2.625 2.625 0 100 5.25h12.75a2.625 2.625 0 000-5.25H5.625zM3.75 11.25a.75.75 0 000 1.5h16.5a.75.75 0 000-1.5H3.75zM3 15.75a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75zM3.75 18.75a.75.75 0 000 1.5h16.5a.75.75 0 000-1.5H3.75z" />
              </svg>
            </OverlayTrigger>
          </div>
        );
      }
    }
  ];

  const columnsQuestion = [
    {
      accessor: 'name',
      Header: 'Factor'
    },
    {
      accessor: 'questions',
      Header: 'Pregunta'
    },
    {
      accessor: 'actions',
      Header: 'Acciones',
      Cell: rowData => {
        return (
          <Flex className="gap-1 mt-1.5">
            <Buttons
              btn={'update'}
              handleEvent={() => {
                rowData.row.original.pt = false;
                setQuestions(rowData.row.original);
                handleEModal();
              }}
            />
            {/* <Buttons
              btn={'delete'}
              handleEvent={() => {
                setItem(rowData.row.original);
                handleDModal();
              }}
            /> */}
          </Flex>
        );
      }
    }
  ];

  const columnsReferrals = [
    {
      accessor: 'name',
      Header: 'Nombre',
      Cell: rowData => {
        const { name } = rowData.row.original;
        return (
          <div className="d-flex align-items-center">
            <Avatar size="l" name={name} />
            <div className="ms-2">{name}</div>
          </div>
        );
      }
    },
    {
      accessor: 'email',
      Header: 'Correo'
    },
    {
      accessor: 'phone',
      Header: 'Teléfono'
    },
    {
      accessor: 'code',
      Header: 'Código'
    },
    {
      accessor: 'referral',
      Header: 'Referidos',
      Cell: rowData => {
        const { referral } = rowData.row.original;
        return (
          <span
            className={`${
              referral > 0 ? 'text-primary fw-bold' : 'text-500 fw-semibold'
            } text-sm`}
          >
            {referral > 0 ? referral : 'sin referidos'}
          </span>
        );
      }
    },
    {
      accessor: 'btn',
      Header: 'Acciones',
      headerProps: { style: { width: '95px' } },
      Cell: rowData => {
        return (
          <Flex className="gap-1 mt-1.5">
            {/* <Buttons
              btn={'update'}
              handleEvent={() => {
                rowData.row.original.pt = false;
                setItem(rowData.row.original);
                handleEModal();
              }}
            /> */}
            <Buttons
              btn={'delete'}
              handleEvent={() => {
                setReferral(rowData.row.original);
                handleDModal();
              }}
            />
          </Flex>
        );
      }
    }
  ];

  const tabElements = data => {
    let tab = [];

    data?.map(dat => {
      tab.push({
        _id: dat[0]._id,
        category: dat[0].name,
        component: <Datatable_ title={' '} data={dat[1]} columns={columnsEm} />
      });
    });

    setTabs(tab);
  };

  useEffect(() => {
    !mmodal && setComment({});
  }, [mmodal]);
  useEffect(() => {
    !vmodal && setItemList({});
  }, [vmodal]);
  useEffect(() => {
    mmodal && handleMModal();
  }, [itemsList]);

  // List
  useEffect(() => {
    list('students/query_quiz').then(res => res && setItemsList(res));
    list('answers/quiz_statistics').then(res => {
      if (res) {
        setQuizTotal(res.quiz_total);
        setVerified(res.user_verified);
        setUnverified(res.user_unverified);
        setPublish(res.publish);
        setnoPublish(res.no_publish);
      }
    });
  }, [isAuthenticated]);

  function searchInObject(obj, filterText) {
    for (const key in obj) {
      const value = obj[key];
      if (typeof value === 'object' && value !== null) {
        if (searchInObject(value, filterText)) {
          return true;
        }
      } else if (
        value != null &&
        value.toString().toLowerCase().includes(filterText.toLowerCase())
      ) {
        return true;
      }
    }
    return false;
  }

  const searchQuery = filter => {
    if (filter) {
      const filteredItems = itemsList.filter(row => {
        return searchInObject(row, filter);
      });
      setItemsList(filteredItems);
    } else list('students/query_quiz').then(res => res && setItemsList(res));
  };

  const searchQueryDate = (filterDt1, filterDt2) => {
    if (filterDt1 && filterDt2) {
      const filteredDate = itemsList.filter(
        val =>
          Date.parse(val.createdAt) >= Date.parse(filterDt1) &&
          Date.parse(val.createdAt) <= Date.parse(filterDt2)
      );
      setItemsList(filteredDate);
    } else list('students/query_quiz').then(res => res && setItemsList(res));
  };

  const removeOne = () => {
    remove(`students/query_quiz/delete/${itemList._id}`).then(() => {
      setItemsList(itemsList.filter(stateObj => stateObj._id !== itemList._id));
      setItemList({});
    });
  };

  // Get data for configuration
  useEffect(() => {
    list('configuration/all').then(res => res && setConfig(res));
  }, []);

  const configuration = async object_ => {
    update('configuration/update', object_).then(res => {
      res && setConfig(res);
      list('students/query_quiz').then(res => res && setItemsList(res));
    });
  };

  // Get data comment
  const getComments = async object_ => {
    try {
      const { data } = await http(
        'comments/query_quiz',
        {
          params: {
            student: object_.student._id,
            university: object_.university._id,
            program: object_.student.program._id
          }
        },
        headers()
      );
      const res = {
        row: object_,
        data: data
      };
      setComment(res);
    } catch (e) {
      console.log(e);
    }
  };

  const updateComment = async object_ => {
    try {
      await http.put('comments/update', object_);
    } catch (e) {
      console.log(e);
    } finally {
      toast.success('Comentarios actualizado correctamente!');
      list('students/query_quiz').then(res => res && setItemsList(res));
    }
  };

  const getAnswers = async object_ => {
    try {
      const { data } = await http(
        '/answers/query_by_quiz',
        {
          params: {
            student: object_.student._id,
            university: object_.university._id,
            program: object_.student.program._id,
            level: object_.student.program.level._id,
            modality: object_.student.program.modality._id
          }
        },
        headers()
      );
      return data;
    } catch (e) {
      console.log(e);
    }
  };
  // List

  // Question
  useEffect(() => {
    list('levels/all').then(res => res && setNivelQuestion(res));
    list('modalitys/all').then(res => res && setModalityQuestion(res));
  }, [isAuthenticated]);

  const getAspects = async () => {
    try {
      if (nivel && modality) {
        const { data } = await http(
          'aspects/query_quiz',
          { params: { level: nivel, modality: modality } },
          headers()
        );
        return data;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const submitQuest = object_ => {
    update('aspects/update', object_).then(res => {
      res && setPunter(!punter);
      setQuestions({});
    });
  };
  // Question

  const published = async () => {
    try {
      const object_ = {
        student: itemList.student._id,
        university: itemList.university._id,
        program: itemList.student.program._id,
        level: itemList.student.program.level._id,
        modality: itemList.student.program.modality._id
      };
      const { data } = await http.post(
        'answers/publish_quiz',
        object_,
        headers()
      );

      const updateObj = itemsList.map(stateObj =>
        stateObj._id === data.data._id ? data.data : stateObj
      );
      setItemsList(updateObj);
      setItemList([]);
      handleCModal();

      toast.success('Encuesta publicada correctamente!');
      list('students/query_quiz').then(res => res && setItemsList(res));
      list('answers/quiz_statistics').then(res => {
        if (res) {
          setQuizTotal(res.quiz_total);
          setVerified(res.user_verified);
          setUnverified(res.user_unverified);
          setPublish(res.publish);
          setnoPublish(res.no_publish);
        }
      });

      data.status === 'success' &&
        (await http.post('answers/set_answers', object_, config));
    } catch (e) {
      console.log(e);
    }
  };

  const verifiedF = async () => {
    try {
      const object_ = {
        student: itemList.student._id,
        university: itemList.university._id,
        program: itemList.student.program._id,
        level: itemList.student.program.level._id,
        modality: itemList.student.program.modality._id
      };
      const { data } = await http.post(
        'answers/verify_quiz',
        object_,
        headers()
      );

      const updateObj = itemsList.map(stateObj =>
        stateObj._id === data.data._id ? data.data : stateObj
      );
      setItemsList(updateObj);
      setItemList([]);
      handleFModal();

      toast.success('Encuesta verificada correctamente!');
      list('students/query_quiz').then(res => res && setItemsList(res));
      list('answers/quiz_statistics').then(res => {
        if (res) {
          setQuizTotal(res.quiz_total);
          setVerified(res.user_verified);
          setUnverified(res.user_unverified);
          setPublish(res.publish);
          setnoPublish(res.no_publish);
        }
      });

      data.status === 'success' &&
        (await http.post('answers/set_answers', object_, config));
    } catch (e) {
      console.log(e);
    }
  };

  // Referrals
  //* GET ALL REGISTER
  useEffect(() => {
    list('referrals_answers/all').then(res => res && setReferrals(res));
  }, []);

  //* DELETE
  const removeReferrals = () => {
    remove(`referrals_answers/delete/${referral._id}`).then(() => {
      setReferrals(referrals.filter(stateObj => stateObj._id !== referral._id));
      setReferral({});
    });
  };
  // Referrals

  return (
    <SurveyContext.Provider
      value={{
        itemList,
        itemsList,
        setItemsList,
        config,
        comment,
        columnsList,
        removeOne,
        searchQuery,
        searchQueryDate,
        published,
        configuration,
        updateComment,
        getComments,
        getAnswers,
        mmodal,
        vmodal,
        handleMModal,
        handleVModal,
        verifiedF,
        quizTotal,
        verified,
        unverified,
        publish,
        noPublish,
        nivelQuestion,
        modalityQuestion,
        tabs,
        setTabs,
        questions,
        setQuestions,
        nivel,
        setNivel,
        modality,
        setModality,
        getAspects,
        submitQuest,
        fn_asp,
        punter,

        columnsReferrals,
        referral,
        referrals,
        removeReferrals,

        captionList: 'Encuestas',
        Form_,
        modalSize: 'lg',
        confirm: true,
        modifaid: true
      }}
    >
      {children}
    </SurveyContext.Provider>
  );
};

SurveyProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { SurveyProvider };
export default SurveyContext;
