import React, { useEffect, useState, createContext } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import { http } from 'hooks';
import headers from 'config/header';

import { useDefault, useUniversity, useUniversityProfile } from 'hooks';

import Form_ from 'components/forms/ImagesForm';

import Buttons from 'components/templates/Buttons';
import Flex from 'components/common/Flex';

const GalleryContext = createContext();

const GalleryProvider = ({ children }) => {
  const [items, setItems] = useState([]);
  let routes = '';
  let images = null;
  let setImages = null;
  let list = null;
  let name = null;
  const {
    item: images1,
    setItems: setImages1,
    items: list1,
    name: name1
  } = useUniversity();
  const {
    item: images2,
    setItems: setImages2,
    items: list2,
    name: name2
  } = useUniversityProfile();
  if (images1) {
    images = images1;
    setImages = setImages1;
    list = list1;
    name = name1;
    routes = 'universities';
  } else {
    if (images2) {
      images = images2;
      setImages = setImages2;
      list = list2;
      name = name2;
      routes = 'university';
    }
  }

  useEffect(() => {
    if (images) {
      setItems(images.images);
    }
  }, [images]);

  const { update, add, remove, handleEModal, handleDModal } = useDefault();

  const [item, setItem] = useState({});

  let columns;

  columns = [
    {
      accessor: 'image',
      Header: 'Imagen'
    },
    {
      accessor: 'btn',
      Header: 'Acciones',
      headerProps: { style: { width: '95px' } },
      Cell: rowData => {
        return (
          <Flex className="gap-1 mt-1.5">
            <Buttons
              btn={'update'}
              handleEvent={() => {
                rowData.row.original.pt = false;
                setItem(rowData.row.original);
                handleEModal();
              }}
            />
            <Buttons
              btn={'delete'}
              handleEvent={() => {
                setItem(rowData.row.original);
                handleDModal();
              }}
            />
          </Flex>
        );
      }
    }
  ];

  const submit = object_ => {
    update(`universities/updateImage`, object_).then(res => {
      res && setItems(res.images);
      setImages(
        list.map(stateObj => (stateObj._id === res._id ? res : stateObj))
      );
      setItem({});
    });
  };

  const removeOne = async () => {
    const deleted = {
      id: item._id,
      image: images._id
    };
    try {
      let config = headers();

      if (config.headers.token.length > 50) {
        const { data } = await http.put(
          `universities/deleteImage`,
          deleted,
          config
        );
        //! Async width .State.
        setItems(data.data.images);
        setImages(
          list.map(stateObj =>
            stateObj._id === data.data._id ? data.data : stateObj
          )
        );
        toast.success('Registro eliminado correctamente!');
      } else toast.error('Invalid token!');
    } catch (e) {
      console.log(e);
    } finally {
      handleDModal();
      setItem({});
    }
  };

  return (
    <GalleryContext.Provider
      value={{
        removeOne,
        update,
        add,
        images,
        remove,
        name,
        submit,
        item,
        setItem,
        columns,
        routes,
        items,
        Form_,
        caption: 'Imagen',
        captions: 'Imágenes',
        confirm: false
      }}
    >
      {children}
    </GalleryContext.Provider>
  );
};

GalleryProvider.propTypes = {
  children: PropTypes.node
};

export { GalleryProvider };
export default GalleryContext;
