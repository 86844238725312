import FalconCardHeader from 'components/common/FalconCardHeader';
import React from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ProjectTable from './ProjectTable';
import FalconCardFooterLink from 'components/common/FalconCardFooterLink';

const ProjectStatistics = ({
  projectsTable,
  title,
  setTitle,
  setColumns,
  setallStudents,
  handleEModal
}) => {
  let data = [];
  if (projectsTable.length > 3) {
    data = projectsTable.slice(0, 3);
  } else {
    data = projectsTable;
  }
  // const [option, setOption] = useState('Según Marca');
  // const handleClick = event => {
  //   setOption(event.target.value);
  // };
  return (
    <Card className="h-100">
      <FalconCardHeader title={title} titleTag="h6" />

      <Card.Body className="pt-0">
        <ProjectTable data={data} />

        {/* {option === 'Según Nivel' && (
          <ProjectTable data={projectsTable.levels} />
        )}
        {option === 'Según Núcleo' && (
          <ProjectTable data={projectsTable.basic_cores} />
        )} */}
      </Card.Body>
      {projectsTable.length > 3 && (
        <FalconCardFooterLink
          title="Ver más..."
          size="sm"
          to="#!"
          onClick={() => {
            setTitle(title);
            setColumns([
              {
                accessor: 'project',
                Header: 'Nombre'
              },
              {
                accessor: 'team',
                Header:
                  title === 'Precios por Programa'
                    ? 'Precio'
                    : 'Precio Promedio',
                Cell: rowData => {
                  const { team } = rowData.row.original;
                  return <p> ${team}</p>;
                }
              }
            ]);
            setallStudents(projectsTable);
            handleEModal();
          }}
        />
      )}
    </Card>
  );
};

ProjectStatistics.propTypes = {
  projectsTable: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  setTitle: PropTypes.func,
  setColumns: PropTypes.func,
  setallStudents: PropTypes.func,
  handleEModal: PropTypes.func
};

export default ProjectStatistics;
