import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useImages } from 'hooks';
import Buttons from '../templates/Buttons';
import PropTypes from 'prop-types';
const ImagesForm = ({ submit, item }) => {
  const [image, setImage] = useState(null);
  const [logo, setLogo] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [availabledImage, setAvailabledImage] = useState(null);
  const { images } = useImages();
  const { handleSubmit, watch, setValue, reset } = useForm({
    defaultValues: { id: null }
  });

  //*EVENT CLICK SAVE AND UPDATE
  const onSubmit = () => {
    const formData = new FormData();
    if (item._id) {
      formData.append('_id', item._id);
    }
    if (!image && !availabledImage) {
      formData.append('logo', logo);
      formData.append('image', images._id);
      submit(formData);
      saveImage(
        `${process.env.REACT_APP_BACKEND_URL}/storage/logomarcas/estandar.jpg`
      );
      setImage(null);
      setAvailabledImage(null);
      reset();
    }
  };

  //* EVENT GET ALL RGISTER
  useEffect(() => {
    const fetchLogo = async () => {
      try {
        let logoUrl;
        if (item._id) {
          setValue('id', item._id);
          setValue('name', item.name);
          setValue('about', item.about);
          setValue('position', item.position);
          logoUrl = `${process.env.REACT_APP_BACKEND_URL}/storage/gallery/${item.image}`;
        } else {
          logoUrl = `${process.env.REACT_APP_BACKEND_URL}/storage/logomarcas/estandar.jpg`;
        }
        saveImage(logoUrl);
      } catch (error) {
        console.error('Error al obtener el archivo:', error);
      }
    };

    fetchLogo();
  }, [watch('id')]);

  const saveImage = async logoUrl => {
    setImageUrl(logoUrl);
    const response = await fetch(logoUrl);
    const blob = await response.blob();
    const file = new File([blob], 'logo.jpg', { type: 'image/jpeg' });
    setLogo(file);
    setValue('logo', [file]);
  };

  const verifyImage = async logo => {
    setImage(null);
    const file = logo;
    if (file) {
      setImageUrl(URL.createObjectURL(file));
      if (file.type && file.type.startsWith('image/')) {
        try {
          if (file.size < 42000000) {
            setLogo(file);
          } else {
            setAvailabledImage('El tamaño del fichero excede los 2mb!');
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        setImage('Este fichero no es una imagen!');
      }
    }
  };

  return (
    <Row>
      <Col>
        <Form noValidate onSubmit={handleSubmit(onSubmit)} className="px-1">
          <Form.Group>
            <Form.Label className="mb-1">Imagen (*)</Form.Label>
            <Form.Control
              size="sm"
              type="file"
              id="logo"
              name="logo"
              onChange={e => {
                verifyImage(e.target.files[0]);
              }}
            />
            {image !== null && (
              <h6 key="errorMessage" className="text-danger fs--2 mt-2 mb-0">
                {image}
              </h6>
            )}
          </Form.Group>
          <div className="mb-3">
            {imageUrl && image === null && (
              <div
                style={{
                  width: '150px',
                  height: '150px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 'auto',
                  marginTop: '10px'
                }}
              >
                <img
                  src={imageUrl}
                  alt="Preview"
                  style={{
                    objectFit: 'cover',
                    maxWidth: '100%',
                    maxHeight: '100%'
                  }}
                />
              </div>
            )}
            <Form.Label
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 'auto',
                color: '#000'
              }}
            >
              {' '}
              Solo deben ir imágenes cuadradas
            </Form.Label>
            <Form.Label
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 'auto',
                color: '#000'
              }}
            >
              {' '}
              Tamaño máximo: 1mb
            </Form.Label>
            <Form.Label
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 'auto',
                color: '#000'
              }}
            >
              {' '}
              Formato permitido: .png .jpg .jpeg
            </Form.Label>
          </div>

          <Buttons
            btn={'create'}
            caption={watch('id') ? 'Actualizar Datos' : 'Guardar Datos'}
          />
        </Form>
      </Col>
    </Row>
  );
};

ImagesForm.propTypes = {
  submit: PropTypes.func.isRequired,
  item: PropTypes.object,
  items: PropTypes.array
};

export default ImagesForm;
