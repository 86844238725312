import React from 'react';
import { Card, Col, ProgressBar, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDefault } from 'hooks';
import FalconCardHeader from 'components/common/FalconCardHeader';
import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import createMarkup from 'helpers/createMarkup';

const University = ({ item, index }) => {
  const { _id, nombre, cantidad, ciudad } = item;
  return (
    <Row
      className={classNames('g-0 align-items-center pb-3', {
        'border-top pt-3': index !== 0
      })}
    >
      <Col className="pe-4">
        <h6 className="fs--2 text-600">{nombre}</h6>

        <ProgressBar className="mt-xxl-auto" style={{ height: 5 }}>
          <ProgressBar
            variant={'primary'}
            now={cantidad}
            key={_id}
            className="rounded-3"
          />
        </ProgressBar>
      </Col>
      <Col xs="auto" className="text-end">
        <p className="mb-0 text-900 font-sans-serif">
          <FontAwesomeIcon icon={'caret-up'} className={`text-success me-1`} />
          {cantidad}
        </p>
        <p
          className="mb-0 fs--2 text-500 fw-semi-bold"
          dangerouslySetInnerHTML={createMarkup(
            `<span class ="text-600">${ciudad}</span>`
          )}
        />
      </Col>
    </Row>
  );
};

const Top20University = ({ data, toShow }) => {
  const { handleCModal } = useDefault();

  return (
    <Card className="h-100">
      <FalconCardHeader
        title="Top 40 x Universidad"
        titleTag="h6"
        className="py-2"
      />
      <Card.Body className="py-0 d-flex align-items-center h-100">
        <div className="flex-1">
          {data
            ?.slice(0, toShow !== 'f' ? toShow : data.length)
            .map((item, index) => (
              <University item={item} key={item._id} index={index} />
            ))}
        </div>
      </Card.Body>

      {toShow !== 'f' && (
        <FalconCardFooterLink
          title="mostrar listado completo"
          size="sm"
          onClick={handleCModal}
        />
      )}
    </Card>
  );
};

University.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string,
    nombre: PropTypes.string,
    cantidad: PropTypes.number,
    ciudad: PropTypes.string
  }),
  index: PropTypes.number
};

Top20University.propTypes = {
  data: PropTypes.array,
  toShow: PropTypes.oneOfType([PropTypes.oneOf(['f']), PropTypes.number])
};

export default Top20University;
