import React, { useEffect } from 'react';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useDefault } from 'hooks';
import Buttons from 'components/templates/Buttons';

import { EModal, DModal, CModal } from 'components/templates/Modal';
import Datatable from 'components/templates/datatable/Datatable';

const Scholarship = ({ authorized, route, useHook }) => {
  if (!authorized) return <Navigate to="/dashboard" />;

  const navigate = useNavigate();

  const { emodal, handleEModal, dmodal, handleDModal, cmodal, handleCModal } =
    useDefault();
  const {
    setItem,
    item,
    items,
    submit,
    removeOne,
    changeStatus,
    columns,
    name,
    nameProgram,
    nameBootCamp,
    nameMentoring,
    Form_,
    modalSize,
    confirm
  } = useHook();

  useEffect(() => {
    if (items === undefined) navigate(`/cms/${route}s`, { replace: true });
  }, [navigate, route, items]);

  useEffect(() => {
    if (route === 'course') {
      if (name === null) navigate(`/cms/${route}s`, { replace: true });
    } else if (route === 'program') {
      if (nameProgram === null) navigate(`/cms/${route}s`, { replace: true });
    } else if (route === 'bootcamp') {
      if (nameBootCamp === null) navigate(`/cms/${route}s`, { replace: true });
    } else if (route === 'mentoring') {
      if (nameMentoring === null) navigate(`/cms/${route}s`, { replace: true });
    }
  }, [navigate, route, name, items, nameProgram, nameBootCamp, nameMentoring]);

  //* FOR CLEAR FORM ON-CLOSE MODAL'S
  useEffect(() => {
    !emodal && setItem({});
  }, [emodal]);

  useEffect(() => {
    !dmodal && setItem({});
  }, [dmodal]);

  useEffect(() => {
    !cmodal && setItem({});
  }, [cmodal]);

  return (
    <>
      <Link to={`/cms/${route}s`}>
        <Buttons btn={'return'} />
      </Link>
      {Form_ && (
        <Buttons btn={'add'} caption={'Beca'} handleEvent={handleEModal} />
      )}
      {/* {items !== undefined && ( */}
      <Datatable
        title={`${
          route === 'course'
            ? `Listado de Beca Futura del Curso ${name}`
            : route === 'mentoring'
            ? `Listado de Beca Futura de la Mentoría ${nameMentoring}`
            : route === 'bootcamp'
            ? `Listado de Beca Futura del Bootcamps ${nameBootCamp}`
            : route === 'program'
            ? `Listado de Beca Futura del Programa ${nameProgram}`
            : null
        }`}
        data={items || []}
        columns={columns}
      />
      {/* )} */}
      {Form_ && (
        <EModal
          form_={<Form_ submit={submit} item={item} items={items} />}
          title={'Beca'}
          modal={emodal}
          modalSize={modalSize}
          handleModal={handleEModal}
        />
      )}
      <DModal
        item={item}
        modal={dmodal}
        handleModal={handleDModal}
        handleClick={removeOne}
      />
      {confirm && (
        <CModal
          item={item}
          modal={cmodal}
          handleModal={handleCModal}
          handleClick={changeStatus}
        />
      )}
    </>
  );
};

Scholarship.propTypes = {
  route: PropTypes.string,
  authorized: PropTypes.bool,
  useHook: PropTypes.func.isRequired
};

export default Scholarship;
