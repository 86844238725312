import React, { useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import Buttons from '../templates/Buttons';
import PropTypes from 'prop-types';

const STATE = [
  { id: 'approved', state: 'Aprobada' },
  { id: 'rejected', state: 'Rechazada' },
  { id: 'review', state: 'Revision' }
];

const ApplicationsForm = ({ submit, item }) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors }
  } = useForm({ defaultValues: { id: null } });

  //*EVENT CLICK SAVE AND UPDATE
  const onSubmit = data => {
    const foundState = STATE.find(item => item.id === data.state);
    data.state = foundState.id;
    submit(data);
    reset();
  };

  //* EVENT GET ALL RGISTER
  useEffect(() => {
    if (item._id) {
      setValue('id', item._id);
      setValue('state', item.state);
    }
  }, [watch('id')]);

  return (
    <Row>
      <Col>
        <Form noValidate onSubmit={handleSubmit(onSubmit)} className="px-1">
          <div id="state-row" className="mb-3">
            <Form.Group>
              <Form.Label className="mb-1 mt-2">Cambiar Estado</Form.Label>
              <Form.Select
                size="sm"
                id="state"
                isInvalid={!!errors.state}
                {...register('state', { required: true })}
              >
                {STATE.map(dat => (
                  <option key={dat.id} value={dat.id}>
                    {dat.state}
                  </option>
                ))}
              </Form.Select>

              <Form.Control.Feedback type="invalid">
                {errors.state && errors.state.message}
              </Form.Control.Feedback>
            </Form.Group>
          </div>

          <Buttons
            btn={'create'}
            caption={watch('id') ? 'Actualizar Datos' : 'Guardar Datos'}
          />
        </Form>
      </Col>
    </Row>
  );
};

ApplicationsForm.propTypes = {
  submit: PropTypes.func.isRequired,
  item: PropTypes.object,
  items: PropTypes.array
};

export default ApplicationsForm;
