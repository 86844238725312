import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';

const PerfomanceTableRow = ({ campaigns, cost }) => {
  return (
    <tr>
      <td
        className={`${
          localStorage.getItem('isDark') === 'true'
            ? 'text-truncate text-400'
            : 'text-truncate text-700'
        }`}
      >
        {campaigns}
      </td>
      <td
        className={`${
          localStorage.getItem('isDark') === 'true'
            ? 'text-truncate text-end text-400'
            : 'text-truncate text-end text-700'
        }`}
      >
        ${cost}
      </td>
      {/* <td className="text-truncate text-end">${revenue}</td> */}
    </tr>
  );
};

PerfomanceTableRow.propTypes = {
  campaigns: PropTypes.string,
  cost: PropTypes.string
  // revenue: PropTypes.number.isRequired
};

const PerfomanceTable = ({ data }) => {
  return (
    <SimpleBarReact>
      <Table className="fs-10 mb-0 overflow-hidden">
        <thead className="bg-100">
          <tr>
            <th className="text-800 text-nowrap">Nombre</th>
            <th className="text-800 text-nowrap text-end">Precio Promedio</th>
            {/* <th className="text-800 text-nowrap text-end">Revenue from Ads</th> */}
          </tr>
        </thead>
        <tbody>
          {data.map(item => (
            <PerfomanceTableRow
              key={item.id}
              campaigns={item.label}
              cost={item.team}
              // revenue={item.revenue}
            />
          ))}
        </tbody>
      </Table>
    </SimpleBarReact>
  );
};

PerfomanceTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape(PerfomanceTableRow.propTypes))
    .isRequired
};

export default PerfomanceTable;
