import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useDefault } from 'hooks';

import { EModal, DModal, CModal } from 'components/templates/Modal';
import Buttons from 'components/templates/Buttons';
import Datatable from 'components/templates/datatable/Datatable';

import BackDrop from 'components/templates/BackDrop';

const Default = ({ authorized, useHook }) => {
  if (!authorized) return <Navigate to="/dashboard" />;

  const {
    emodal,
    handleEModal,
    dmodal,
    handleDModal,
    cmodal,
    handleCModal,
    fmodal,
    handleFModal
  } = useDefault();
  const {
    setItem,
    item,
    items,
    submit,
    removeOne,
    changeStatus,
    changeFutura,
    caption,
    captions,
    columns,
    Form_,
    modalSize,
    confirm
  } = useHook();

  //* FOR CLEAR FORM ON-CLOSE MODAL'S
  useEffect(() => {
    !emodal && setItem({});
  }, [emodal]);
  useEffect(() => {
    !dmodal && setItem({});
  }, [dmodal]);
  useEffect(() => {
    !cmodal && setItem({});
  }, [cmodal]);
  useEffect(() => {
    !fmodal && setItem({});
  }, [fmodal]);

  if (items === undefined) return <BackDrop param={'los Programas'} />;

  return (
    <>
      {Form_ && (
        <Buttons btn={'add'} caption={caption} handleEvent={handleEModal} />
      )}

      <Datatable
        title={`Listado de ${captions}`}
        data={items}
        columns={columns}
      />

      {Form_ && (
        <EModal
          dialogClassName="max-width: 900px;"
          form_={
            <Form_
              submit={submit}
              item={item === null ? {} : item}
              items={items}
              modal={emodal}
            />
          }
          title={caption}
          modal={emodal}
          modalSize={modalSize}
          handleModal={handleEModal}
        />
      )}

      <DModal
        item={item === null ? {} : item}
        modal={dmodal}
        handleModal={handleDModal}
        handleClick={removeOne}
      />

      {(caption !== 'E-Learning' || caption !== 'Programa') && confirm && (
        <CModal
          item={item === null ? {} : item}
          modal={cmodal}
          handleModal={handleCModal}
          handleClick={changeStatus}
        />
      )}
      {(caption === 'Curso/Bootcamp' || caption === 'Programa') && confirm && (
        <CModal
          item={item === null ? {} : item}
          modal={fmodal}
          handleModal={handleFModal}
          handleClick={changeFutura}
        />
      )}
    </>
  );
};

Default.propTypes = {
  useHook: PropTypes.func,
  authorized: PropTypes.bool
};

export default Default;
