import React from 'react';
import { Col, Card, Row, Tab, Tabs } from 'react-bootstrap';
// import { accountInfo } from 'data/ecommerce/customerDetailsData';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import dayjs from 'helpers/dayjs';
import Datatable from 'components/templates/datatable/Datatable';
import Avatar from 'components/common/Avatar';
const CustomerInfo = ({ items }) => {
  console.log(items.programs);
  // let cont = 1;
  // let contCourse = 1;
  const columnsCourse = [
    {
      accessor: 'name',
      Header: 'Nombre',
      Cell: rowData => {
        let name = rowData.row.original.course?.name;

        return (
          <div className="d-flex align-items-center">
            <Avatar size="l" name={name} />
            <div className="ms-2">{name}</div>
          </div>
        );
      }
    },
    {
      accessor: 'client',
      Header: 'Cliente',
      Cell: rowData => {
        let name = rowData.row.original.course?.mark
          ? rowData.row.original.course?.mark?.name
          : rowData.row.original.course?.university?.name;

        return (
          <div className="d-flex align-items-center">
            <div className="ms-2">{name}</div>
          </div>
        );
      }
    },
    {
      accessor: 'modality',
      Header: 'Modalidad',
      Cell: rowData => {
        let modality = rowData.row.original.course?.modality?.name;

        return (
          <div className="d-flex align-items-center">
            <div className="ms-2">{modality}</div>
          </div>
        );
      }
    },
    {
      accessor: 'level',
      Header: 'Nivel',
      Cell: rowData => {
        let modality = rowData.row.original.course?.level?.name;

        return (
          <div className="d-flex align-items-center">
            <div className="ms-2">{modality}</div>
          </div>
        );
      }
    },
    {
      accessor: 'register',
      Header: 'Fecha de Aplicación',
      Cell: rowData => {
        return dayjs(
          rowData.row.original.date,
          'YYYY-MM-DDTHH:mm:ss.SSSZ'
        ).format('YYYY/MM/DD');
      }
    }
  ];
  const columnsProgram = [
    {
      accessor: 'name',
      Header: 'Nombre',
      Cell: rowData => {
        let name = rowData.row.original.program?.name;

        return (
          <div className="d-flex align-items-center">
            <Avatar size="l" name={name} />
            <div className="ms-2">{name}</div>
          </div>
        );
      }
    },
    {
      accessor: 'snies',
      Header: 'SNIES',
      Cell: rowData => {
        let name = rowData.row.original.program?.code;

        return (
          <div className="d-flex align-items-center">
            <div className="ms-2">{name}</div>
          </div>
        );
      }
    },
    {
      accessor: 'client',
      Header: 'Universidad',
      Cell: rowData => {
        let name = rowData.row.original.university?.name;

        return (
          <div className="d-flex align-items-center">
            <div className="ms-2">{name}</div>
          </div>
        );
      }
    },
    // {
    //   accessor: 'price',
    //   Header: 'Precio',
    //   Cell: rowData => {
    //     let name = rowData.row.original.program?.price;

    //     return (
    //       <div className="d-flex align-items-center">
    //         <div className="ms-2">${name}</div>
    //       </div>
    //     );
    //   }
    // },
    {
      accessor: 'city',
      Header: 'Ciudad',
      Cell: rowData => {
        let name = rowData.row.original.program?.city?.name;

        return (
          <div className="d-flex align-items-center">
            <div className="ms-2">{name}</div>
          </div>
        );
      }
    },
    {
      accessor: 'modality',
      Header: 'Modalidad',
      Cell: rowData => {
        let modality = rowData.row.original.program?.modality?.name;

        return (
          <div className="d-flex align-items-center">
            <div className="ms-2">{modality}</div>
          </div>
        );
      }
    },
    {
      accessor: 'level',
      Header: 'Nivel',
      Cell: rowData => {
        let level = rowData.row.original.program?.level?.name;

        return (
          <div className="d-flex align-items-center">
            <div className="ms-2">{level}</div>
          </div>
        );
      }
    },
    {
      accessor: 'register',
      Header: 'Fecha de Aplicación',
      Cell: rowData => {
        return (
          <div className="d-flex align-items-center">
            <div className="ms-2">
              {dayjs(
                rowData.row.original.date,
                'YYYY-MM-DDTHH:mm:ss.SSSZ'
              ).format('YYYY/MM/DD')}
            </div>
          </div>
        );
      }
    }
  ];
  const columnsBank = [
    {
      accessor: 'name',
      Header: 'Nombre',
      Cell: rowData => {
        let name = rowData.row.original.bank?.name;

        return (
          <div className="d-flex align-items-center">
            <Avatar size="l" name={name} />
            <div className="ms-2">{name}</div>
          </div>
        );
      }
    },
    {
      accessor: 'range',
      Header: 'Rango',
      Cell: rowData => {
        let name = rowData.row.original.bank?.range?.range;

        return (
          <div className="d-flex align-items-center">
            <div className="ms-2">{name}</div>
          </div>
        );
      }
    },
    {
      accessor: 'tasaN',
      Header: 'Tasa N.MV',
      Cell: rowData => {
        let name = rowData.row.original.bank?.rate_nmv;

        return (
          <div className="d-flex align-items-center">
            <div className="ms-2">{name}</div>
          </div>
        );
      }
    },
    {
      accessor: 'tasaE',
      Header: 'Tasa EA',
      Cell: rowData => {
        let name = rowData.row.original.bank?.rate_ea;

        return (
          <div className="d-flex align-items-center">
            <div className="ms-2">{name}</div>
          </div>
        );
      }
    },
    // {
    //   accessor: 'modality',
    //   Header: 'Modalidad',
    //   Cell: rowData => {
    //     let modality = rowData.row.original.program.modality.name;

    //     return (
    //       <div className="d-flex align-items-center">
    //         <div className="ms-2">{modality}</div>
    //       </div>
    //     );
    //   }
    // },
    // {
    //   accessor: 'level',
    //   Header: 'Nivel',
    //   Cell: rowData => {
    //     let level = rowData.row.original.program.level.name;

    //     return (
    //       <div className="d-flex align-items-center">
    //         <div className="ms-2">{level}</div>
    //       </div>
    //     );
    //   }
    // },
    {
      accessor: 'register',
      Header: 'Fecha de Aplicación',
      Cell: rowData => {
        return (
          <div className="d-flex align-items-center">
            <div className="ms-2">
              {dayjs(
                rowData.row.original.date,
                'YYYY-MM-DDTHH:mm:ss.SSSZ'
              ).format('YYYY/MM/DD')}
            </div>
          </div>
        );
      }
    }
  ];
  const columnsOrientation = [
    {
      accessor: 'name',
      Header: 'Nombre',
      Cell: rowData => {
        let name = rowData.row.original.orientation?.name;

        return (
          <div className="d-flex align-items-center">
            <Avatar size="l" name={name} />
            <div className="ms-2">{name}</div>
          </div>
        );
      }
    },
    {
      accessor: 'price',
      Header: 'Precio',
      Cell: rowData => {
        let name = rowData.row.original.orientation?.price;

        return (
          <div className="d-flex align-items-center">
            <div className="ms-2">{name}</div>
          </div>
        );
      }
    },
    {
      accessor: 'financing',
      Header: 'Tipo Test',
      Cell: rowData => {
        let name = rowData.row.original.orientation?.financing;

        return (
          <div className="d-flex align-items-center">
            <div className="ms-2">{name}</div>
          </div>
        );
      }
    },
    {
      accessor: 'register',
      Header: 'Fecha de Aplicación',
      Cell: rowData => {
        return (
          <div className="d-flex align-items-center">
            <div className="ms-2">
              {dayjs(
                rowData.row.original.date,
                'YYYY-MM-DDTHH:mm:ss.SSSZ'
              ).format('YYYY/MM/DD')}
            </div>
          </div>
        );
      }
    }
  ];
  return (
    <Card className="mb-3">
      <Card.Header>
        <Row className="align-items-center">
          <Col>
            <h5 className="mb-0">Detalles</h5>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="bg-body-tertiary border-top">
        <Row>
          <h6 className="fw-semibold ls mb-3 text-uppercase">
            Datos del usuario
          </h6>
          <Col lg xxl={5}>
            <Row>
              <Col xs={1} sm={3}>
                <p className="fw-semibold mb-1">Document</p>
              </Col>
              <Col>
                <p
                  className={classNames('mb-1', {
                    'fst-italic text-400': false
                  })}
                >
                  {items.user.document ? items.user.document : '------'}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={1} sm={3}>
                <p className="fw-semibold mb-1">Nombre</p>
              </Col>
              <Col>
                <p
                  className={classNames('mb-1', {
                    'fst-italic text-400': false
                  })}
                >
                  {items.user.name}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={1} sm={3}>
                <p className="fw-semibold mb-1">Apellidos</p>
              </Col>
              <Col>
                <p
                  className={classNames('mb-1', {
                    'fst-italic text-400': false
                  })}
                >
                  {items.user.lastname}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={1} sm={3}>
                <p className="fw-semibold mb-1">Correo</p>
              </Col>
              <Col>
                <p
                  className={classNames('mb-1', {
                    'fst-italic text-400': false
                  })}
                >
                  {items.user.email}
                </p>
              </Col>
            </Row>
          </Col>
          <Col lg xxl={5}>
            <Row>
              <Col xs={1} sm={3}>
                <p className="fw-semibold mb-1">Teléfono</p>
              </Col>
              <Col>
                <p
                  className={classNames('mb-1', {
                    'fst-italic text-400': false
                  })}
                >
                  {items.user.phone}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={1} sm={3}>
                <p className="fw-semibold mb-1">Ciudad</p>
              </Col>
              <Col>
                <p
                  className={classNames('mb-1', {
                    'fst-italic text-400': false
                  })}
                >
                  {items.user.city ? items.user.city.name : '------'}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={1} sm={4}>
                <p className="fw-semibold mb-1">Departamento</p>
              </Col>
              <Col>
                <p
                  className={classNames('mb-1', {
                    'fst-italic text-400': false
                  })}
                >
                  {items.user.department
                    ? items.user.department.name
                    : '------'}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={1} sm={4}>
                <p className="fw-semibold mb-1">Fecha de Registro</p>
              </Col>
              <Col>
                <p
                  className={classNames('mb-1', {
                    'fst-italic text-400': false
                  })}
                >
                  {dayjs(
                    items.user.createdAt,
                    'YYYY-MM-DDTHH:mm:ss.SSSZ'
                  ).format('YYYY/MM/DD')}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
      {/* <Card.Body className="bg-body-tertiary border-top">
        <Row>
          <h6 className="fw-semibold ls mb-3 text-uppercase">Programas</h6>
          {items.programs.length === 0 && (
            <p className="fw-semibold mb-1">
              El usuario no se ha interesado por un programa
            </p>
          )}
          {items.programs.length > 0 &&
            items.programs.map(item => (
              <Row key={item.program.code} style={{ marginTop: '10px' }}>
                <Col lg xxl={1}>
                  <div className="text-center" style={{ marginTop: '20px' }}>
                    {cont++}
                  </div>
                </Col>
                <Col lg xxl={6}>
                  <Row>
                    <Col xs={1} sm={3}>
                      <p className="fw-semibold mb-1">SNIES:</p>
                    </Col>
                    <Col>
                      <p
                        className={classNames('mb-1', {
                          'fst-italic text-400': false
                        })}
                      >
                        {item.program.code}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={1} sm={3}>
                      <p className="fw-semibold mb-1">Nombre:</p>
                    </Col>
                    <Col>
                      <p
                        className={classNames('mb-1', {
                          'fst-italic text-400': false
                        })}
                      >
                        {item.program.name}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={1} sm={3}>
                      <p className="fw-semibold mb-1">Universidad:</p>
                    </Col>
                    <Col>
                      <p
                        className={classNames('mb-1', {
                          'fst-italic text-400': false
                        })}
                      >
                        {item.university.name}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col lg xxl={5}>
                  <Row>
                    <Col xs={1} sm={4}>
                      <p className="fw-semibold mb-1">Modalidad:</p>
                    </Col>
                    <Col>
                      <p
                        className={classNames('mb-1', {
                          'fst-italic text-400': false
                        })}
                      >
                        {item.program.modality.name}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={1} sm={3}>
                      <p className="fw-semibold mb-1">Nivel:</p>
                    </Col>
                    <Col>
                      <p
                        className={classNames('mb-1', {
                          'fst-italic text-400': false
                        })}
                      >
                        {item.program.level.name}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={1} sm={6}>
                      <p className="fw-semibold mb-1">Fecha de Aplicación:</p>
                    </Col>
                    <Col>
                      <p
                        className={classNames('mb-1', {
                          'fst-italic text-400': false
                        })}
                      >
                        {dayjs(
                          item.program.date,
                          'YYYY-MM-DDTHH:mm:ss.SSSZ'
                        ).format('YYYY/MM/DD')}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))}
        </Row>
      </Card.Body> */}
      {/* <Card.Body className="bg-body-tertiary border-top">
        <Row>
          <h6 className="fw-semibold ls mb-3 text-uppercase">Cursos</h6>
          {items.courses.length === 0 && (
            <p className="fw-semibold mb-1">
              El usuario no se ha interesado por un curso
            </p>
          )}
          {items.courses.length > 0 &&
            items.courses.map(item => (
              <Row key={item.course.name}>
                <Col lg xxl={1}>
                  <div className="text-center" style={{ marginTop: '20px' }}>
                    {contCourse++}
                  </div>
                </Col>
                <Col lg xxl={6}>
                  <Row>
                    <Col xs={1} sm={4}>
                      <p className="fw-semibold mb-1">
                        {item.courses?.course?.mark ? 'Marca' : 'Universidad'}
                      </p>
                    </Col>
                    <Col>
                      <p
                        className={classNames('mb-1', {
                          'fst-italic text-400': false
                        })}
                      >
                        {item.course?.mark
                          ? item.course.mark.name
                          : item.course.university.name}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={1} sm={3}>
                      <p className="fw-semibold mb-1">Nombre:</p>
                    </Col>
                    <Col>
                      <p
                        className={classNames('mb-1', {
                          'fst-italic text-400': false
                        })}
                      >
                        {item.course.name}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={1} sm={5}>
                      <p className="fw-semibold mb-1">Fecha de Aplicación:</p>
                    </Col>
                    <Col>
                      <p
                        className={classNames('mb-1', {
                          'fst-italic text-400': false
                        })}
                      >
                        {dayjs(
                          item.course.date,
                          'YYYY-MM-DDTHH:mm:ss.SSSZ'
                        ).format('YYYY/MM/DD')}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col lg xxl={5}>
                  <Row>
                    <Col xs={1} sm={4}>
                      <p className="fw-semibold mb-1">Modalidad:</p>
                    </Col>
                    <Col>
                      <p
                        className={classNames('mb-1', {
                          'fst-italic text-400': false
                        })}
                      >
                        {item.course.modality.name}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={1} sm={3}>
                      <p className="fw-semibold mb-1">Nivel:</p>
                    </Col>
                    <Col>
                      <p
                        className={classNames('mb-1', {
                          'fst-italic text-400': false
                        })}
                      >
                        {item.course.level.name}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))}
        </Row>
      </Card.Body> */}
      <Tabs defaultActiveKey="prgram" id="uncontrolled-tab-example">
        <Tab eventKey="prgram" title="Programas">
          <Card.Body className="bg-body-tertiary border-top">
            <Datatable
              title={`PROGRAMAS`}
              data={items.programs}
              columns={columnsProgram}
            />
          </Card.Body>
        </Tab>
        <Tab eventKey="course" title="Cursos">
          <Card.Body className="bg-body-tertiary border-top">
            <Datatable
              title={`CURSOS`}
              data={items.courses}
              columns={columnsCourse}
            />
          </Card.Body>
        </Tab>
        <Tab eventKey="bank" title="Bancos">
          <Card.Body className="bg-body-tertiary border-top">
            <Datatable
              title={`BANCOS`}
              data={items.banks}
              columns={columnsBank}
            />
          </Card.Body>
        </Tab>
        <Tab eventKey="orientation" title="Orientaciones">
          <Card.Body className="bg-body-tertiary border-top">
            <Datatable
              title={`ORIENTATCIÓN`}
              data={items.orientations}
              columns={columnsOrientation}
            />
          </Card.Body>
        </Tab>
      </Tabs>
      {/* <Card.Footer className="border-top text-end">
        <IconButton
          iconClassName="fs-11 me-1"
          variant="falcon-default"
          size="sm"
          icon="dollar-sign"
        >
          Refund
        </IconButton>
        <IconButton
          className="ms-2"
          iconClassName="fs-11 me-1"
          variant="falcon-default"
          size="sm"
          icon="check"
        >
          Save changes
        </IconButton>
      </Card.Footer> */}
    </Card>
  );
};
CustomerInfo.propTypes = {
  // data: PropTypes.array,
  items: PropTypes.object
};

export default CustomerInfo;
