import React, { useState, useEffect } from 'react';
import { Row, Col, Alert } from 'react-bootstrap';
import { useDefault } from 'hooks';
import BackDrop from 'components/templates/BackDrop';

import TopPages from 'components/dashboards/analytics/top-pages/TopPages';
import TopProducts from 'components/dashboards/default/TopProducts';
import CustomerSatisfaction from 'components/dashboards/customer-satisfaction/CustomerSatisfaction';
import TableModal from 'components/dashboards/futura/TableModal';
import { EModal } from 'components/templates/Modal';
export const marketShare = [
  { id: 1, value: 53000000, name: 'Bogotá', color: 'primary' },
  { id: 2, value: 19000000, name: 'Medellín', color: 'info' },
  { id: 3, value: 20000000, name: 'Cali', color: 'gray-300' }
];
const Accredited = () => {
  const [universitiesAccredited, setUniversitiesAccredited] = useState([]);
  const [programsAccredited, setProgramsAccredited] = useState([]);
  const { emodal, handleEModal, list } = useDefault();
  const [columns, setColumns] = useState([]);
  const [allStudents, setallStudents] = useState([]);
  const [title, setTitle] = useState('');

  useEffect(() => {
    getUniversitiesAccredited();
    getProgramsAccredited();
    // getUser_university();
  }, []);

  const getUniversitiesAccredited = () => {
    list('dashboard_university/data_university_accredited').then(data => {
      data && setUniversitiesAccredited(data);
    });
  };
  const getProgramsAccredited = () => {
    list('dashboard_university/data_program_accredited').then(data => {
      data && setProgramsAccredited(data);
    });
  };

  if (
    Object.keys(programsAccredited).length === 0 ||
    programsAccredited === undefined ||
    Object.keys(universitiesAccredited).length === 0 ||
    universitiesAccredited === undefined
  )
    return <BackDrop param={' '} />;
  if (
    programsAccredited?.cities?.length > 0 &&
    programsAccredited?.universities?.length > 0 &&
    programsAccredited?.education_levels?.length > 0 &&
    universitiesAccredited?.cities?.length > 0 &&
    universitiesAccredited?.sectors?.length > 0
  )
    return (
      <>
        <Row className="g-3 mb-3">
          <Alert.Heading>Total de Universidades Acreditadas</Alert.Heading>
          <Col md={6} xxl={6}>
            <TopPages
              tableData={universitiesAccredited.cities}
              perPage={5}
              title="Según Ciudad"
            />
          </Col>
          {/* <Col>
            <TopPages
              tableData={universitiesAccredited.sectors}
              perPage={5}
              title="Según Sector"
            />
          </Col> */}
          <Col md={6} xxl={3}>
            <CustomerSatisfaction
              data={universitiesAccredited.sectors[0]}
              title={`Sector ${universitiesAccredited.sectors[0].project}`}
            />
          </Col>
          <Col md={6} xxl={3}>
            <CustomerSatisfaction
              data={universitiesAccredited.sectors[1]}
              title={`Sector ${universitiesAccredited.sectors[1].project}`}
            />
          </Col>
        </Row>
        <Row className="g-3 mb-3">
          <Alert.Heading>Total de Programas Acreditados</Alert.Heading>
          <Col md={6} xxl={6}>
            <TopPages
              tableData={programsAccredited.universities}
              perPage={5}
              title="Por Universidad"
            />
          </Col>
          <Col md={6} xxl={6}>
            <TopPages
              tableData={programsAccredited.education_levels}
              perPage={5}
              title="Por Nivel de Formacion"
            />
          </Col>
        </Row>
        <Row className="g-3 mb-3">
          <Col lg="12">
            {/* <ReportForThisWeek data={programsAccredited.cities.slice(0, 9)} /> */}
            <TopProducts
              data={programsAccredited.cities.slice(0, 9)}
              data2={programsAccredited.cities2}
              title="Por Ciudad"
              setColumns={setColumns}
              allStudents={allStudents}
              setallStudents={setallStudents}
              handleEModal={handleEModal}
              setTitle={setTitle}
            />
            {/* <TopPages
              tableData={programsAccredited.cities}
              perPage={5}
              title="Por Ciudad"
            /> */}
          </Col>
        </Row>
        <EModal
          form_={
            <TableModal items={allStudents} title={title} columns={columns} />
          }
          title={''}
          modalSize={'lg'}
          modal={emodal}
          handleModal={handleEModal}
        />
      </>
    );
};

export default Accredited;
