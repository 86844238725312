import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useDefault, useApplications } from 'hooks';

import { EModal, DModal, CModal } from 'components/templates/Modal';
import Datatable from 'components/templates/datatable/Datatable';

const Applications = ({ authorized, route }) => {
  if (!authorized) return <Navigate to="/dashboard" />;

  const { emodal, handleEModal, dmodal, handleDModal, cmodal, handleCModal } =
    useDefault();
  const {
    setItem,
    item,
    submit,
    removeOne,
    changeStatus,
    course,
    mentoring,
    bootcamp,
    program,
    columns,
    // columnsCourse,
    // columnsMentoring,
    // columnsProgram,
    // columnsBootcamp,
    Form_,
    modalSize,
    confirm
  } = useApplications();

  //* FOR CLEAR FORM ON-CLOSE MODAL'S
  useEffect(() => {
    !emodal && setItem({});
  }, [emodal]);

  useEffect(() => {
    !dmodal && setItem({});
  }, [dmodal]);

  useEffect(() => {
    !cmodal && setItem({});
  }, [cmodal]);
  return (
    <>
      <Datatable
        title={
          `Listado de usuarios`
          // ${
          //   route === 'course'
          //     ? 'Cursos'
          //     : route === 'mentoring'
          //     ? 'Mentorías'
          //     : route === 'bootcamp'
          //     ? 'Bootcamps'
          //     : route === 'program'
          //     ? 'Programas'
          //     : null
          //   }`
        }
        data={
          route === 'course'
            ? course
            : route === 'mentoring'
            ? mentoring
            : route === 'bootcamp'
            ? bootcamp
            : route === 'program'
            ? program
            : null
        }
        columns={columns}
      />

      {Form_ && (
        <EModal
          form_={
            <Form_
              submit={submit}
              item={item}
              items={
                route === 'course'
                  ? course
                  : route === 'mentoring'
                  ? mentoring
                  : route === 'bootcamp'
                  ? bootcamp
                  : route === 'program'
                  ? program
                  : null
              }
            />
          }
          title={
            route === 'course'
              ? 'Cursos'
              : route === 'mentoring'
              ? 'Mentorías'
              : route === 'bootcamp'
              ? 'Bootcamps'
              : route === 'program'
              ? 'Programas'
              : null
          }
          modal={emodal}
          modalSize={modalSize}
          handleModal={handleEModal}
        />
      )}

      <DModal
        item={item}
        modal={dmodal}
        handleModal={handleDModal}
        handleClick={removeOne}
      />

      {confirm && (
        <CModal
          item={item}
          modal={cmodal}
          handleModal={handleCModal}
          handleClick={changeStatus}
        />
      )}
    </>
  );
};

Applications.propTypes = {
  route: PropTypes.string,
  authorized: PropTypes.bool
};

export default Applications;
