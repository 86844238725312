import React, { useEffect, useState, createContext } from 'react';
import PropTypes from 'prop-types';

import { useDefault, useAuth } from 'hooks';
import { useLocation } from 'react-router-dom';

// import { columnsCourse } from 'components/Applications/course';
// import { columnsMentoring } from 'components/Applications/mentoring';
// import { columnsProgram } from 'components/Applications/program';
// import { columnsBootcamp } from 'components/Applications/bootcamp';
import Form_ from 'components/forms/ApplicationsForm';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SoftBadge from 'components/common/SoftBadge';
import Avatar from 'components/common/Avatar';
import Buttons from 'components/templates/Buttons';
import Flex from 'components/common/Flex';
import dayjs from 'helpers/dayjs';

const ApplicationsContext = createContext();

const ApplicationsProvider = ({ children }) => {
  const { auth } = useAuth();
  const location = useLocation();
  const splitted = location.pathname.split('-');
  const result = splitted[splitted.length - 1];
  let headerText;
  let headerTextMark;
  if (result === 'course') {
    headerText = 'Curso';
    headerTextMark = 'Marca/Universidad';
  } else if (result === 'program') {
    headerText = 'Programa';
    headerTextMark = 'Universidad';
  } else if (result === 'mentoring') {
    headerText = 'Mentoría';
  } else if (result === 'bootcamp') {
    headerText = 'BootCamp';
  }

  const { list, update, add, remove, handleEModal } = useDefault();

  const [item, setItem] = useState({});
  const [course, setCourse] = useState([]);
  const [mentoring, setMentoring] = useState([]);
  const [bootcamp, setBootcamp] = useState([]);
  const [program, setProgram] = useState([]);

  //* GET ALL REGISTER
  useEffect(() => {
    list('applications-course/list').then(res => {
      res && setCourse(res);
    });
    // list('applications-mentoring/all').then(res => res && setMentoring(res));
    // list('applications-bootcamp/all').then(res => res && setBootcamp(res));
    list('applications-program/list').then(res => {
      res && setProgram(res);
    });
  }, [auth]);

  const removeOne = async () => {
    // Remove(`/bootcamps/delete/${item._id}`).then(() => {
    //   setItems(items.filter(stateObj => stateObj._id !== item._id));
    //   setItem({});
    // });
  };
  let columns;

  columns = [
    {
      accessor: 'name',
      Header: 'Nombre y Apellidos',
      Cell: rowData => {
        let name = 'Desconocido';
        let lastname = 'Desconocido';

        if (rowData.row.original.user) {
          name = rowData.row.original.user?.name;
          lastname = rowData.row.original.user?.lastname;
        }

        return (
          <div className="d-flex align-items-center">
            <Avatar size="l" name={name} />
            <div className="ms-2">{name + ' ' + lastname?.split(' ')[0]}</div>
          </div>
        );
      }
    },
    {
      accessor: 'phone',
      Header: 'Teléfono',
      Cell: rowData => {
        let phone = 'Desconocido';

        if (rowData.row.original.user) {
          phone = rowData.row.original.user?.phone;
        }

        return (
          <div className="d-flex align-items-center">
            <div className="ms-2">{phone}</div>
          </div>
        );
      }
    },
    {
      accessor: 'email',
      Header: 'Correo',
      Cell: rowData => {
        let email = 'Desconocido';

        if (rowData.row.original.user) {
          email = rowData.row.original.user.email;
        }

        return (
          <Link
            className="link-primary font-sans-serif fs--1 d-inline-block"
            to={`mailto:${email}`}
          >
            {email?.split('@')[0]}@...
          </Link>
        );
      }
    },
    {
      accessor: 'university',
      Header: headerTextMark,
      Cell: rowData => {
        let name = 'Desconocido';

        if (rowData.row.original.course) {
          if (rowData.row.original.course?.mark)
            name = rowData.row.original.course?.mark?.name;
          else name = rowData.row.original.course?.university?.name;
        } else {
          name = rowData.row.original.university?.name;
        }

        return (
          <div className="ms-2 text-truncate" style={{ maxWidth: '400px' }}>
            {name}
          </div>
        );
      }
    },
    {
      accessor: `${result}.name`,
      Header: headerText
    },
    {
      accessor: 'createdAt',
      Header: 'Fecha Aplicación',
      Cell: rowData => {
        const { createdAt } = rowData.row.original;
        return dayjs(createdAt, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format(
          'YYYY/MM/DD'
        );
      }
    },
    {
      accessor: 'state',
      Header: 'Estado',
      Cell: rowData => {
        const { state } = rowData.row.original;
        return state !== 'rejected' ? (
          <SoftBadge pill bg={`${state === 'review' ? 'warning' : 'success'}`}>
            {state === 'review' ? 'En revisión' : 'Aceptada'}
            <FontAwesomeIcon
              icon={`${state === 'review' ? 'stream' : 'check'}`}
              className="ms-2"
            />{' '}
          </SoftBadge>
        ) : (
          <SoftBadge pill bg="danger" className="me-2">
            Rechazada
            <FontAwesomeIcon icon="ban" className="ms-2" />
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'btn',
      Header: 'Acciones',
      headerProps: { style: { width: '95px' } },
      Cell: rowData => {
        return (
          <Flex className="gap-1 mt-1.5">
            <Buttons
              btn={'update'}
              handleEvent={() => {
                rowData.row.original.pt = false;
                setItem(rowData.row.original);
                handleEModal();
              }}
            />
          </Flex>
        );
      }
    }
  ];

  const submit = object_ => {
    if (object_.id) {
      object_._id = object_.id;
      update(`applications-${result}/update`, object_).then(res => {
        if (res) {
          if (result === 'course') {
            setCourse(
              course.map(stateObj =>
                stateObj._id === res._id ? res : stateObj
              )
            );
          } else if (result === 'program') {
            setProgram(
              program.map(stateObj =>
                stateObj._id === res._id ? res : stateObj
              )
            );
          } else if (result === 'mentoring') {
            setMentoring(
              mentoring.map(stateObj =>
                stateObj._id === res._id ? res : stateObj
              )
            );
          } else if (result === 'bootcamp') {
            setBootcamp(
              bootcamp.map(stateObj =>
                stateObj._id === res._id ? res : stateObj
              )
            );
          }
        }

        setItem({});
      });
    }
  };

  return (
    <ApplicationsContext.Provider
      value={{
        removeOne,
        update,
        add,
        remove,
        handleEModal,
        submit,
        item,
        setItem,
        course,
        mentoring,
        bootcamp,
        program,
        columns,
        Form_,
        confirm: false
      }}
    >
      {children}
    </ApplicationsContext.Provider>
  );
};

ApplicationsProvider.propTypes = {
  children: PropTypes.node
};

export { ApplicationsProvider };
export default ApplicationsContext;
