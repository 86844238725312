import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import routesMap from './routesMap';

function Routers() {
  const routes = routesMap();

  const renderRoutes = routes => {
    return routes.map((route, i) => {
      const Component = route.component;
      return (
        <Route
          key={i}
          path={route.path}
          exact={route.exact}
          element={<Component {...route.props} />}
        >
          {route.children && renderRoutes(route.children)}
        </Route>
      );
    });
  };

  return <Routes>{renderRoutes(routes)}</Routes>;
}

Routers.propTypes = {
  pt: PropTypes.bool,
  component: PropTypes.element
};

export default Routers;
