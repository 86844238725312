import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import CountUp from 'react-countup';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Background from '../../common/Background';
import classNames from 'classnames';
import ReactSelect from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LeadsWidgets = ({
  // data,
  items,
  title,
  setTitle,
  setallStudents,
  handleEModal,
  background
}) => {
  const [all, setAll] = useState(null);
  const [option, setOption] = useState(null);
  const [cant, setCant] = useState(0);
  let campo = '';
  let name = '';

  switch (title) {
    case 'Ciudad':
      campo = 'cityId';
      name = 'cityName';
      break;
    case 'Nivel':
      campo = 'levelId';
      name = 'levelName';
      break;
    case 'Universidad':
      campo = 'universityId';
      name = 'universityName';
      break;
    case 'Programas':
      campo = 'programId';
      name = 'programName';
      break;
    case 'Cursos':
      campo = 'courseId';
      name = 'courseName';
      break;
    case 'Orientaciones':
      campo = 'orientationId';
      name = 'orientationName';
      break;
    case 'Modalidad':
      campo = 'modalityId';
      name = 'modalityName';
      break;
    case 'Bancos':
      campo = 'bankId';
      name = 'bankName';
      break;
    default:
      campo = 'Opción no reconocida';
  }

  const llenarcant = () => {
    setCant(
      items?.reduce((total, objeto) => total + objeto?.students.length, 0)
    );
  };

  useEffect(() => {
    if (items) {
      setTitle(title);
      const studentArrays = items?.map(item => item?.students);
      setAll([].concat(...studentArrays));
      setallStudents(all);
      llenarcant();
    }
  }, [items]);

  const buscar = () => {
    return items?.find(objeto => objeto?.[campo] == option?.value);
  };

  const cargarAllStudents = () => {
    setallStudents(null);
    if (option && option?.value !== null) {
      const n = buscar();
      setCant(n?.cant);
      setallStudents(n?.students);
    } else {
      llenarcant();
      setallStudents(all);
    }
  };

  useEffect(() => {
    cargarAllStudents();
  }, [option]);

  return (
    <>
      <Card>
        <Background image={background} className="bg-card" />
        <FalconCardHeader
          light
          title={title ? title : ''}
          className="py-2 fs--1"
          endEl={
            <ReactSelect
              closeMenuOnSelect={true}
              options={[{ value: null, label: `Por ${title}` }].concat(
                // data?.map(find => ({
                items?.map(find => ({
                  // value: find?.value,
                  value: find?.[campo],
                  label: find?.[name],
                  cant: find?.students.length
                }))
              )}
              placeholder={`Por ${title}`}
              classNamePrefix="react-select"
              value={option}
              onChange={option => setOption(option)}
            />
          }
        />
        <Card.Body className="py-2 position-relative">
          <div
            className={classNames(
              `display-4 fs-4 mb-2 fw-normal font-sans-serif ${
                title === 'Ciudad'
                  ? 'text-warning'
                  : title === 'Nivel'
                  ? 'text-primary'
                  : title === 'Universidad'
                  ? 'text-info'
                  : title === 'Cursos'
                  ? 'text-info'
                  : title === 'Modalidad'
                  ? 'text-danger'
                  : 'text-success'
              }`
            )}
          >
            <CountUp
              start={0}
              end={cant}
              duration={2.75}
              suffix={''}
              prefix={' '}
              decimals={0}
              decimal="."
            />
          </div>

          <a
            onClick={() => {
              setTitle(title);
              cargarAllStudents();
              handleEModal();
            }}
            className="fw-semi-bold fs--1 text-nowrap cursor-pointer"
          >
            Ver mas ...
            <FontAwesomeIcon
              icon="angle-right"
              className="ms-1"
              transform="down-1"
            />
          </a>
        </Card.Body>
      </Card>
    </>
  );
};

LeadsWidgets.propTypes = {
  // data: PropTypes.array,
  items: PropTypes.array,
  title: PropTypes.string,
  setTitle: PropTypes.func,
  allStudents: PropTypes.array,
  setallStudents: PropTypes.func,
  handleEModal: PropTypes.func,
  background: PropTypes.string
};

export default LeadsWidgets;
