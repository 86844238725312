import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CardDropdown from 'components/common/CardDropdown';
import FalconCardHeader from 'components/common/FalconCardHeader';
import React from 'react';
import { Badge, Card, Col, Row } from 'react-bootstrap';
import CustomerSatisfactionChart from './CustomerSatisfactionChart';
import PropTypes from 'prop-types';

const CustomerSatisfaction = ({ title, data }) => {
  const total = data.accredited + data.noAccredited;
  const percentA = ((data.accredited * 100) / total).toFixed(2);
  const percentN = ((data.noAccredited * 100) / total).toFixed(2);

  return (
    <Card className="card h-100">
      <FalconCardHeader
        title={title}
        titleTag="h6"
        className="border-200 border-bottom py-2"
        endEl={<CardDropdown />}
      />
      <Card.Body className="p-3 d-flex aligm-items-center">
        <CustomerSatisfactionChart item={data} />
      </Card.Body>
      <Card.Footer className="border-top border-200 py-0">
        <Row>
          <Col className="border-end border-200 py-3 d-flex justify-content-center">
            <div>
              <h6 className="text-600 mb-1 fs-11">Acreditada</h6>
              <h6 className="fs-9 mb-0 d-flex align-items-center">
                {data.accredited}
                <Badge
                  pill
                  bg="transparent"
                  className="px-0 text-success d-flex align-items-start"
                >
                  <FontAwesomeIcon
                    icon="caret-up"
                    className="fs-11 ms-2 me-1"
                  />
                  {percentA}%
                </Badge>
              </h6>
            </div>
          </Col>
          <Col className="py-3 d-flex justify-content-center">
            <div>
              <h6 className="text-600 mb-1 fs-11">No Acreditada</h6>
              <h6 className="fs-9 mb-0 d-flex align-items-center">
                {data.noAccredited}
                <Badge
                  pill
                  bg="transparent"
                  className="px-0 text-danger d-flex align-items-start"
                >
                  <FontAwesomeIcon
                    icon="caret-down"
                    className="fs-11 ms-2 me-1"
                  />
                  {percentN}%
                </Badge>
              </h6>
            </div>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};

CustomerSatisfaction.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object
};

export default CustomerSatisfaction;
