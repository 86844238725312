import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import Buttons from '../templates/Buttons';
import PropTypes from 'prop-types';

const MarksCoursesForm = ({ submit, item, items }) => {
  const [availabledMsg, setAvailabledMsg] = useState('');
  const [availabled, setAvailabled] = useState(true);
  const [image, setImage] = useState(null);
  const [logo, setLogo] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [availabledImage, setAvailabledImage] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState();
  const [backgroundImageUrl, setBackgroundImageUrl] = useState();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors }
  } = useForm({ defaultValues: { id: null } });

  //*EVENT CLICK SAVE AND UPDATE
  const onSubmit = data => {
    const formData = new FormData();
    if (item._id) {
      formData.append('_id', item._id);
    }
    if (availabled && !image && !availabledImage) {
      formData.append('logo', logo);
      formData.append('name', data.name);
      formData.append(
        'url_facebook',
        data.url_facebook !== null ? data.url_facebook : ''
      );
      formData.append(
        'url_linkedin',
        data.url_linkedin !== null ? data.url_linkedin : ''
      );
      formData.append(
        'url_instagram',
        data.url_instagram !== null ? data.url_instagram : ''
      );
      formData.append(
        'url_video',
        data.url_video !== null ? data.url_video : ''
      );
      formData.append('background_image', backgroundImage);
      submit(formData);
      saveImage(
        `${process.env.REACT_APP_BACKEND_URL}/storage/logomarcas/estandar.jpg`
      );
      saveImageBackground(
        `${process.env.REACT_APP_BACKEND_URL}/storage/fondo_test.jpg`
      );
      setImage(null);
      setAvailabledImage(null);
      reset();
    }
  };

  //* EVENT GET ALL RGISTER
  useEffect(() => {
    const fetchLogo = async () => {
      try {
        let logoUrl;
        let backgroundURL;
        if (item._id) {
          setValue('id', item._id);
          setValue('name', item.name);
          setValue('url_facebook', item.url_facebook);
          setValue('url_linkedin', item.url_linkedin);
          setValue('url_instagram', item.url_instagram);
          setValue('url_video', item.url_video);
          logoUrl = `${process.env.REACT_APP_BACKEND_URL}/storage/logomarcas/${item.logo}`;
          backgroundURL = `${process.env.REACT_APP_BACKEND_URL}/storage/backgrounds/${item.background_image}`;
        } else {
          logoUrl = `${process.env.REACT_APP_BACKEND_URL}/storage/logomarcas/estandar.jpg`;
          backgroundURL = `${process.env.REACT_APP_BACKEND_URL}/storage/fondo_test.jpg`;
        }
        saveImage(logoUrl);
        saveImageBackground(backgroundURL);
      } catch (error) {
        console.error('Error al obtener el archivo:', error);
      }
    };

    fetchLogo();
  }, [watch('id')]);

  const saveImage = async logoUrl => {
    setImageUrl(logoUrl);
    const response = await fetch(logoUrl);
    const blob = await response.blob();
    const file = new File([blob], 'logo.jpg', { type: 'image/jpeg' });
    setLogo(file);
    setValue('logo', [file]);
  };

  const saveImageBackground = async logoUrl => {
    setBackgroundImageUrl(logoUrl);
    const response = await fetch(logoUrl);
    const blob = await response.blob();
    const file = new File([blob], 'fondo.jpg', { type: 'image/jpeg' });
    setBackgroundImage(file);
  };

  const verifyImage = async logo => {
    setImage(null);
    const file = logo;
    if (file) {
      setImageUrl(URL.createObjectURL(file));
      if (file.type && file.type.startsWith('image/')) {
        try {
          if (file.name !== backgroundImage.name) {
            if (file.size < 21000000) {
              setLogo(file);
            } else {
              setImage('El tamaño del fichero excede los 2mb!');
            }
          } else {
            setImage('El nombre de las imágenes no pueden ser iguales!');
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        setImage('Este fichero no es una imagen!');
      }
    }
  };

  const verifyImageBackground = async image => {
    setAvailabledImage(null);
    const file = image;
    if (file) {
      setBackgroundImageUrl(URL.createObjectURL(file));
      if (file.type && file.type.startsWith('image/')) {
        try {
          if (file.name !== logo.name) {
            if (file.size < 21000000) {
              setBackgroundImage(file);
            } else {
              setAvailabledImage('El tamaño del fichero excede los 2mb!');
            }
          } else {
            setAvailabledImage(
              'El nombre de las imágenes no pueden ser iguales!'
            );
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        setAvailabledImage('Este fichero no es una imagen!');
      }
    }
  };

  useEffect(() => {
    if (!item?._id || item?.name !== watch('name')) {
      setAvailabled(true);
      setAvailabledMsg('');
      items.forEach(dat => {
        if (dat.name === watch('name')) {
          setAvailabled(false);
          setAvailabledMsg('Este nombre está en uso actualmente!');
        }
      });
    }
  }, [watch('name')]);

  return (
    <Row>
      <Col>
        <Form noValidate onSubmit={handleSubmit(onSubmit)} className="px-1">
          <div id="name-row" className="mb-3">
            <Form.Group>
              <Form.Label className="mb-1">Nombre (*)</Form.Label>
              <Form.Control
                size="sm"
                type="text"
                id="name"
                name="name"
                placeholder="Nombre"
                isInvalid={!!errors.name}
                {...register('name', {
                  required: {
                    value: true,
                    message: 'este campo es requerido!'
                  },
                  minLength: {
                    limit: 1,
                    value: 1,
                    message: 'Mínimo 1 carácter!'
                  }
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name && errors.name.message}
              </Form.Control.Feedback>
              {availabled ? null : (
                <h6 className="text-danger fs--2 mt-2 mb-0">{availabledMsg}</h6>
              )}
            </Form.Group>
            <div id="url_facebook-row" className="mb-1">
              <Form.Group>
                <Form.Label className="mb-1">URL de Facebook</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  id="url_facebook"
                  name="url_facebook"
                  placeholder="URL de facebook"
                  isInvalid={!!errors.url_facebook}
                  {...register('url_facebook', {
                    minLength: {
                      limit: 1,
                      value: 1,
                      message: 'Mínimo 1 carácter!'
                    },
                    pattern: {
                      value:
                        '^(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})([/\\w .-]*)*/?$',
                      message: 'URL Inválida!'
                    }
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.url_facebook && errors.url_facebook.message}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div id="url_linkedin-row" className="mb-1">
              <Form.Group>
                <Form.Label className="mb-1">URL de Linkedin</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  id="url_linkedin"
                  name="url_linkedin"
                  placeholder="URL de linkedin"
                  isInvalid={!!errors.url_linkedin}
                  {...register('url_linkedin', {
                    minLength: {
                      limit: 1,
                      value: 1,
                      message: 'Mínimo 1 carácter!'
                    },
                    pattern: {
                      value:
                        '^(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})([/\\w .-]*)*/?$',
                      message: 'URL Inválida!'
                    }
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.url_linkedin && errors.url_linkedin.message}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div id="url_instagram-row" className="mb-1">
              <Form.Group>
                <Form.Label className="mb-1">URL de Instagram</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  id="url_instagram"
                  name="url_instagram"
                  placeholder="URL de instagram"
                  isInvalid={!!errors.url_instagram}
                  {...register('url_instagram', {
                    minLength: {
                      limit: 1,
                      value: 1,
                      message: 'Mínimo 1 carácter!'
                    },
                    pattern: {
                      value:
                        '^(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})([/\\w .-]*)*/?$',
                      message: 'URL Inválida!'
                    }
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.url_instagram && errors.url_instagram.message}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div id="url_video-row" className="mb-1">
              <Form.Group>
                <Form.Label className="mb-1">URL del Video</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  id="url_video"
                  name="url_video"
                  placeholder="URL del Video"
                  isInvalid={!!errors.url_video}
                  {...register('url_video', {
                    minLength: {
                      limit: 1,
                      value: 1,
                      message: 'Mínimo 1 carácter!'
                    },
                    pattern: {
                      value:
                        '^(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})([/\\w .-]*)*/?$',
                      message: 'URL Inválida!'
                    }
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.url_video && errors.url_video.message}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <Form.Group>
              <Form.Label className="mb-1">Logo</Form.Label>
              <Form.Control
                size="sm"
                type="file"
                id="logo"
                name="logo"
                onChange={e => {
                  verifyImage(e.target.files[0]);
                }}
              />
              {image !== null && (
                <h6 key="errorMessage" className="text-danger fs--2 mt-2 mb-0">
                  {image}
                </h6>
              )}
            </Form.Group>
            {imageUrl && image === null && (
              <div
                style={{
                  width: '150px',
                  height: '150px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 'auto',
                  marginTop: '10px'
                }}
              >
                <img
                  src={imageUrl}
                  alt="Preview"
                  style={{
                    objectFit: 'cover',
                    maxWidth: '100%',
                    maxHeight: '100%'
                  }}
                />
              </div>
            )}
            <div id="background_image-row" className="mb-3">
              <Form.Group>
                <Form.Label className="mb-1">Imagen de Fondo</Form.Label>
                <Form.Control
                  size="sm"
                  type="file"
                  id="background_image"
                  name="background_image"
                  onChange={e => {
                    verifyImageBackground(e.target.files[0]);
                  }}
                />
                {availabledImage !== null && (
                  <h6 className="text-danger fs--2 mt-2 mb-0">
                    {availabledImage}
                  </h6>
                )}
              </Form.Group>
            </div>
            {backgroundImageUrl && availabledImage === null && (
              <div
                style={{
                  width: '150px',
                  height: '150px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 'auto'
                }}
              >
                <img
                  src={backgroundImageUrl}
                  alt="Preview"
                  style={{
                    objectFit: 'cover',
                    maxWidth: '100%',
                    maxHeight: '100%'
                  }}
                />
              </div>
            )}
          </div>

          <Buttons
            btn={'create'}
            caption={watch('id') ? 'Actualizar Datos' : 'Guardar Datos'}
          />
        </Form>
      </Col>
    </Row>
  );
};

MarksCoursesForm.propTypes = {
  submit: PropTypes.func.isRequired,
  item: PropTypes.object,
  items: PropTypes.array
};

export default MarksCoursesForm;
