import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
// import { Link } from 'react-router-dom';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';

const columns = [
  {
    accessor: 'project',
    Header: 'Ciudad',
    headerProps: {
      className: 'text-900'
    }
    // Cell: rowData => (
    //   <Link to="#!" className="text-primary fw-semibold">
    //     {rowData.row.original.path}
    //   </Link>
    // )
  },
  {
    accessor: 'accredited',
    Header: 'Acreditadas',
    headerProps: {
      className: 'text-end text-900'
    },
    cellProps: {
      className: 'text-end'
    }
  },
  {
    accessor: 'noAccredited',
    Header: 'No acreditadas',
    headerProps: {
      className: 'text-end text-900'
    },
    cellProps: {
      className: 'text-end'
    }
  }
];

const TopPages = ({ tableData, perPage = 8, title }) => {
  return (
    <AdvanceTableWrapper
      columns={columns}
      data={tableData}
      sortable
      pagination
      perPage={perPage}
    >
      <Card className="h-100">
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs="auto" sm={6} lg={7}>
              <h6 className="mb-0 text-nowrap py-2 py-xl-0">{title}</h6>
            </Col>
            <Col xs="auto" sm={6} lg={5}>
              <AdvanceTableSearchBox table placeholder="Search..." />
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              className: 'fs-10 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTableFooter
            rowCount={tableData.length}
            table
            rowInfo
            navButtons
          />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

TopPages.propTypes = {
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      project: PropTypes.string,
      noAccredited: PropTypes.number.isRequired,
      accredited: PropTypes.number.isRequired
      // time: PropTypes.string.isRequired,
      // exitRate: PropTypes.string.isRequired
    })
  ).isRequired,
  perPage: PropTypes.number,
  title: PropTypes.string
};

export default TopPages;
