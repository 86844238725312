import React, { useEffect } from 'react';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useDefault } from 'hooks';
import Buttons from 'components/templates/Buttons';

import { EModal, DModal, CModal } from 'components/templates/Modal';
// import Datatable from 'components/templates/datatable/Datatable';
import { Button, Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';

const Gallery = ({ authorized, route, useHook }) => {
  if (!authorized) return <Navigate to="/dashboard" />;

  const navigate = useNavigate();

  const { emodal, handleEModal, dmodal, handleDModal, cmodal, handleCModal } =
    useDefault();
  const {
    setItem,
    item,
    items,
    submit,
    removeOne,
    changeStatus,
    name,
    routes,
    nameBootCamp,
    nameMentoring,
    Form_,
    caption,
    modalSize,
    confirm
  } = useHook();
  useEffect(() => {
    if (items === undefined) navigate(`/cms/${routes}`, { replace: true });
  }, [navigate, route, items]);
  useEffect(() => {
    if (name === null) navigate(`/cms/${routes}`, { replace: true });
  }, [navigate, route, name, items, nameBootCamp, nameMentoring]);

  //* FOR CLEAR FORM ON-CLOSE MODAL'S
  useEffect(() => {
    !emodal && setItem({});
  }, [emodal]);

  useEffect(() => {
    !dmodal && setItem({});
  }, [dmodal]);

  useEffect(() => {
    !cmodal && setItem({});
  }, [cmodal]);
  const itemList = items ?? [];
  return (
    <>
      <Link to={`/cms/${routes}`}>
        <Buttons btn={'return'} />
      </Link>
      {Form_ && (
        <Buttons btn={'add'} caption={caption} handleEvent={handleEModal} />
      )}
      {/* {items !== undefined && ( */}
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {itemList.map((image, index) => (
          <Card key={index} style={{ width: '20rem', margin: '5px' }}>
            <Card.Img
              src={`${process.env.REACT_APP_BACKEND_URL}/storage/gallery/${image.image}`}
              variant="top"
            />
            <Card.Body
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Flex justifyContent={'end'}>
                <Button
                  size="sm"
                  variant="falcon-default" //blanco
                  className="me-2 rounded-pill me-1 mb-1"
                  onClick={() => {
                    setItem(image);
                    handleEModal();
                  }}
                >
                  Editar
                </Button>

                <Button
                  size="sm"
                  variant="danger"
                  className="me-2 rounded-pill me-1 mb-1"
                  onClick={() => {
                    setItem(image);
                    handleDModal();
                  }}
                >
                  Eliminar
                </Button>
              </Flex>
            </Card.Body>
          </Card>
        ))}
      </div>
      {/* )} */}
      {Form_ && (
        <EModal
          form_={<Form_ submit={submit} item={item} items={items} />}
          title={caption}
          modal={emodal}
          modalSize={modalSize}
          handleModal={handleEModal}
        />
      )}
      <DModal
        item={item}
        modal={dmodal}
        handleModal={handleDModal}
        handleClick={removeOne}
      />
      {confirm && (
        <CModal
          item={item}
          modal={cmodal}
          handleModal={handleCModal}
          handleClick={changeStatus}
        />
      )}
    </>
  );
};

Gallery.propTypes = {
  route: PropTypes.string,
  authorized: PropTypes.bool,
  useHook: PropTypes.func.isRequired
};

export default Gallery;
