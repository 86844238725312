import AdvanceTableWrapper from 'components/templates/datatable/advance-table/AdvanceTableWrapper';
import React from 'react';
import { Card } from 'react-bootstrap';
import AdvanceTable from 'components/templates/datatable/advance-table/AdvanceTable';
import AllTicketsHeader from './Header';
import PropTypes from 'prop-types';
import AdvanceTableFooter from 'components/templates/datatable/advance-table/AdvanceTableFooter';

const DataTable_ = ({
  title,
  data,
  columns,
  searchQuery,
  searchQueryDate,
  handleModal
}) => {
  return (
    <AdvanceTableWrapper
      columns={columns}
      data={data}
      sortable
      pagination
      perPage={10}
      rowCount={data.length}
    >
      <Card>
        <Card.Header className="border-bottom border-200 px-0">
          <AllTicketsHeader
            table
            layout="table-view"
            title={title}
            searchQuery={searchQuery}
            searchQueryDate={searchQueryDate}
            handleModal={handleModal}
          />
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-light text-800 align-middle"
            rowClassName="btn-reveal-trigger align-middle"
            tableProps={{
              size: 'sm',
              className: 'fs--1 mb-0 overflow-hidden',
              striped: true,
              hover: true
            }}
          />
        </Card.Body>
        <Card.Footer>
          {/* <AdvanceTablePagination table /> */}
          <AdvanceTableFooter
            rowCount={data.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
          />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

DataTable_.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  searchQuery: PropTypes.func.isRequired,
  searchQueryDate: PropTypes.func.isRequired,
  handleModal: PropTypes.func.isRequired
};

export default DataTable_;
