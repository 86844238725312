import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import AllTicketsHeader from './advance-table/Header';
import AdvanceTable from './advance-table/AdvanceTable';
import AdvanceTableWrapper from './advance-table/AdvanceTableWrapper';
import AdvanceTableFooter from './advance-table/AdvanceTableFooter';

const DataTable_ = ({ title, data, columns }) => {
  return (
    <AdvanceTableWrapper
      columns={columns}
      data={data}
      sortable
      pagination
      perPage={10}
      rowCount={data.length}
    >
      <Card>
        <Card.Header className="border-bottom border-200 px-0">
          <AllTicketsHeader table layout="table-view" title={title} />
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-light text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              className: 'fs--1 mb-0 overflow-hidden',
              striped: true,
              hover: true
            }}
          />
        </Card.Body>
        <Card.Footer>
          {/* <AdvanceTablePagination table /> */}
          <AdvanceTableFooter
            rowCount={data.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
          />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

DataTable_.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired
};

export default DataTable_;
