import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useAuth } from 'hooks';
import validator from 'validator';
import { getParameterByName } from 'helpers/utils';

const PasswordResetForm = ({ hasLabel }) => {
  const { resetPassword } = useAuth();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm({ defaultValues: { id: null } });

  // Handler
  const onSubmit = data => {
    data.id = getParameterByName('id');
    data.token = getParameterByName('res');
    delete data.password2;

    resetPassword(data);
    reset();
  };

  const [errorStrongMsg, setErrorStrongMsg] = useState('');
  const [strong, setStrong] = useState(false);

  useEffect(() => {
    if (watch('password')) {
      if (
        validator.isStrongPassword(watch('password'), {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1
        })
      ) {
        setErrorStrongMsg('tu contraseña es fuerte');
        setStrong(true);
      } else {
        setErrorStrongMsg('tu contraseña es débil');
        setStrong(false);
      }
    } else setErrorStrongMsg('');
  }, [watch('password'), watch('password2')]);

  return (
    <Form
      className={classNames('mt-3', { 'text-left': hasLabel })}
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <div id="password-row">
        <Form.Group>
          <Form.Control
            size="sm"
            type="password"
            name="password"
            id="password"
            placeholder="Contraseña"
            isInvalid={!!errors.password}
            {...register('password', {
              required: {
                value: true,
                message: 'este campo es requerido!'
              },
              minLength: {
                value: 8,
                message: 'min. caracteres: 8!'
              }
            })}
          />

          {errors.password && !errorStrongMsg && (
            <Form.Control.Feedback type="invalid">
              {errors.password.message}
            </Form.Control.Feedback>
          )}
          {errorStrongMsg === '' ? (
            !errors.password && (
              <h6 className="text-500 fs--2 mt-2 mb-0">
                Requerido (caracteres: 8, mayúscula: 1, minúsculas: 1, número:
                1, símbolo: 1)
              </h6>
            )
          ) : (
            <h6
              className={`${
                strong ? 'text-primary' : 'text-danger'
              } fs--2 mt-2 mb-0`}
            >
              {errorStrongMsg}
            </h6>
          )}
        </Form.Group>
      </div>
      <br />
      <div id="password2-row">
        <Form.Group className="mb-3">
          <Form.Control
            size="sm"
            type="password"
            placeholder="Confirmar contraseña"
            id="password2"
            name="password2"
            isInvalid={!!errors.password2}
            {...register('password2', {
              required: {
                value: true,
                message: 'este campo es requerido!'
              },
              minLength: {
                value: 8,
                message: 'min. caracteres: 8!'
              },
              validate: value =>
                value === watch('password') || 'las contraseñas no coinciden!'
            })}
          />

          <Form.Control.Feedback type="invalid">
            {errors.password2 && errors.password2.message}
          </Form.Control.Feedback>
        </Form.Group>
      </div>

      <Button className="w-100" size="sm" type="submit">
        Cambiar contraseña
      </Button>
    </Form>
  );
};

PasswordResetForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default PasswordResetForm;
