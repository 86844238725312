import { useAuth } from 'hooks';
import React from 'react';
import { Outlet } from 'react-router-dom';

const AuthLayout = () => {
  const { auth = {} } = useAuth();
  if (!auth?._id) localStorage.removeItem('token');

  return (
    <>
      <main>
        <div>
          <Outlet />
        </div>
      </main>
    </>
  );
};

export default AuthLayout;
