import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import PerfomanceTable from './PerfomanceTable';
import PropTypes from 'prop-types';
import FalconCardFooterLink from 'components/common/FalconCardFooterLink';

const CampaignPerfomance = ({
  title,
  data,
  data2,
  setColumns,
  setallStudents,
  handleEModal,
  setTitle
}) => {
  let sum = 0;
  data.forEach(element => {
    sum += Number(element.team.replace(/\./g, ''));
  });
  let prom = 0;
  if (data.length > 0) prom = sum / data.length;
  return (
    <Card className="h-100">
      <FalconCardHeader
        title={title}
        titleTag="h6"
        className="py-2"
        light
        // endEl={<CardDropdown />}
      />
      <Card.Body className="pb-0">
        <Row>
          <Col xs={6}>
            <h6 className="text-700">Precio promedio total</h6>
            <h3 className="fw-normal text-700">
              ${prom.toLocaleString().split(',')[0]}
            </h3>
            {/* <CampaignChart
              color={getColor('warning')}
              data={campaignChart.revenue}
            /> */}
          </Col>
          {/* <Col xs={6}>
            <h6 className="text-700">Clicks</h6>
            <h3 className="fw-normal text-700">3.8k</h3>
            <CampaignChart
              color={getColor('primary')}
              data={campaignChart.clicks}
            />
          </Col> */}
        </Row>
        <div className="mx-ncard">
          <PerfomanceTable data={data} />
        </div>
      </Card.Body>

      <Card.Footer
        className={`${
          localStorage.getItem('isDark') === 'true'
            ? 'py-2 '
            : 'bg-body-tertiary'
        }`}
      >
        <Row className="g-0 flex-between-center">
          {/* <Col xs="auto">
            <Form.Select size="sm" className="me-2">
              <option>Last 7 days</option>
              <option>Last Month</option>
              <option>Last Year</option>
            </Form.Select>
          </Col> */}
          <Col xs="auto" style={{ backgroundColor: 'transparent' }}>
            <FalconCardFooterLink
              title="Ver más...."
              className="px-0 fw-medium"
              onClick={() => {
                setTitle(title);
                setColumns([
                  {
                    accessor: 'label',
                    Header: 'Nombre'
                  },
                  {
                    accessor: 'team',
                    Header: 'Precio Promedio',
                    Cell: rowData => {
                      const { team } = rowData.row.original;
                      return (
                        <div className="d-flex align-items-center">
                          <div className="ms-2">${team}</div>
                        </div>
                      );
                    }
                  }
                ]);
                setallStudents(data2);
                handleEModal();
              }}
            />
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};
CampaignPerfomance.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  data2: PropTypes.array,
  setColumns: PropTypes.func,
  setallStudents: PropTypes.func,
  handleEModal: PropTypes.func,
  setTitle: PropTypes.func
};

export default CampaignPerfomance;
