import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import AllTicketsHeader from './advance-table/Header';
import AdvanceTable from './advance-table/AdvanceTable';
import AdvanceTableWrapper from './advance-table/AdvanceTableWrapper';
import AdvanceTableFooter from './advance-table/AdvanceTableFooter';

const DataTable_ = ({ title, data, columns }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = data.slice(indexOfFirstRow, indexOfLastRow);

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
  };

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={currentRows}
      sortable
      pagination
      perPage={rowsPerPage}
      rowCount={data.length}
      onPageChange={handlePageChange}
    >
      <Card>
        <Card.Header className="border-bottom border-200 px-0">
          <AllTicketsHeader table layout="table-view" title={title} />
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-light text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              className: 'fs--1 mb-0 overflow-hidden',
              striped: true,
              hover: true
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTableFooter
            rowCount={data.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
          />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

DataTable_.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired
};

export default DataTable_;
