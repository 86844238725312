import React, { useContext, useState, useEffect } from 'react';
import { Button, Col, Form, Row, Spinner, Tab, Tabs } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import PropTypes from 'prop-types';
import { useBankRange, useDefault } from 'hooks';

import { toast } from 'react-toastify';

import AppContext from 'context/Context';
import { Editor } from '@tinymce/tinymce-react';
import { getColor } from 'helpers/utils';

const BankForm = ({ submit, item, items, modal }) => {
  const {
    config: { isDark, isRTL }
  } = useContext(AppContext);

  const { loadBotton } = useDefault();
  const [availabledMsg, setAvailabledMsg] = useState('');
  const [availabled, setAvailabled] = useState(true);
  const { items: ranges } = useBankRange();
  const [image, setImage] = useState(null);
  const [logo, setLogo] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [availabledImage, setAvailabledImage] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState();
  const [backgroundImageUrl, setBackgroundImageUrl] = useState();
  const [availabledPDF, setAvailabledPDF] = useState(null);
  const [pdf, setPDF] = useState();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
    clearErrors
  } = useForm({ defaultValues: { id: null } });

  let pt = false;
  useEffect(() => {
    if (modal && !pt) {
      pt = true;
      toast.warning(
        'La opcíón de Guardar no estará habilitada hasta tanto los campos señalados como obligatorio (*) estén llenos.'
      );
    }
  }, [modal]);

  //*EVENT CLICK SAVE AND UPDATE
  const onSubmit = data => {
    const formData = new FormData();
    if (item._id) {
      formData.append('_id', item._id);
    }
    if (availabled && !image && !availabledImage && !availabledPDF) {
      formData.append('logo', logo);
      formData.append('background_image', backgroundImage);
      formData.append('image_brochure', pdf);
      formData.append('name', data.name);
      formData.append('range', data.range);
      formData.append('about', data.about);
      formData.append('financing', data.financing);
      formData.append('min_amount', data.min_amount);
      formData.append('rate_nmv', data.rate_nmv);
      formData.append('rate_ea', data.rate_ea);
      formData.append('video_about', data.video_about);
      formData.append(
        'url_facebook',
        data.url_facebook !== null ? data.url_facebook : ''
      );
      formData.append(
        'url_linkedin',
        data.url_linkedin !== null ? data.url_linkedin : ''
      );
      formData.append(
        'url_instagram',
        data.url_instagram !== null ? data.url_instagram : ''
      );
      submit(formData);
      setImage(null);
      setAvailabledImage(null);
      setAvailabledPDF(null);
      setPDF(null);
      saveImage(`${process.env.REACT_APP_BACKEND_URL}/storage/estandar.png`);
      saveImageBackground(
        `${process.env.REACT_APP_BACKEND_URL}/storage/fondo_test.jpg`
      );
      // setNameBrochure(null);
      reset();
    }
  };

  //* EVENT GET ALL RGISTER
  useEffect(() => {
    try {
      let logoUrl;
      let backgroundURL;
      let brochureURL;
      if (item._id) {
        setValue('id', item._id);
        setValue('name', item.name);
        setValue('range', item.range?._id);
        setValue('about', item.about);
        setValue('financing', item.financing);
        setValue('min_amount', item.min_amount);
        // setValue('video_about', item.video_about);
        setValue('rate_nmv', item.rate_nmv);
        setValue('rate_ea', item.rate_ea);
        setValue('name_brochure', item.name_brochure);
        setValue('url_facebook', item.url_facebook);
        setValue('url_linkedin', item.url_linkedin);
        setValue('url_instagram', item.url_instagram);
        logoUrl = `${process.env.REACT_APP_BACKEND_URL}/storage/logobancos/${item.logo}`;
        backgroundURL = `${process.env.REACT_APP_BACKEND_URL}/storage/backgrounds/${item.background_image}`;
        if (item.image_brochure) {
          brochureURL = `${process.env.REACT_APP_BACKEND_URL}/storage/backgrounds/${item.image_brochure}`;
          savePDF(brochureURL);
        }
      } else {
        logoUrl = `${process.env.REACT_APP_BACKEND_URL}/storage/estandar.png`;
        backgroundURL = `${process.env.REACT_APP_BACKEND_URL}/storage/fondo_test.jpg`;
      }
      saveImage(logoUrl);
      saveImageBackground(backgroundURL);
    } catch (error) {
      console.error('Error al obtener el archivo:', error);
    }
  }, [watch('id')]);

  // useEffect(() => {}, [watch('image_brochure')]);
  const savePDF = async pdfUrl => {
    const response = await fetch(pdfUrl);
    const blob = await response.blob();
    const file = new File([blob], 'document.pdf', { type: 'application/pdf' });
    setPDF(file);
    setValue('pdf', [file]);
  };

  const saveImage = async logoUrl => {
    setImageUrl(logoUrl);
    const response = await fetch(logoUrl);
    const blob = await response.blob();
    const file = new File([blob], 'logo.jpg', { type: 'image/jpeg' });
    setLogo(file);
  };

  const saveImageBackground = async logoUrl => {
    setBackgroundImageUrl(logoUrl);
    const response = await fetch(logoUrl);
    const blob = await response.blob();
    const file = new File([blob], 'fondo.jpg', { type: 'image/jpeg' });
    setBackgroundImage(file);
  };

  const verifyImage = async logo => {
    setImage(null);
    const file = logo;
    if (file) {
      setImageUrl(URL.createObjectURL(file));
      if (file.type && file.type.startsWith('image/')) {
        try {
          if (file.name !== backgroundImage.name) {
            if (file.size < 21000000) {
              setLogo(file);
            } else {
              setImage('El tamaño del fichero excede los 2mb!');
            }
          } else {
            setImage('El nombre de las imágenes no pueden ser iguales!');
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        setImage('Este fichero no es una imagen!');
      }
    }
  };

  const verifyImageBackground = async image => {
    setAvailabledImage(null);
    const file = image;
    if (file) {
      setBackgroundImageUrl(URL.createObjectURL(file));
      if (file.type && file.type.startsWith('image/')) {
        try {
          if (file.name !== logo.name) {
            if (file.size < 21000000) {
              setBackgroundImage(file);
            } else {
              setAvailabledImage('El tamaño del fichero excede los 2mb!');
            }
          } else {
            setAvailabledImage(
              'El nombre de las imágenes no pueden ser iguales!'
            );
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        setAvailabledImage('Este fichero no es una imagen!');
      }
    }
  };

  const verifyPDF = async logo => {
    setAvailabledPDF(null);
    const file = logo;
    if (file) {
      if (file.type && file.type.startsWith('application/pdf')) {
        try {
          setPDF(file);
        } catch (error) {
          console.error(error);
        }
      } else {
        setAvailabledPDF('Este fichero no es un pdf!');
      }
    }
  };

  useEffect(() => {
    if (
      !item?._id ||
      (item?.range._id !== watch('range') && item?.name !== watch('name'))
    ) {
      setAvailabled(true);
      setAvailabledMsg('');
      items.forEach(dat => {
        if (dat?.range?._id === watch('range') && dat.name === watch('name')) {
          setAvailabled(false);
          setAvailabledMsg('Este rango está en uso actualmente!');
        }
      });
    }
  }, [watch('range'), watch('name')]);

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)} className="px-1">
      <Row>
        <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
          <Tab eventKey="home" title="Información general">
            <Row>
              <Col>
                <div className="w-100">
                  <div id="name-row" className="mb-3">
                    <Form.Group>
                      <Form.Label className="mb-1">Nombre (*)</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        id="name"
                        name="name"
                        placeholder="BBVA"
                        isInvalid={!!errors.range}
                        {...register('name', {
                          required: {
                            value: true,
                            message: 'este campo es requerido!'
                          },
                          minLength: {
                            limit: 1,
                            value: 1,
                            message: 'Mínimo 1 carácter!'
                          }
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.name && errors.name.message}
                      </Form.Control.Feedback>
                      {availabled ? null : (
                        <h6 className="text-danger fs--2 mt-2 mb-0">
                          {availabledMsg}
                        </h6>
                      )}
                    </Form.Group>
                  </div>
                  <div id="range-row">
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-1 mt-2">Plazo (*)</Form.Label>
                      <Form.Select
                        size="sm"
                        id="range"
                        isInvalid={!!errors.range}
                        {...register('range', { required: false })}
                      >
                        <option value="">--Seleccionar--</option>
                        {ranges.map(dat => (
                          <option value={dat._id} key={dat._id}>
                            {dat.range}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div id="financing-row" className="mb-3">
                    <Form.Group>
                      <Form.Label className="mb-1">Financiación (*)</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        id="financing"
                        name="financing"
                        placeholder="Hasta 100%"
                        isInvalid={!!errors.financing}
                        {...register('financing', {
                          required: {
                            value: true,
                            message: 'este campo es requerido!'
                          },
                          minLength: {
                            limit: 1,
                            value: 1,
                            message: 'Mínimo 1 carácter!'
                          }
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.financing && errors.financing.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="w-100">
                  <div id="min_amount-row" className="mb-3">
                    <Form.Group>
                      <Form.Label className="mb-1">Monto Mínimo (*)</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        id="min_amount"
                        name="min_amount"
                        placeholder="1000000"
                        isInvalid={!!errors.min_amount}
                        {...register('min_amount', {
                          required: {
                            value: true,
                            message: 'este campo es requerido!'
                          },
                          minLength: {
                            limit: 1,
                            value: 1,
                            message: 'Mínimo 1 carácter!'
                          }
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.min_amount && errors.min_amount.message}
                      </Form.Control.Feedback>
                      <div
                        className={`${
                          localStorage.getItem('isDark') === 'true'
                            ? 'text-400'
                            : 'text-700'
                        }`}
                        style={{
                          marginTop: '5px',
                          fontSize: '12px'
                        }}
                      >
                        Con signo $ y con puntos
                      </div>
                    </Form.Group>
                  </div>
                  <div id="rate_nmv-row" className="mb-3">
                    <Form.Group>
                      <Form.Label className="mb-1">Taza NMV (*)</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        id="rate_nmv"
                        name="rate_nmv"
                        placeholder="0,85% M.V."
                        isInvalid={!!errors.rate_nmv}
                        {...register('rate_nmv', {
                          required: {
                            value: true,
                            message: 'este campo es requerido!'
                          },
                          minLength: {
                            limit: 1,
                            value: 1,
                            message: 'Mínimo 1 carácter!'
                          }
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.rate_nmv && errors.rate_nmv.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div id="rate_ea-row" className="mb-3">
                    <Form.Group>
                      <Form.Label className="mb-1">Taza EA (*)</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        id="rate_ea"
                        name="rate_ea"
                        placeholder="Desde 10,69% E.A."
                        isInvalid={!!errors.rate_ea}
                        {...register('rate_ea', {
                          required: {
                            value: true,
                            message: 'este campo es requerido!'
                          },
                          minLength: {
                            limit: 1,
                            value: 1,
                            message: 'Mínimo 1 carácter!'
                          }
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.rate_ea && errors.rate_ea.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
              </Col>
              <div id="about-row" className="mb-3">
                <Form.Group>
                  <Form.Label className="mb-1">Sobre el Crédito (*)</Form.Label>
                  <div style={{ border: errors.about ? '2px solid red' : '' }}>
                    <Editor
                      id="about"
                      name="about"
                      value={watch('about')}
                      onEditorChange={newValue => {
                        setValue('about', newValue);
                        if (errors.about) clearErrors('about');
                      }}
                      apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                      init={{
                        height: '200px',
                        menubar: false,
                        content_style: `
                        .mce-content-body {
                          color: ${
                            isDark ? 'white' : getColor('emphasis-color')
                          };                          
                          background-color: ${getColor('tinymce-bg')};
                        }`,
                        statusbar: false,
                        plugins: 'lists directionality',
                        toolbar:
                          'styleselect | bold italic link bullist numlist image table media undo redo',

                        directionality: isRTL ? 'rtl' : 'ltr',
                        theme_advanced_toolbar_align: 'center'
                      }}
                      {...register('about', {
                        required: {
                          value: true,
                          message: 'este campo es requerido!'
                        }
                      })}
                    />
                  </div>
                  {errors.about && (
                    <div style={{ color: 'red' }}>{errors.about.message}</div>
                  )}
                  {/* <Form.Control
                    size="sm"
                    as="textarea"
                    id="about"
                    name="about"
                    placeholder="Lorem ipsum"
                    isInvalid={!!errors.about}
                    {...register('about', {
                      required: {
                        value: true,
                        message: 'este campo es requerido!'
                      },
                      minLength: {
                        limit: 2,
                        value: 2,
                        message: 'Mínimo 1 carácter!'
                      }
                    })}
                  /> 
                  <Form.Control.Feedback type="invalid">
                    {errors.about && errors.about.message}
                  </Form.Control.Feedback>*/}
                </Form.Group>
              </div>
            </Row>
          </Tab>
          <Tab eventKey="imagen" title="Marca">
            <Row>
              <Col>
                <div className="w-100">
                  <Form.Group>
                    <Form.Label
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: 'auto',
                        marginBottom: '10px',
                        color: '#000'
                      }}
                    >
                      {' '}
                      Logo Cliente
                    </Form.Label>
                    {imageUrl && image === null && (
                      <div
                        style={{
                          width: '150px',
                          height: '150px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          margin: 'auto'
                        }}
                      >
                        <img
                          src={imageUrl}
                          alt="Preview"
                          style={{
                            objectFit: 'cover',
                            maxWidth: '100%',
                            maxHeight: '100%'
                          }}
                        />
                      </div>
                    )}
                  </Form.Group>
                  <div id="logo-row" className="mb-3">
                    <Form.Group>
                      <Form.Label className="mb-1">Logo Cliente</Form.Label>
                      <Form.Control
                        size="sm"
                        type="file"
                        id="logo"
                        name="logo"
                        onChange={e => {
                          verifyImage(e.target.files[0]);
                        }}
                      />
                      {image !== null && (
                        <h6 className="text-danger fs--2 mt-2 mb-0">{image}</h6>
                      )}
                    </Form.Group>
                  </div>
                  <div id="url_facebook-row" className="mb-3">
                    <Form.Group>
                      <Form.Label className="mb-1">URL de Facebook</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        id="url_facebook"
                        name="url_facebook"
                        placeholder="URL de Facebook"
                        isInvalid={!!errors.url_facebook}
                        {...register('url_facebook', {
                          minLength: {
                            limit: 1,
                            value: 1,
                            message: 'Mínimo 1 carácter!'
                          },
                          pattern: {
                            value:
                              '^(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})([/\\w .-]*)*/?$',
                            message: 'URL Inválida!'
                          }
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.url_facebook && errors.url_facebook.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div id="url_instagram-row" className="mb-3">
                    <Form.Group>
                      <Form.Label className="mb-1">URL de Instagram</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        id="url_instagram"
                        name="url_instagram"
                        placeholder="URL de instagram"
                        isInvalid={!!errors.url_instagram}
                        {...register('url_instagram', {
                          minLength: {
                            limit: 1,
                            value: 1,
                            message: 'Mínimo 1 carácter!'
                          },
                          pattern: {
                            value: '^(https?://)?(www.)?facebook.com/.*$',
                            message: 'URL Inválida!'
                          }
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.url_instagram && errors.url_instagram.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="w-100">
                  <Form.Group>
                    <Form.Label
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: 'auto',
                        marginBottom: '5px',
                        marginTop: '15px',
                        color: '#000'
                      }}
                    >
                      {' '}
                      Imagen de Fondo
                    </Form.Label>
                    {backgroundImageUrl && availabledImage === null && (
                      <div
                        style={{
                          width: '150px',
                          height: '150px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          margin: 'auto'
                        }}
                      >
                        <img
                          src={backgroundImageUrl}
                          alt="Preview"
                          style={{
                            objectFit: 'cover',
                            maxWidth: '100%',
                            maxHeight: '100%'
                          }}
                        />
                      </div>
                    )}
                  </Form.Group>
                  <div id="background_image-row" className="mb-3">
                    <Form.Group>
                      <Form.Label className="mb-1">Imagen de Fondo</Form.Label>
                      <Form.Control
                        size="sm"
                        type="file"
                        id="background_image"
                        name="background_image"
                        onChange={e => {
                          verifyImageBackground(e.target.files[0]);
                        }}
                      />
                      {availabledImage !== null && (
                        <h6 className="text-danger fs--2 mt-2 mb-0">
                          {availabledImage}
                        </h6>
                      )}
                    </Form.Group>
                  </div>
                  <div id="image_brochure-row" className="mb-1">
                    <Form.Group>
                      <Form.Label className="mb-1">Brochure</Form.Label>
                      <Form.Control
                        size="sm"
                        type="file"
                        id="image_brochure"
                        name="image_brochure"
                        onChange={e => {
                          verifyPDF(e.target.files[0]);
                        }}
                      />
                      {availabledPDF !== null && (
                        <h6 className="text-danger fs--2 mt-2 mb-0">
                          {availabledPDF}
                        </h6>
                      )}
                    </Form.Group>
                  </div>
                  <div id="url_linkedin-row" className="mb-3">
                    <Form.Group>
                      <Form.Label className="mb-1">URL de Linkedin</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        id="url_linkedin"
                        name="url_linkedin"
                        placeholder="URL de linkedin"
                        isInvalid={!!errors.url_linkedin}
                        {...register('url_linkedin', {
                          minLength: {
                            limit: 1,
                            value: 1,
                            message: 'Mínimo 1 carácter!'
                          },
                          pattern: {
                            value:
                              '^(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})([/\\w .-]*)*/?$',
                            message: 'URL Inválida!'
                          }
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.url_linkedin && errors.url_linkedin.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
              </Col>
            </Row>
          </Tab>
        </Tabs>
        <Button
          type="submit"
          size="sm"
          variant="primary"
          disabled={
            loadBotton ||
            Object.keys(errors).length > 0 ||
            availabledPDF ||
            !availabled ||
            image ||
            availabledImage ||
            !watch('name') ||
            !watch('range') ||
            !watch('about') ||
            !watch('financing') ||
            !watch('min_amount') ||
            !watch('rate_nmv') ||
            !watch('rate_ea')
          }
          className="rounded-pill me-1 mb-3 w-100 d-inline-flex flex-center gap-1 p-1"
        >
          {loadBotton && (
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          {loadBotton
            ? 'Cargando…'
            : watch('id')
            ? 'Actualizar Datos'
            : 'Guardar Datos'}
        </Button>
      </Row>
    </Form>
  );
};

BankForm.propTypes = {
  submit: PropTypes.func.isRequired,
  item: PropTypes.object,
  items: PropTypes.array,
  modal: PropTypes.bool
};

export default BankForm;
