import React from 'react';
import PropTypes from 'prop-types';

import Datatable from 'components/templates/datatable/Datatable';

const TableModal = ({ items, title, columns }) => {
  return items == null || items[0] == null ? (
    'No hay Datos que Mostrar.'
  ) : (
    <div style={{ marginBottom: '20px' }}>
      <Datatable title={title} data={items} columns={columns} />
    </div>
  );
};

TableModal.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array,
  columns: PropTypes.array
};

export default TableModal;
