import React from 'react';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

const Tabs = ({ tabs }) => {
  return (
    <div>
      <ul className="nav nav-pills mb-2 fs--1" id="pills-tab" role="tablist">
        {tabs?.map(({ _id, category }, i) => {
          return (
            <li className="nav-item" role="presentation" key={_id}>
              <button
                className={`${i === 0 && 'active'} nav-link`}
                id={`${_id}-pills-home-tab`}
                data-bs-toggle="pill"
                data-bs-target={`#${_id}-pills-home`}
                type="button"
                role="tab"
                aria-controls={`${_id}-pills-home`}
                aria-selected="true"
              >
                {category}
              </button>
            </li>
          );
        })}
      </ul>

      <div className="tab-content" id="pills-tabContent">
        {tabs?.map(({ _id, component }, i) => {
          return (
            <div
              key={_id}
              id={`${_id}-pills-home`}
              role="tabpanel"
              aria-labelledby={`${_id}-pills-home-tab`}
              className={`${i === 0 && 'active'} tab-pane fade show`}
              tabIndex={0}
            >
              {component}
            </div>
          );
        })}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object)
};

export default Tabs;
