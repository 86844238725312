import React, { useEffect, useState, createContext } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import headers from 'config/header';

import { useDefault, useUniversity, useUniversityProfile, http } from 'hooks';

import Form_ from 'components/forms/RequirementsForm';

import Buttons from 'components/templates/Buttons';
import Flex from 'components/common/Flex';

const RequirementsContext = createContext();

const RequirementsProvider = ({ children }) => {
  const [items, setItems] = useState([]);

  let routes = '';
  let requirements = null;
  let setRequirements = null;
  let list = null;
  let name = null;
  const {
    item: requirements1,
    setItems: setRequirements1,
    items: list1,
    name: name1
  } = useUniversity();
  const {
    item: requirements2,
    setItems: setRequirements2,
    items: list2,
    name: name2
  } = useUniversityProfile();
  if (requirements1) {
    requirements = requirements1;
    setRequirements = setRequirements1;
    list = list1;
    name = name1;
    routes = 'universities';
  } else {
    if (requirements2) {
      requirements = requirements2;
      setRequirements = setRequirements2;
      list = list2;
      name = name2;
      routes = 'university';
    }
  }

  useEffect(() => {
    if (requirements) {
      setItems(requirements.requirements);
    }
  }, [requirements]);

  const { update, add, remove, handleEModal, handleDModal } = useDefault();

  const [item, setItem] = useState({});

  let columns;

  columns = [
    {
      accessor: 'name',
      Header: 'Nombre',
      Cell: rowData => {
        const { name } = rowData.row.original;
        return (
          <div className="d-flex align-items-center">
            <div className="ms-2 text-truncate" style={{ maxWidth: '400px' }}>
              {name}
            </div>
          </div>
        );
      }
    },
    {
      accessor: 'level',
      Header: 'Nivel'
    },
    {
      accessor: 'btn',
      Header: 'Acciones',
      headerProps: { style: { width: '95px' } },
      Cell: rowData => {
        return (
          <Flex className="gap-1 mt-1.5">
            <Buttons
              btn={'update'}
              handleEvent={() => {
                rowData.row.original.pt = false;
                setItem(rowData.row.original);
                handleEModal();
              }}
            />
            <Buttons
              btn={'delete'}
              handleEvent={() => {
                setItem(rowData.row.original);
                handleDModal();
              }}
            />
          </Flex>
        );
      }
    }
  ];

  const submit = object_ => {
    update(`universities/updateRequirements`, object_).then(res => {
      res && setItems(res.requirements);
      setRequirements(
        list.map(stateObj => (stateObj._id === res._id ? res : stateObj))
      );
      setItem({});
    });
  };

  const removeOne = async () => {
    const deleted = {
      id: item._id,
      requirements: requirements._id
    };
    try {
      let config = headers();

      if (config.headers.token.length > 50) {
        const { data } = await http.put(
          `universities/deleteRequirements`,
          deleted,
          config
        );
        //! Async width .State.
        setItems(data.data.requirements);
        setRequirements(
          list.map(stateObj =>
            stateObj._id === data.data._id ? data.data : stateObj
          )
        );
        toast.success('Registro eliminado correctamente!');
      } else toast.error('Invalid token!');
    } catch (e) {
      console.log(e);
    } finally {
      handleDModal();
      setItem({});
    }
  };

  return (
    <RequirementsContext.Provider
      value={{
        removeOne,
        update,
        add,
        requirements,
        remove,
        name,
        submit,
        item,
        setItem,
        columns,
        routes,
        items,
        Form_,
        caption: 'Requisito',
        captions: 'Requisitos',
        confirm: false
      }}
    >
      {children}
    </RequirementsContext.Provider>
  );
};

RequirementsProvider.propTypes = {
  children: PropTypes.node
};

export { RequirementsProvider };
export default RequirementsContext;
