import React, { useEffect, useState, createContext } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import headers from 'config/header';

import {
  useDefault,
  useCourse,
  // useBootCamps, useMentoring,
  http
} from 'hooks';
import { useLocation } from 'react-router-dom';

import Form_ from 'components/forms/RolesForm';

import Buttons from 'components/templates/Buttons';
import Flex from 'components/common/Flex';

const RolesContext = createContext();

const RolesProvider = ({ children }) => {
  const location = useLocation();
  const splitted = location.pathname.split('-');
  let result = splitted[splitted.length - 1];
  if (result === 'mentoring') {
    result = 'mentoring';
  } else {
    result = result + 's';
  }

  const [items, setItems] = useState([]);
  let object;
  const { item: roles, name } = useCourse();
  // const { item: rolesBootCamp, name: nameBootCamp } = useBootCamps();
  // const { item: rolesMentoring, name: nameMentoring } = useMentoring();
  if (result === 'courses') {
    object = roles;
  }
  // else if (result === 'mentoring') {
  //   object = rolesMentoring;
  // } else if (result === 'bootcamps') {
  //   object = rolesBootCamp;
  // }

  useEffect(() => {
    if (roles) {
      setItems(roles.roles);
    }
  }, [roles]);
  // useEffect(() => {
  //   if (rolesBootCamp) {
  //     setItems(rolesBootCamp.roles);
  //   }
  // }, [rolesBootCamp]);
  // useEffect(() => {
  //   if (rolesMentoring) {
  //     setItems(rolesMentoring.roles);
  //   }
  // }, [rolesMentoring]);

  const { update, add, remove, handleEModal, handleDModal } = useDefault();

  const [item, setItem] = useState({});

  let columns;

  columns = [
    {
      accessor: 'name',
      Header: 'Nombre'
    },
    {
      accessor: `about`,
      Header: 'Sobre',
      Cell: rowData => {
        const { about } = rowData.row.original;
        return (
          <div className="d-flex align-items-center">
            <div className="ms-2 text-truncate" style={{ maxWidth: '400px' }}>
              {about}
            </div>
          </div>
        );
      }
    },
    {
      accessor: 'btn',
      Header: 'Acciones',
      headerProps: { style: { width: '95px' } },
      Cell: rowData => {
        return (
          <Flex className="gap-1 mt-1.5">
            <Buttons
              btn={'update'}
              handleEvent={() => {
                rowData.row.original.pt = false;
                setItem(rowData.row.original);
                handleEModal();
              }}
            />
            <Buttons
              btn={'delete'}
              handleEvent={() => {
                setItem(rowData.row.original);
                handleDModal();
              }}
            />
          </Flex>
        );
      }
    }
  ];

  const submit = object_ => {
    object_.roles = object._id;
    update(`${result}/updateRoles`, object_).then(res => {
      res && setItems(res.roles);
      setItem({});
    });
  };

  const removeOne = async () => {
    const deleted = {
      id: item._id,
      roles: object._id
    };
    try {
      let config = headers();

      if (config.headers.token.length > 50) {
        const { data } = await http.put(
          `${result}/deleteRoles`,
          deleted,
          config
        );
        //! Async width .State.
        setItems(data.data.roles);
        toast.success('Registro eliminado correctamente!');
      } else toast.error('Invalid token!');
    } catch (e) {
      console.log(e);
    } finally {
      handleDModal();
      setItem({});
    }
    // update(`${result}/deleteroles`, deleted).then(() => {
    //   setItems(items.filter(stateObj => stateObj._id !== item._id));
    //   setItem({});
    // });
  };

  return (
    <RolesContext.Provider
      value={{
        removeOne,
        update,
        add,
        remove,
        name,
        // nameBootCamp,
        // nameMentoring,
        submit,
        item,
        setItem,
        columns,
        items,
        Form_,
        confirm: false
      }}
    >
      {children}
    </RolesContext.Provider>
  );
};

RolesProvider.propTypes = {
  children: PropTypes.node
};

export { RolesProvider };
export default RolesContext;
