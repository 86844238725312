import React, { useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import Buttons from '../templates/Buttons';
import PropTypes from 'prop-types';

const OrientationForm = ({ submit, item }) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    // reset,
    formState: { errors }
  } = useForm({ defaultValues: { id: null } });

  //*EVENT CLICK SAVE AND UPDATE
  const onSubmit = data => {
    data._id = data.id;
    submit(data);
    // reset();
  };

  //* EVENT GET ALL RGISTER
  useEffect(() => {
    if (item._id) {
      setValue('id', item._id);
      setValue('name', item.name);
      setValue('questions', item.questions);
    }
  }, [watch('id')]);

  return (
    <Row>
      <Col>
        <Form noValidate onSubmit={handleSubmit(onSubmit)} className="px-1">
          <div id="name-row">
            <Form.Group>
              <Form.Label className="mb-1 mt-2">Factor (*)</Form.Label>
              <Form.Control
                size="sm"
                type="text"
                id="name"
                name="name"
                placeholder="Factor"
                isInvalid={!!errors.name}
                {...register('name', {
                  required: {
                    value: true,
                    message: 'este campo es requerido!'
                  },
                  pattern: {
                    value: /^[A-Za-z .]+$/i,
                    message: 'no se permiten caracteres numéricos!'
                  }
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name && errors.name.message}
              </Form.Control.Feedback>
            </Form.Group>
          </div>

          <div id="questions-row" className="mb-2">
            <Form.Group>
              <Form.Label className="mb-1">Pregunta (*)</Form.Label>
              <Form.Control
                size="sm"
                as="textarea"
                id="questions"
                name="questions"
                placeholder="Descripción de la pregunta"
                isInvalid={!!errors.questions}
                {...register('questions', {
                  required: {
                    value: true,
                    message: 'este campo es requerido!'
                  },
                  maxLength: {
                    limit: 255,
                    value: 255,
                    message: 'maxLength: 255 caracteres!'
                  }
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.questions && errors.questions.message}
              </Form.Control.Feedback>
            </Form.Group>
          </div>

          <Buttons
            btn={'create'}
            caption={watch('id') ? 'Actualizar Datos' : 'Guardar Datos'}
          />
        </Form>
      </Col>
    </Row>
  );
};

OrientationForm.propTypes = {
  submit: PropTypes.func.isRequired,
  item: PropTypes.object
};

export default OrientationForm;
