import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDefault } from 'hooks';
import { EModal } from 'components/templates/Modal';
import { useNavigate } from 'react-router-dom';
import BackDrop from 'components/templates/BackDrop';
import { Button, Card, Col, Row, Tab, Tabs } from 'react-bootstrap';
import Flex from 'components/common/Flex';

const UniversityProfile = ({ authorized, useHook }) => {
  if (!authorized) {
    return <Navigate to="/dashboard" />;
  }
  const navigate = useNavigate();
  const { emodal, handleEModal } = useDefault();

  const {
    setItem,
    item,
    items,
    setName,
    submit,
    setUniversity,
    caption,
    Form_,
    modalSize
  } = useHook();
  if (items === undefined) return <BackDrop param={'los Programas'} />;
  //* FOR CLEAR FORM ON-CLOSE MODAL'S
  useEffect(() => {
    !emodal && setItem({});
  }, [emodal]);

  const handleClick = url => {
    setItem(items[0]);
    setName(items[0].name);
    setUniversity(null);
    navigate(`/cms/university/${url}`, { replace: true });
  };
  return (
    <>
      <Card className="p-0 mb-3">
        {/* {!items[0].background_image ? (
          <div style={{ position: 'relative' }}>
            <img
              className="card-img-top"
              src={`${process.env.REACT_APP_BACKEND_URL}/storage/fondo_test.jpg`}
              alt=""
            />
            {!items[0].logo && (
              <img
                className="small-image"
                src={`${process.env.REACT_APP_BACKEND_URL}/storage/estandar.png`}
                alt=""
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  maxWidth: '50%',
                  maxHeight: '50%'
                }}
              />
            )}
          </div>
        ) : (
          <div style={{ position: 'relative' }}>
            <img
              className="card-img-top"
              src={`${process.env.REACT_APP_BACKEND_URL}/storage/backgrounds/${items[0].background_image}`}
              alt=""
            />
            {items[0].logo && (
              <img
                className="small-image"
                src={`${process.env.REACT_APP_BACKEND_URL}/storage/logos/${items[0].logo}`}
                alt=""
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  maxWidth: '50%',
                  maxHeight: '50%'
                }}
              />
            )}
          </div>
        )} */}
        <Card.Body className="overflow-hidden">
          <Row className="flex-center">
            <Col>
              <Flex>
                <div className="fs--1 ms-2 flex-1">
                  <h5 className="fs-0 text-capitalize">Nombre</h5>
                  <p
                    className={`${
                      localStorage.getItem('isDark') === 'true'
                        ? 'text-400'
                        : 'text-700'
                    }`}
                  >
                    {items[0].name}
                  </p>
                </div>
              </Flex>
            </Col>
            <Col md="auto" className="mt-4 mt-md-0">
              <Flex>
                <Button
                  variant="falcon-primary"
                  size="sm"
                  className="px-4 px-sm-5"
                  onClick={() => {
                    setItem(items[0]);
                    handleEModal();
                  }}
                >
                  Editar
                </Button>
              </Flex>
            </Col>
          </Row>
        </Card.Body>
        <Card.Body>
          <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
            <Tab eventKey="home" title="Institucional">
              <div className="row w-100" style={{ marginTop: 10 }}>
                <div className="col-md-11">
                  <Row>
                    <Col>
                      <div
                        id="name-row"
                        className={`${
                          localStorage.getItem('isDark') === 'true'
                            ? 'text-400'
                            : 'text-700'
                        }`}
                        style={{ display: 'flex' }}
                      >
                        <p
                          className="fs-0 mb-3"
                          style={{
                            margin: 0,
                            marginRight: 10,
                            fontWeight: 'bold'
                          }}
                        >
                          Sector:
                        </p>
                        <p style={{ margin: 0 }}>{items[0].sectors?.name}</p>
                      </div>
                    </Col>
                    <Col>
                      <div
                        id="name-row"
                        className={`${
                          localStorage.getItem('isDark') === 'true'
                            ? 'text-400'
                            : 'text-700'
                        }`}
                        style={{ display: 'flex' }}
                      >
                        <p
                          className="fs-0 mb-3"
                          style={{
                            margin: 0,
                            marginRight: 10,
                            fontWeight: 'bold'
                          }}
                        >
                          Ciudad:
                        </p>
                        <p>{items[0].cities?.name}</p>
                      </div>
                    </Col>
                  </Row>
                  <di
                    className={`${
                      localStorage.getItem('isDark') === 'true'
                        ? 'text-400'
                        : 'text-700'
                    }`}
                  >
                    <p className="fs-0 mb-3" style={{ fontWeight: 'bold' }}>
                      Sobre la Universidad:
                    </p>
                    <p dangerouslySetInnerHTML={{ __html: items[0].about }}></p>
                  </di>
                </div>
              </div>
            </Tab>
            <Tab eventKey="usp" title="USP">
              <div className="row w-100" style={{ marginTop: 10 }}>
                <div className="col-md-11">
                  <div
                    className={`${
                      localStorage.getItem('isDark') === 'true'
                        ? 'text-400'
                        : 'text-700'
                    }`}
                  >
                    <Row>
                      <Col>
                        <div
                          id="name-row"
                          className="mb-1"
                          style={{ display: 'flex' }}
                        >
                          <p
                            className="fs-0 mb-3"
                            style={{
                              margin: 0,
                              marginRight: 10,
                              fontWeight: 'bold'
                            }}
                          >
                            Año de fundación:
                          </p>
                          <p style={{ margin: 0 }}>
                            {items[0].year_foundation}
                          </p>
                        </div>
                      </Col>
                      <Col>
                        <div
                          id="name-row"
                          className="mb-1"
                          style={{ display: 'flex' }}
                        >
                          <p
                            className="fs-0 mb-3"
                            style={{
                              margin: 0,
                              marginRight: 10,
                              fontWeight: 'bold'
                            }}
                          >
                            Graduados:
                          </p>
                          <p>{items[0].graduates}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div
                          id="name-row"
                          className="mb-1"
                          style={{ display: 'flex' }}
                        >
                          <p
                            className="fs-0 mb-3"
                            style={{
                              margin: 0,
                              marginRight: 10,
                              fontWeight: 'bold'
                            }}
                          >
                            Estudia a tu ritmo:
                          </p>
                          <p style={{ margin: 0 }}>{items[0].study_pace}</p>
                        </div>
                      </Col>
                      <Col>
                        <div
                          id="name-row"
                          className="mb-1"
                          style={{ display: 'flex' }}
                        >
                          <p
                            className="fs-0 mb-3"
                            style={{
                              margin: 0,
                              marginRight: 10,
                              fontWeight: 'bold'
                            }}
                          >
                            Información de programas Virtuales:
                          </p>
                          <p>{items[0].virtual_program_information}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div
                          id="name-row"
                          className="mb-1"
                          style={{ display: 'flex' }}
                        >
                          <p
                            className="fs-0 mb-3"
                            style={{
                              margin: 0,
                              marginRight: 10,
                              fontWeight: 'bold'
                            }}
                          >
                            Información de Títulos:
                          </p>
                          <p style={{ margin: 0 }}>
                            {items[0].title_information}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="url" title="Urls">
              <div className="row w-100" style={{ marginTop: 10 }}>
                <div className="col-md-11">
                  <div
                    className={`${
                      localStorage.getItem('isDark') === 'true'
                        ? 'text-400'
                        : 'text-700'
                    }`}
                  >
                    <Row>
                      <Col>
                        <div
                          id="name-row"
                          className="mb-1"
                          style={{ display: 'flex' }}
                        >
                          <p
                            className="fs-0 mb-3"
                            style={{
                              margin: 0,
                              marginRight: 10,
                              fontWeight: 'bold'
                            }}
                          >
                            URL de Facebook:
                          </p>
                          <p style={{ margin: 0 }}>{items[0].url_facebook}</p>
                        </div>
                      </Col>
                      <Col>
                        <div
                          id="name-row"
                          className="mb-1"
                          style={{ display: 'flex' }}
                        >
                          <p
                            className="fs-0 mb-3"
                            style={{
                              margin: 0,
                              marginRight: 10,
                              fontWeight: 'bold'
                            }}
                          >
                            URL de Linkedin:
                          </p>
                          <p>{items[0].url_linkedin}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div
                          id="name-row"
                          className="mb-1"
                          style={{ display: 'flex' }}
                        >
                          <p
                            className="fs-0 mb-3"
                            style={{
                              margin: 0,
                              marginRight: 10,
                              fontWeight: 'bold'
                            }}
                          >
                            URL de Instagram:
                          </p>
                          <p style={{ margin: 0 }}>{items[0].url_instagram}</p>
                        </div>
                      </Col>
                      <Col>
                        <div
                          id="name-row"
                          className="mb-1"
                          style={{ display: 'flex' }}
                        >
                          <p
                            className="fs-0 mb-3"
                            style={{
                              margin: 0,
                              marginRight: 10,
                              fontWeight: 'bold'
                            }}
                          >
                            Url Video:
                          </p>
                          <p>{items[0].video_about}</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
          {/* <IconGroup icons={socialIcons} /> */}
        </Card.Body>
      </Card>
      <div style={{ display: 'inline-block', margin: 5 }}>
        <Card style={{ width: '16rem' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Card.Img
              src={`${process.env.REACT_APP_BACKEND_URL}/storage/gallery/sede.png`}
              variant="top"
              style={{ width: '100px', height: '100px' }}
            />
          </div>
          <Card.Body
            className={`${
              localStorage.getItem('isDark') === 'true'
                ? 'text-400'
                : 'text-700'
            }`}
          >
            <Card.Title
              as="h5"
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              Sedes:{' '}
              {items[0].campus === null || items[0].campus === undefined
                ? 0
                : items[0].campus.length}
            </Card.Title>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '10px'
              }}
            >
              <Button
                color="primary"
                size="sm"
                onClick={() => handleClick('campus')}
              >
                Editar
              </Button>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div style={{ display: 'inline-block', margin: 5 }}>
        <Card style={{ width: '16rem' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Card.Img
              src={`${process.env.REACT_APP_BACKEND_URL}/storage/gallery/convenio.png`}
              variant="top"
              style={{ width: '100px', height: '100px' }}
            />
          </div>
          <Card.Body
            className={`${
              localStorage.getItem('isDark') === 'true'
                ? 'text-400'
                : 'text-700'
            }`}
          >
            <Card.Title
              as="h5"
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              Convenios:{' '}
              {items[0].agreements === null || items[0].agreements === undefined
                ? 0
                : items[0].agreements.length}
            </Card.Title>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '10px'
              }}
            >
              <Button
                color="primary"
                size="sm"
                onClick={() => handleClick('agreements')}
              >
                Editar
              </Button>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div style={{ display: 'inline-block', margin: 5 }}>
        <Card style={{ width: '16rem' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Card.Img
              src={`${process.env.REACT_APP_BACKEND_URL}/storage/gallery/bienestar.png`}
              variant="top"
              style={{ width: '100px', height: '100px' }}
            />
          </div>
          <Card.Body
            className={`${
              localStorage.getItem('isDark') === 'true'
                ? 'text-400'
                : 'text-700'
            }`}
          >
            <Card.Title
              as="h5"
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              Bienestar:{' '}
              {items[0].welfare === null || items[0].welfare === undefined
                ? 0
                : items[0].welfare.length}
            </Card.Title>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '10px'
              }}
            >
              <Button
                color="primary"
                size="sm"
                onClick={() => handleClick('welfare')}
              >
                Editar
              </Button>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div style={{ display: 'inline-block', margin: 5 }}>
        <Card style={{ width: '16rem' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Card.Img
              src={`${process.env.REACT_APP_BACKEND_URL}/storage/gallery/bonos.png`}
              variant="top"
              style={{ width: '100px', height: '100px' }}
            />
          </div>
          <Card.Body
            className={`${
              localStorage.getItem('isDark') === 'true'
                ? 'text-400'
                : 'text-700'
            }`}
          >
            <Card.Title
              as="h5"
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              Bonos:{' '}
              {items[0].bonds === null || items[0].bonds === undefined
                ? 0
                : items[0].bonds.length}
            </Card.Title>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '10px'
              }}
            >
              <Button
                color="primary"
                size="sm"
                onClick={() => handleClick('bonds')}
              >
                Editar
              </Button>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div style={{ display: 'inline-block', margin: 5 }}>
        <Card style={{ width: '16rem' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Card.Img
              src={`${process.env.REACT_APP_BACKEND_URL}/storage/gallery/rector.png`}
              variant="top"
              style={{ width: '100px', height: '100px' }}
            />
          </div>
          <Card.Body
            className={`${
              localStorage.getItem('isDark') === 'true'
                ? 'text-400'
                : 'text-700'
            }`}
          >
            <Card.Title
              as="h5"
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              Rectores:{' '}
              {items[0].rectors === null || items[0].rectors === undefined
                ? 0
                : items[0].rectors.length}
            </Card.Title>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '10px'
              }}
            >
              <Button
                color="primary"
                size="sm"
                onClick={() => handleClick('rectors')}
              >
                Editar
              </Button>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div style={{ display: 'inline-block', margin: 5 }}>
        <Card style={{ width: '16rem' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Card.Img
              src={`${process.env.REACT_APP_BACKEND_URL}/storage/gallery/galeria.png`}
              variant="top"
              style={{ width: '100px', height: '100px' }}
            />
          </div>
          <Card.Body
            className={`${
              localStorage.getItem('isDark') === 'true'
                ? 'text-400'
                : 'text-700'
            }`}
          >
            <Card.Title
              as="h5"
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              Imágenes:{' '}
              {items[0].images === null || items[0].images === undefined
                ? 0
                : items[0].images.length}
            </Card.Title>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '10px'
              }}
            >
              <Button
                color="primary"
                size="sm"
                onClick={() => handleClick('images')}
              >
                Editar
              </Button>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div style={{ display: 'inline-block', margin: 5 }}>
        <Card style={{ width: '16rem' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Card.Img
              src={`${process.env.REACT_APP_BACKEND_URL}/storage/gallery/requisito.png`}
              variant="top"
              style={{ width: '100px', height: '100px' }}
            />
          </div>
          <Card.Body
            className={`${
              localStorage.getItem('isDark') === 'true'
                ? 'text-400'
                : 'text-700'
            }`}
          >
            <Card.Title
              as="h5"
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              Requisitos:{' '}
              {items[0].requirements === null ||
              items[0].requirements === undefined
                ? 0
                : items[0].requirements.length}
            </Card.Title>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '10px'
              }}
            >
              <Button
                color="primary"
                size="sm"
                onClick={() => handleClick('requirements')}
              >
                Editar
              </Button>
            </div>
          </Card.Body>
        </Card>
      </div>

      {Form_ && (
        <EModal
          dialogClassName="max-width: 900px;"
          form_={<Form_ submit={submit} item={item} items={items} />}
          title={caption}
          modal={emodal}
          modalSize={modalSize}
          handleModal={handleEModal}
        />
      )}
    </>
  );
};

UniversityProfile.propTypes = {
  useHook: PropTypes.func,
  authorized: PropTypes.bool
};

export default UniversityProfile;
