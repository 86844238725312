import React, { useEffect, useState, createContext } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useDefault, useAuth } from 'hooks';

import Buttons from 'components/templates/Buttons';
import Form_ from 'components/forms/BankForm';
import { SwitchDt } from 'components/SwitchDt';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import Avatar from 'components/common/Avatar';

const BankContext = createContext();

const BankProvider = ({ children }) => {
  const {
    list,
    add,
    remove,
    update,
    status,
    handleCModal,
    handleEModal,
    handleDModal
  } = useDefault();
  const { isAuthenticated } = useAuth();

  const [item, setItem] = useState({});
  const modalSize = 'xl';
  const [items, setItems] = useState([]);

  //==============================
  const columns = [
    {
      accessor: 'name',
      Header: 'Nombre',
      Cell: rowData => {
        const { name } = rowData.row.original;
        return (
          <div className="d-flex align-items-center">
            <Avatar size="l" name={name} />
            <div className="ms-2">{name}</div>
          </div>
        );
      }
    },
    {
      accessor: 'financing',
      Header: 'Finanza'
    },
    {
      accessor: 'range.range',
      Header: 'Rango'
    },
    {
      accessor: 'rate_nmv',
      Header: 'Rango NMV'
    },
    {
      accessor: 'rate_ea',
      Header: 'Rango EA'
    },
    {
      accessor: 'agreement',
      Header: 'Estado',
      Cell: rowData => {
        const { recommended } = rowData.row.original;
        return recommended ? (
          <SoftBadge pill bg="success">
            Recomendado
            <FontAwesomeIcon icon="check" className="ms-2" />{' '}
          </SoftBadge>
        ) : (
          <SoftBadge pill bg="secondary" className="me-2">
            No recomendado
            <FontAwesomeIcon icon="ban" className="ms-2" />
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'btn',
      Header: 'Acciones',
      Cell: rowData => {
        const { _id, recommended } = rowData.row.original;
        return (
          <Flex className="gap-1 mt-1.5">
            {' '}
            <SwitchDt
              id={_id}
              status={recommended}
              pt={false}
              tx={'No recomendar'}
              tx0={'Recomendar'}
              handledEvent={() => {
                setItem(rowData.row.original);
                handleCModal();
              }}
            />
            <Buttons
              btn={'update'}
              handleEvent={() => {
                rowData.row.original.pt = false;
                setItem(rowData.row.original);
                handleEModal();
              }}
            />
            <Buttons
              btn={'delete'}
              handleEvent={() => {
                setItem(rowData.row.original);
                handleDModal();
              }}
            />
          </Flex>
        );
      }
    }
  ];
  //* FUNTION FOR SAVE OR MODIFY REGISTER
  const submit = object_ => {
    if (object_.get('_id')) {
      object_._id = object_.get('_id');
      update('banks/update', object_).then(res => {
        res &&
          setItems(
            items.map(stateObj => (stateObj._id === res._id ? res : stateObj))
          );
        setItem({});
      });
    } else {
      add('banks/add', object_).then(res => res && setItems([...items, res]));
    }
  };
  //* GET ALL REGISTER
  useEffect(() => {
    list('banks/list').then(data => {
      data && setItems(data);
    });
  }, [isAuthenticated]);

  const changeStatus = async () => {
    status(`banks/recommended`, {
      _id: item._id,
      recommended: !item.recommended
    }).then(res => {
      res &&
        setItems(
          items.map(stateObj => (stateObj._id === res._id ? res : stateObj))
        );
      setItem({});
    });
  };
  const removeOne = async () => {
    remove(`banks/delete/${item._id}`).then(() => {
      setItems(items.filter(stateObj => stateObj._id !== item._id));
      setItem({});
    });
  };

  return (
    <BankContext.Provider
      value={{
        changeStatus,
        removeOne,
        item,
        items,
        setItem,
        setItems,
        columns,
        Form_,
        submit,
        caption: 'Banco',
        captions: 'Bancos',
        confirm: true,
        modalSize
      }}
    >
      {children}
    </BankContext.Provider>
  );
};

BankProvider.propTypes = {
  children: PropTypes.node
};

export { BankProvider };
export default BankContext;
