import FalconCardHeader from 'components/common/FalconCardHeader';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart } from 'echarts/charts';
import {
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent,
  DatasetComponent
]);

const getOption = data => ({
  color: [getColor('primary')],
  dataset: { source: data },
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('gray-1100') },
    borderWidth: 1,
    transitionDuration: 0,
    formatter: function (params) {
      return `<div className="fw-semibold">${
        params.seriesName
      }</div><div className="fs-10 text-600"><strong>${params.name}:</strong> ${
        params.value[params.componentIndex + 1]
      }</div>`;
    }
  },
  legend: {
    data: ['2019'],
    left: 'left',
    itemWidth: 10,
    itemHeight: 10,
    borderRadius: 0,
    icon: 'circle',
    inactiveColor: getColor('gray-400'),
    textStyle: { color: getColor('gray-700') }
  },
  xAxis: {
    type: 'category',
    axisLabel: {
      color: getColor('gray-400'),
      formatter: function (value) {
        var count = 0;
        var formattedValue = '';
        for (var i = 0; i < value.length; i++) {
          if (value[i] === ' ') {
            count += 1;
            if (count === 2) {
              formattedValue += '\n';
              count = 0;
            }
          }
          formattedValue += value[i];
        }
        return formattedValue;
      }
    },
    axisLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    axisTick: false,
    boundaryGap: true
  },
  yAxis: {
    axisPointer: { type: 'none' },
    axisTick: 'none',
    splitLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    axisLine: { show: false },
    axisLabel: { color: getColor('gray-400') }
  },
  series: [
    {
      type: 'bar',
      barWidth: '10px',
      barGap: '30%',
      label: { show: false },
      z: 10,
      itemStyle: {
        borderRadius: [10, 10, 0, 0],
        color: getColor('primary')
      }
    }
    // {
    //   type: 'bar',
    //   barWidth: '10px',
    //   barGap: '30%',
    //   label: { show: false },
    //   itemStyle: {
    //     borderRadius: [10, 10, 0, 0],
    //     color: getColor('gray-300')
    //   }
    // }
  ],
  grid: { right: '0', left: '30px', bottom: '10%', top: '20%' }
});

const TopLeads = ({ data, title }) => {
  return (
    <Card className="h-100">
      <FalconCardHeader title={title} titleTag="h6" className="py-2" light />
      <Card.Body className="h-100">
        {data.length > 1 && (
          <ReactEChartsCore
            echarts={echarts}
            option={getOption(data)}
            style={{ height: '100%', minHeight: '17.75rem' }}
          />
        )}
        {data.length === 1 && (
          <h6 className="text-center">No hay datos para mostrar!!!</h6>
        )}
      </Card.Body>
    </Card>
  );
};

TopLeads.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired
};

export default TopLeads;
