import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart } from 'echarts/charts';
import {
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent,
  DatasetComponent
]);

const getOption = data => ({
  color: [getColor('primary'), getColor('gray-300')],
  dataset: { source: data },
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    transitionDuration: 0,
    formatter: function (params) {
      return `<div className="fw-semi-bold">${
        params.seriesName
      }</div><div className="fs--1 text-600"><strong>${params.name}:</strong> ${
        params.value[params.componentIndex + 1]
      }</div>`;
    }
  },
  legend: {
    data: ['Acreditada', 'No Acreditada'],
    left: 'left',
    itemWidth: 10,
    itemHeight: 10,
    borderRadius: 0,
    icon: 'circle',
    inactiveColor: getColor('gray-400'),
    textStyle: { color: getColor('gray-700') }
  },
  xAxis: {
    type: 'category',
    axisLabel: { color: getColor('gray-400') },
    axisLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    axisTick: false,
    boundaryGap: true
  },
  yAxis: {
    axisPointer: { type: 'none' },
    axisTick: 'none',
    splitLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    axisLine: { show: false },
    axisLabel: { color: getColor('gray-400') }
  },
  series: [
    {
      type: 'bar',
      barWidth: '15px',
      barGap: '30%',
      label: { show: false },
      z: 10,
      itemStyle: {
        borderRadius: [10, 10, 0, 0],
        color: getColor('primary')
      }
    },
    {
      type: 'bar',
      barWidth: '15px',
      barGap: '30%',
      label: { show: false },
      itemStyle: {
        borderRadius: [10, 10, 0, 0],
        color: getColor('gray-300')
      }
    }
  ],
  grid: { right: '0', left: '30px', bottom: '10%', top: '20%' }
});

const TopProducts = ({
  data,
  data2,
  title,
  setColumns,
  setallStudents,
  handleEModal,
  setTitle
}) => {
  return (
    <Card className="h-100">
      <FalconCardHeader
        title={title}
        titleTag="h6"
        className="py-2"
        light
        endEl={
          <Flex>
            <Button
              variant="link"
              size="sm"
              as={Link}
              className="me-2"
              to="#!"
              onClick={() => {
                setTitle(title);
                setColumns([
                  {
                    accessor: 'project',
                    Header: 'Nombre'
                  },
                  {
                    accessor: 'accredited',
                    Header: 'Acreditada'
                  },
                  {
                    accessor: 'noAccredited',
                    Header: 'No Acreditada'
                  }
                  // {
                  //   accessor: 'duration',
                  //   Header: 'Cantidad'
                  // }
                ]);
                setallStudents(data2);
                handleEModal();
              }}
            >
              Ver más...
            </Button>
          </Flex>
        }
      />
      <Card.Body className="h-100">
        <ReactEChartsCore
          echarts={echarts}
          option={getOption(data)}
          style={{ height: '100%', minHeight: '17.75rem' }}
        />
      </Card.Body>
    </Card>
  );
};

TopProducts.propTypes = {
  data: PropTypes.array.isRequired,
  data2: PropTypes.array,
  title: PropTypes.string,
  setColumns: PropTypes.func,
  setallStudents: PropTypes.func,
  handleEModal: PropTypes.func,
  setTitle: PropTypes.func
};

export default TopProducts;
