import classNames from 'classnames';
import AppContext from 'context/Context';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Collapse, Nav } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import Flex from 'components/common/Flex';
import Access from 'routes/Access';

const CollapseItems = ({ route, access }) => {
  const { pathname } = useLocation();

  const openCollapse = childrens => {
    const checkLink = children => {
      if (children.to === pathname) {
        return true;
      }
      return (
        Object.prototype.hasOwnProperty.call(children, 'children') &&
        children.children.some(checkLink)
      );
    };
    return childrens.some(checkLink);
  };

  const [open, setOpen] = useState(openCollapse(route.children));

  return (
    (access[route.access] && (
      <Nav.Item as="li">
        <Nav.Link
          onClick={() => {
            setOpen(!open);
          }}
          className={classNames('dropdown-indicator cursor-pointer', {
            'text-500': !route.active
          })}
          aria-expanded={open}
          // {...route}
        >
          <Flex alignItems="center">
            {route.icon && (
              <span className="nav-link-icon">
                <FontAwesomeIcon icon={route.icon} />
              </span>
            )}
            <span className="nav-link-text ps-1">{route.name}</span>
            {route.badge && (
              <SoftBadge pill bg={route.badge.type} className="ms-2">
                {route.badge.text}
              </SoftBadge>
            )}
          </Flex>
        </Nav.Link>
        <Collapse in={open}>
          <Nav className="flex-column nav" as="ul">
            <NavbarVerticalMenu routes={route.children} />
          </Nav>
        </Collapse>
      </Nav.Item>
    )) ||
    null
  );
};

CollapseItems.propTypes = {
  route: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    children: PropTypes.array.isRequired,
    active: PropTypes.bool,
    access: PropTypes.string,
    badge: PropTypes.shape({
      type: PropTypes.string,
      text: PropTypes.string
    })
  }).isRequired,
  access: PropTypes.object.isRequired
};

const NavbarVerticalMenu = ({ routes }) => {
  const access = Access();
  const {
    config: { showBurgerMenu },
    setConfig
  } = useContext(AppContext);

  const handleNavItemClick = () => {
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu);
    }
  };
  return routes.map(route => {
    if (!route.children) {
      return (
        (access[route.access] && (
          <Nav.Item as="li" key={route.name} onClick={handleNavItemClick}>
            <NavLink
              end={route.exact}
              to={route.to}
              state={{ open: route.to === '/authentication-modal' }}
              className={({ isActive }) =>
                isActive && route.to !== '#!' ? 'active nav-link' : 'nav-link'
              }
            >
              <Flex alignItems="center">
                {route.icon && (
                  <span className="nav-link-icon">
                    <FontAwesomeIcon icon={route.icon} />
                  </span>
                )}
                <span className="nav-link-text ps-1">{route.name}</span>
                {route.badge && (
                  <SoftBadge pill bg={route.badge.type} className="ms-2">
                    {route.badge.text}
                  </SoftBadge>
                )}
              </Flex>
            </NavLink>
          </Nav.Item>
        )) ||
        null
      );
    }
    return <CollapseItems route={route} access={access} key={route.name} />;
  });
};

export default NavbarVerticalMenu;
