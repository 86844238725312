import React from 'react';

import { CloseButton } from 'components/common/Toast';
import SettingsToggle from 'components/settings-panel/SettingsToggle';
import SettingsPanel from 'components/settings-panel/SettingsPanel';

import { toast, ToastContainer } from 'react-toastify';
import IndexRoutes from 'routes';

import { DefaultProvider } from 'context/DefaultProvider';
import { AuthProvider } from 'context/AuthProvider';
import { UniversityProvider } from 'context/UniversityProvider';
import { UniversityProfileProvider } from 'context/UniversityProfileProvider';
import { CourseProvider } from 'context/CourseProvider';
import { BankProvider } from 'context/BankProvider';
// import { BootCampsProvider } from 'context/BootCampsProvider';
import { OrientationProvider } from 'context/OrientationProvider';
// import { MentoringProvider } from 'context/MentoringProvider';
import { ReferralProvider } from 'context/ReferralProvider';
import { SurveyProvider } from 'context/SurveyProvider';
import { ScholarshipProvider } from 'context/ScholarshipProvider';
import { SectorsProvider } from 'context/SectorsProvider';
import { DepartmentsProvider } from 'context/DepartmentsProvider';
import { CitiesProvider } from 'context/CitiesProvider';
import { ProgramsProvider } from 'context/ProgramsProvider';
import { ModalitysProvider } from 'context/ModalitysProvider';
import { KnowledgeAreaProvider } from 'context/KnowledgeAreaProvider';
import { BasicCoresProvider } from 'context/BasicCoresProvider';
import { LevelsEducationProvider } from 'context/LevelsEducationProvider';
import { LevelsProvider } from 'context/LevelsProvider';
import { PeriodsProvider } from 'context/PeriodsProvider';
import { BankRangeProvider } from 'context/BankRangeProvider';
import { BankFeaturesProvider } from 'context/BankFeaturesProvider';
import { BankRequirementsProvider } from 'context/BankRequirementsProvider';
import { MarksCoursesProvider } from 'context/MarksCoursesProvider';
// import { MarksBootCampsProvider } from 'context/MarksBootCampsProvider';
// import { MarksMentoringProvider } from 'context/MarksMentoringProvider';
import { ApplicationsProvider } from 'context/ApplicationsProvider';
import { RolesProvider } from 'context/RolesProvider';
import { CampusProvider } from 'context/CampusProvider';
import { AgreementsProvider } from 'context/AgreementsProvider';
import { WelfareProvider } from 'context/WelfareProvider';
import { BondsProvider } from 'context/BondsProvider';
import { RectorsProvider } from 'context/RectorsProvider';
import { GalleryProvider } from 'context/GalleryProvider';
import { AgreementsProgramProvider } from 'context/AgreementsProgramProvider';
import { ChargesProvider } from 'context/ChargesProvider';
import { SpreadsProvider } from 'context/SpreadsProvider';
import { TeachersProvider } from 'context/TeachersProvider';
import { RequirementsProvider } from 'context/RequirementsProvider';
import { DimensionsProvider } from './DimensionsProvider';

const index = () => {
  return (
    <DefaultProvider>
      <AuthProvider>
        <SectorsProvider>
          <DepartmentsProvider>
            <DimensionsProvider>
              <CitiesProvider>
                <ModalitysProvider>
                  <KnowledgeAreaProvider>
                    <BasicCoresProvider>
                      <LevelsEducationProvider>
                        <LevelsProvider>
                          <PeriodsProvider>
                            <BankRangeProvider>
                              <UniversityProvider>
                                <UniversityProfileProvider>
                                  <ProgramsProvider>
                                    <MarksCoursesProvider>
                                      <CourseProvider>
                                        <BankProvider>
                                          <BankFeaturesProvider>
                                            <BankRequirementsProvider>
                                              {/* <MarksBootCampsProvider>
                                              <BootCampsProvider> */}
                                              <OrientationProvider>
                                                {/* <MarksMentoringProvider>
                                                    <MentoringProvider> */}
                                                <ReferralProvider>
                                                  <SurveyProvider>
                                                    <ScholarshipProvider>
                                                      <RolesProvider>
                                                        <CampusProvider>
                                                          <AgreementsProvider>
                                                            <WelfareProvider>
                                                              <BondsProvider>
                                                                <RequirementsProvider>
                                                                  <RectorsProvider>
                                                                    <GalleryProvider>
                                                                      <AgreementsProgramProvider>
                                                                        <ChargesProvider>
                                                                          <SpreadsProvider>
                                                                            <TeachersProvider>
                                                                              <ApplicationsProvider>
                                                                                <IndexRoutes />

                                                                                <SettingsToggle />
                                                                                <SettingsPanel />

                                                                                <ToastContainer
                                                                                  closeButton={
                                                                                    CloseButton
                                                                                  }
                                                                                  icon={
                                                                                    false
                                                                                  }
                                                                                  position={
                                                                                    toast
                                                                                      .POSITION
                                                                                      .TOP_CENTER
                                                                                  }
                                                                                />
                                                                              </ApplicationsProvider>
                                                                            </TeachersProvider>
                                                                          </SpreadsProvider>
                                                                        </ChargesProvider>
                                                                      </AgreementsProgramProvider>
                                                                    </GalleryProvider>
                                                                  </RectorsProvider>
                                                                </RequirementsProvider>
                                                              </BondsProvider>
                                                            </WelfareProvider>
                                                          </AgreementsProvider>
                                                        </CampusProvider>
                                                      </RolesProvider>
                                                    </ScholarshipProvider>
                                                  </SurveyProvider>
                                                </ReferralProvider>
                                                {/* </MentoringProvider>
                                                  </MarksMentoringProvider> */}
                                              </OrientationProvider>
                                              {/* </BootCampsProvider>
                                            </MarksBootCampsProvider> */}
                                            </BankRequirementsProvider>
                                          </BankFeaturesProvider>
                                        </BankProvider>
                                      </CourseProvider>
                                    </MarksCoursesProvider>
                                  </ProgramsProvider>
                                </UniversityProfileProvider>
                              </UniversityProvider>
                            </BankRangeProvider>
                          </PeriodsProvider>
                        </LevelsProvider>
                      </LevelsEducationProvider>
                    </BasicCoresProvider>
                  </KnowledgeAreaProvider>
                </ModalitysProvider>
              </CitiesProvider>
            </DimensionsProvider>
          </DepartmentsProvider>
        </SectorsProvider>
      </AuthProvider>
    </DefaultProvider>
  );
};

export default index;
