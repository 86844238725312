import React from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import Flex from 'components/common/Flex';

const _404 = () => {
  return (
    <Flex justifyContent={'center'} className="mt-7">
      <Card className="text-center">
        <Card.Body className="p-5">
          <div className="display-1 text-300 fs-error">404</div>
          <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
            La página que estás buscando no se encuentra.
          </p>
          <hr />
          <p>
            Asegúrate de que la dirección sea correcta y que la página no se
            haya movido. Si crees que esto es un error,
            <a href="mailto:contacto@mifutura.co" className="ms-1">
              contactenos!
            </a>
            .
          </p>
          <Link className="btn btn-primary btn-sm mt-3" to="/dashboard">
            <FontAwesomeIcon icon={faHome} className="me-2" />
            Ir a inicio
          </Link>
        </Card.Body>
      </Card>
    </Flex>
  );
};

export default _404;
