import classNames from 'classnames';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, ProgressBar, Table } from 'react-bootstrap';
import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import { useDefault } from 'hooks';
import { Link } from 'react-router-dom';

const ListBanck = ({ nbanck, isLast }) => {
  const { name, logo, financing, rate_nmv, rate_ea, range } = nbanck;
  let nrate_nmv = rate_nmv; // rate_nmv.split('%')[0].split('Desde ');
  let nrate_ea = rate_ea; // rate_ea.split('%')[0].split('Desde ');
  let range_name = range?.range; // rate_ea.split('%')[0].split('Desde ');
  let logoUrl = `${process.env.REACT_APP_BACKEND_URL}/storage/logobancos/${logo}`;

  return (
    <tr className={classNames({ 'border-bottom border-200': !isLast })}>
      <td>
        <Flex alignItems="center" className="position-relative">
          <img
            className="rounded-1 border border-200"
            src={logoUrl}
            width="60"
            alt={name}
          />
          <div className="ms-3">
            <h6 className="mb-1 fw-semi-bold">
              <Link className="text-dark stretched-link" to="#!">
                {name}
              </Link>
            </h6>
            <p className="fw-semi-bold mb-0 text-500">{financing}</p>
            <p className="fw-semi-bold mb-0 text-500">{range_name}</p>
          </div>
        </Flex>
      </td>
      <td
        className={`${
          localStorage.getItem('isDark') === 'true'
            ? 'align-middle text-end fw-semi-bold text-400'
            : 'align-middle text-end fw-semi-bold text-700'
        }`}
      >
        {nrate_nmv}
      </td>
      <td className="align-middle pe-x1">
        <Flex alignItems="center">
          <ProgressBar now={''} style={{ width: '80px', height: 5 }} />
          <div
            className={`${
              localStorage.getItem('isDark') === 'true'
                ? 'fw-semi-bold ms-3 text-400'
                : 'fw-semi-bold ms-3 text-700'
            }`}
          >
            {nrate_ea}
          </div>
        </Flex>
      </td>
    </tr>
  );
};

ListBanck.propTypes = {
  isLast: PropTypes.bool,
  nbanck: PropTypes.shape({
    _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    range: PropTypes.object.isRequired,
    logo: PropTypes.string.isRequired,
    financing: PropTypes.string.isRequired,
    rate_nmv: PropTypes.string.isRequired,
    rate_ea: PropTypes.string.isRequired
  }).isRequired
};

const Banck = ({ data, toShow }) => {
  const noOfProducts = data.length;
  const { handleEModal } = useDefault();

  return (
    <Card className="h-lg-100 overflow-hidden">
      <Card.Body className="p-0 ">
        <Table borderless responsive className="mb-0 fs--1">
          <thead className="bg-light">
            <tr className="text-900">
              <th>Bancos</th>
              {/* <th className="text-end">Revenue ( ${totalPrice})</th>
              <th className="pe-x1 text-end" style={{ width: '8rem' }}>
                Revenue (%)
              </th> */}
            </tr>
          </thead>
          <tbody>
            {data
              ?.slice(0, toShow !== 'f' ? toShow : data.length)
              .map((item, index) => (
                <ListBanck
                  nbanck={item}
                  isLast={index === noOfProducts - 1}
                  key={item._id}
                />
              ))}
          </tbody>
        </Table>
      </Card.Body>

      {toShow !== 'f' && (
        <FalconCardFooterLink
          title="mostrar listado completo"
          size="sm"
          onClick={handleEModal}
        />
      )}
    </Card>
  );
};

Banck.propTypes = {
  data: PropTypes.array.isRequired,
  toShow: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export default Banck;
