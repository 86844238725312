import React, { useState, useEffect } from 'react';
import { Row, Col, Alert } from 'react-bootstrap';
import { useDefault } from 'hooks';
import BackDrop from 'components/templates/BackDrop';
import UniversityProjects from 'components/dashboards/default/UniversityProjects';
import MarketShare from 'components/dashboards/default/MarketShare';
import { EModal } from 'components/templates/Modal';
import TableModal from 'components/dashboards/futura/TableModal';
import WeeklyGoals from 'components/dashboards/analytics/weekly-goals/WeeklyGoals';

const IES = () => {
  const { emodal, handleEModal, list } = useDefault();
  const [numUniversities, setNumUniversities] = useState([]);
  const [numPrograms, setNumPrograms] = useState([]);
  const [columns, setColumns] = useState([
    {
      accessor: 'name',
      Header: 'Nombre'
    },
    {
      accessor: 'lastname',
      Header: 'Apellidos'
    },
    {
      accessor: 'email',
      Header: 'Correo'
    },
    {
      accessor: 'phone',
      Header: 'Telefono'
    }
  ]);
  const [allStudents, setallStudents] = useState([]);
  const [title, setTitle] = useState('');

  useEffect(() => {
    getNumUniversity();
    getNumPrograms();
  }, []);

  const getNumUniversity = () => {
    list('dashboard_university/data_university_count').then(data => {
      data && setNumUniversities(data);
    });
  };
  const getNumPrograms = () => {
    list('dashboard_university/data_university_program').then(data => {
      data && setNumPrograms(data);
    });
  };

  if (
    numUniversities === undefined ||
    Object.keys(numUniversities).length === 0 ||
    Object.keys(numPrograms).length === 0 ||
    numPrograms === undefined
  )
    return <BackDrop param={' '} />;
  if (
    numUniversities?.cities?.length > 0 &&
    numUniversities?.sectors?.length > 0 &&
    numPrograms?.cities?.length > 0 &&
    numPrograms?.modalitys?.length > 0 &&
    numPrograms?.levels?.length > 0 &&
    numPrograms?.universities?.length > 0 &&
    numPrograms?.education_levels?.length > 0
  )
    return (
      <>
        <Row className="g-3 mb-3">
          <Alert.Heading>Número de Universidades</Alert.Heading>
          {/* <Col md={6} xxl={3}>
            <div style={{ maxHeight: '320px', overflowY: 'auto' }}>
              <ProjectStatisticsCant
                projectsTable={numUniversities.cities}
                title="Por Ciudad"
              />
            </div>
          </Col> */}

          <Col md={6} xxl={3}>
            <MarketShare
              data={numUniversities.cities}
              title={'Por Ciudad'}
              radius={['100%', '87%']}
              setColumns={setColumns}
              allStudents={allStudents}
              setallStudents={setallStudents}
              handleEModal={handleEModal}
              setTitle={setTitle}
            />
          </Col>
          <Col md={6} xxl={3}>
            <MarketShare
              data={numUniversities.sectors}
              title={'Por sector'}
              radius={['100%', '87%']}
              setColumns={setColumns}
              allStudents={allStudents}
              setallStudents={setallStudents}
              handleEModal={handleEModal}
              setTitle={setTitle}
            />
          </Col>
          {/* <div style={{ maxHeight: '320px', overflowY: 'auto' }}>
              <ProjectStatisticsCant
                projectsTable={numUniversities.sectors}
                title="Por Sector"
              />
            </div> */}
        </Row>
        <Row className="g-3 mb-3">
          <Alert.Heading>Número de Programas</Alert.Heading>
          <Col>
            <UniversityProjects
              data={numPrograms.cities}
              title={`Según Ciudad`}
              icon={`user-graduate`}
              setColumns={setColumns}
              allStudents={allStudents}
              setallStudents={setallStudents}
              handleEModal={handleEModal}
              setTitle={setTitle}
            />
          </Col>
          <Col>
            <UniversityProjects
              data={numPrograms.modalitys}
              title={`Según Modalidad`}
              icon={`user-graduate`}
              setColumns={setColumns}
              allStudents={allStudents}
              setallStudents={setallStudents}
              handleEModal={handleEModal}
              setTitle={setTitle}
            />
          </Col>
          <Col>
            {/* <UniversityProjects
              data={numPrograms.levels}
              title={`Según Nivel Academico`}
              icon={`user-graduate`}
            /> */}
            <WeeklyGoals data={numPrograms.levels} />
          </Col>
        </Row>
        <Row className="g-3 mb-3">
          <Col>
            <UniversityProjects
              data={numPrograms.universities}
              title={`Según Universidad`}
              icon={`user-graduate`}
              setColumns={setColumns}
              allStudents={allStudents}
              setallStudents={setallStudents}
              handleEModal={handleEModal}
              setTitle={setTitle}
            />
          </Col>
          <Col>
            <UniversityProjects
              data={numPrograms.education_levels}
              title={`Según Nivel de Formación`}
              icon={`user-graduate`}
              setColumns={setColumns}
              allStudents={allStudents}
              setallStudents={setallStudents}
              handleEModal={handleEModal}
              setTitle={setTitle}
            />
          </Col>
        </Row>
        <EModal
          form_={
            <TableModal items={allStudents} title={title} columns={columns} />
          }
          title={''}
          modalSize={'lg'}
          modal={emodal}
          handleModal={handleEModal}
        />
      </>
    );
};

export default IES;
