import React, { useState, useEffect } from 'react';
import { Row, Col, Alert } from 'react-bootstrap';
import { useDefault } from 'hooks';
import BackDrop from 'components/templates/BackDrop';
import UniversityProjects from 'components/dashboards/default/UniversityProjects';
import { EModal } from 'components/templates/Modal';
import TableModal from 'components/dashboards/futura/TableModal';
import TopLeads from 'components/dashboards/default/TopLeads';

const NPS = () => {
  const { emodal, handleEModal, list } = useDefault();
  const [numCities, setNumCities] = useState([]);
  const [numUniversities, setNumUniversities] = useState([]);
  const [numDimension, setNumDimension] = useState([]);
  const [numFactors, setNumFactors] = useState([]);
  const [numModality, setNumModality] = useState([]);
  const [numLevel, setNumLevel] = useState([]);
  const [level, setLevel] = useState([]);
  const [modality, setModality] = useState([]);
  const [columns, setColumns] = useState([
    {
      accessor: 'title',
      Header: 'Nombre'
    },
    {
      accessor: 'duration',
      Header: 'Calificación Promedio'
    }
  ]);
  const [allStudents, setallStudents] = useState([]);
  const [title, setTitle] = useState('');

  useEffect(() => {
    getNPS();
  }, []);

  const getNPS = () => {
    list('dashboard_nps/cal_prom_answers').then(data => {
      if (data) {
        setNumCities(data.cities);
        setNumUniversities(data.universities);
        setNumDimension(data.dimensions);
        setNumFactors(data.factors);
        setNumLevel(data.levels);
        setNumModality(data.modalitys);
        setLevel(data.levelsGrafic);
        setModality(data.modalitysGrafic);
      }
    });
  };

  if (
    numCities === undefined ||
    Object.keys(numCities).length === 0 ||
    numUniversities === undefined ||
    Object.keys(numUniversities).length === 0 ||
    numDimension === undefined ||
    Object.keys(numDimension).length === 0 ||
    numFactors === undefined ||
    Object.keys(numFactors).length === 0 ||
    numLevel === undefined ||
    Object.keys(numLevel).length === 0 ||
    numModality === undefined ||
    Object.keys(numModality).length === 0
  )
    return <BackDrop param={' '} />;

  // if (numCities?.length > 0 || numUniversities?.length > 0)
  return (
    <>
      <Row className="g-3 mb-3">
        <Alert.Heading>Benchmark</Alert.Heading>
        <Col lg="6">
          <TopLeads data={level} title={'Por Nivel Académico'} />
        </Col>
        <Col lg="6">
          <TopLeads data={modality} title={'Por Modalidad'} />
        </Col>
        {/* <Col lg="6">
          <UniversityProjects
            data={numLevel}
            title={`Por Nivel Académico`}
            icon={`user-graduate`}
            setColumns={setColumns}
            allStudents={allStudents}
            setallStudents={setallStudents}
            handleEModal={handleEModal}
            setTitle={setTitle}
            columns={columns}
          />
        </Col> */}
        {/* <Col lg="6">
          <UniversityProjects
            data={numModality}
            title={`Por Modalidad`}
            icon={`user-graduate`}
            setColumns={setColumns}
            allStudents={allStudents}
            setallStudents={setallStudents}
            handleEModal={handleEModal}
            setTitle={setTitle}
            columns={columns}
          />
        </Col> */}
        <Col lg="6">
          <UniversityProjects
            data={numCities}
            title={`Por Ciudad`}
            icon={`user-graduate`}
            setColumns={setColumns}
            allStudents={allStudents}
            setallStudents={setallStudents}
            handleEModal={handleEModal}
            setTitle={setTitle}
            columns={columns}
          />
        </Col>
        <Col lg="6">
          <UniversityProjects
            data={numUniversities}
            title={`Por Universidad`}
            icon={`user-graduate`}
            setColumns={setColumns}
            allStudents={allStudents}
            setallStudents={setallStudents}
            handleEModal={handleEModal}
            setTitle={setTitle}
            columns={columns}
          />
        </Col>
        <Col lg="6">
          <UniversityProjects
            data={numDimension}
            title={`Por Dimensión`}
            icon={`user-graduate`}
            setColumns={setColumns}
            allStudents={allStudents}
            setallStudents={setallStudents}
            handleEModal={handleEModal}
            setTitle={setTitle}
            columns={columns}
          />
        </Col>
        <Col lg="6">
          <UniversityProjects
            data={numFactors}
            title={`Por Factor`}
            icon={`user-graduate`}
            setColumns={setColumns}
            allStudents={allStudents}
            setallStudents={setallStudents}
            handleEModal={handleEModal}
            setTitle={setTitle}
            columns={columns}
          />
        </Col>
      </Row>
      <EModal
        form_={
          <TableModal items={allStudents} title={title} columns={columns} />
        }
        title={''}
        modalSize={'lg'}
        modal={emodal}
        handleModal={handleEModal}
      />
    </>
  );
};

export default NPS;
