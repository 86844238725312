import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useBank } from 'hooks';
import Buttons from '../templates/Buttons';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

// import AppContext from 'context/Context';
// import { Editor } from '@tinymce/tinymce-react';
// import { getColor } from 'helpers/utils';

const BankRequirementsForm = ({ submit, item, items }) => {
  // const {
  //   config: { isDark, isRTL }
  // } = useContext(AppContext);

  const [availabledMsg, setAvailabledMsg] = useState('');
  const [availabled, setAvailabled] = useState(true);
  const [availabledMsgSelect, setAvailabledMsgSelect] = useState('');
  const [availabledSelect, setAvailabledSelect] = useState(true);
  const [option, setOption] = useState(null);
  const { items: banks } = useBank();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors }
  } = useForm({ defaultValues: { id: null } });

  //*EVENT CLICK SAVE AND UPDATE
  const onSubmit = data => {
    setAvailabledSelect(true);
    setAvailabledMsgSelect('');
    if (option !== null) {
      data.bank = option.value;
      submit(data);
      reset();
    } else {
      setAvailabledSelect(false);
      setAvailabledMsgSelect('debe seleccionar un banco!');
    }
  };

  //* EVENT GET ALL RGISTER
  useEffect(() => {
    if (item._id) {
      setValue('id', item._id);
      setValue('name', item.name);
      const selectedOption = banks.find(find => find._id === item.bank?._id);
      if (selectedOption) {
        setOption({
          value: selectedOption._id,
          label: selectedOption.name + '-' + selectedOption.range?.range
        });
      }
    }
  }, [watch('id')]);

  useEffect(() => {
    if (!item?._id || item?.name !== watch('name')) {
      setAvailabled(true);
      setAvailabledMsg('');
      items.forEach(dat => {
        if (dat.name === watch('name') && dat.bank === watch('bank')) {
          setAvailabled(false);
          setAvailabledMsg('Este nombre está en uso actualmente!');
        }
      });
    }
  }, [watch('name'), watch('bank')]);

  return (
    <Row>
      <Col>
        <Form noValidate onSubmit={handleSubmit(onSubmit)} className="px-1">
          <div id="name-row" className="mb-1">
            <Form.Group>
              <Form.Label className="mb-1">Acerca de (*)</Form.Label>
              <Form.Control
                size="sm"
                as="textarea"
                id="name"
                name="name"
                rows={5}
                placeholder="Acerca de"
                isInvalid={!!errors.name}
                {...register('name', {
                  required: {
                    value: true,
                    message: 'este campo es requerido!'
                  },
                  minLength: {
                    limit: 1,
                    value: 1,
                    message: 'Mínimo 1 carácter!'
                  }
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name && errors.name.message}
              </Form.Control.Feedback>
              {availabled ? null : (
                <h6 className="text-danger fs--2 mt-2 mb-0">{availabledMsg}</h6>
              )}
            </Form.Group>
          </div>
          <div id="bank-row">
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 mt-2">Banco (*)</Form.Label>
              <ReactSelect
                closeMenuOnSelect={true}
                options={banks.map(find => ({
                  value: find._id,
                  label: find.name + '-' + find.range?.range
                }))}
                placeholder="Seleccionar...."
                classNamePrefix="react-select"
                value={option}
                id="bank"
                onChange={option => setOption(option)}
              />
              {availabledSelect ? null : (
                <h6 className="text-danger fs--2 mt-2 mb-0">
                  {availabledMsgSelect}
                </h6>
              )}
            </Form.Group>
          </div>

          <Buttons
            btn={'create'}
            caption={watch('id') ? 'Actualizar Datos' : 'Guardar Datos'}
          />
        </Form>
      </Col>
    </Row>
  );
};

BankRequirementsForm.propTypes = {
  submit: PropTypes.func.isRequired,
  item: PropTypes.object,
  items: PropTypes.array
};

export default BankRequirementsForm;
