import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import validator from 'validator';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useUniversity, useMarksCourses } from 'hooks';

import Buttons from 'components/templates/Buttons';
import ReactSelect from 'react-select';

const ROLE = [
  { value: 1, label: 'super' },
  { value: 2, label: 'admin' },
  { value: 3, label: 'guest' }
];
const CLIENTE = [
  { value: 1, label: 'Universidad' },
  { value: 2, label: 'E-learning' },
  { value: 3, label: 'Banco' },
  { value: 4, label: 'Orientación' },
  { value: 5, label: 'Futura' }
];

const UserForm = ({ submit, item, items }) => {
  const [optionMark, setOptionMark] = useState(null);
  const [optionUniversity, setOptionUniversity] = useState(null);
  const [exist, setExist] = useState(true);
  // const [optionRole, setOptionRole] = useState(null);
  // const [optionClient, setOptionClient] = useState(null);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors }
  } = useForm({ defaultValues: { id: null } });
  const { items: markCourses } = useMarksCourses();
  const { univConv } = useUniversity();

  const onSubmit = data => {
    if (!availabled) {
      document.getElementById('email').focus();
      return;
    }
    if (!strong) {
      document.getElementById('password').focus();
      return;
    }

    delete data.password2;
    data.role = select.label;
    if (optionMark) data.mark = optionMark.value;
    if (selectClient) data.client = selectClient.label;
    if (optionUniversity) data.university_profile = optionUniversity.value;

    submit(data);
    reset();
  };

  //* EVENT GET ALL RGISTER
  useEffect(() => {
    if (item._id) {
      setValue('id', item._id);
      setValue('name', item.name);
      setValue('lastname', item.lastname);
      setValue('email', item.email);
      setValue('password', item.password);
      setValue('password2', item.password);
      // setValue('role', item.role);
      setValue('phone', item.phone);
      // setValue('enabled', item.enabled);
      // setValue('university_profile', item.university_profile?._id);

      if (!item.pt) {
        document.getElementById('email').disabled = true;
        document.getElementById('email').style.cursor = 'no-drop';
        document.getElementById('password-row').style.display = 'none';
        document.getElementById('password2-row').style.display = 'none';
      } else {
        document.getElementById('name-row').style.display = 'none';
        document.getElementById('lastname-row').style.display = 'none';
        document.getElementById('email-row').style.display = 'none';
        document.getElementById('role-row').style.display = 'none';
        document.getElementById('phone-row').style.display = 'none';
        // document.getElementById('enabled-row').style.display = 'none';
        // document.getElementById('university-row').style.display = 'none';
        setExist(false);
      }
      const selectedMark = markCourses.find(
        find => find._id === item.mark?._id
      );
      if (selectedMark) {
        setOptionMark({
          value: selectedMark._id,
          label: selectedMark.name
        });
      }
      const selectedUniversity = univConv.find(
        find => find._id === item.university_profile?._id
      );
      if (selectedUniversity) {
        setOptionUniversity({
          value: selectedUniversity._id,
          label: selectedUniversity.name
        });
      }
      const selectedRole = ROLE.find(find => find.label === item.role);
      if (selectedRole) {
        setSelect({
          value: selectedRole.value,
          label: selectedRole.label
        });
      }
      if (item.client) {
        const selectedClient = CLIENTE.find(find => find.label === item.client);
        if (selectedClient) {
          setSelectClient({
            value: selectedClient.value,
            label: selectedClient.label
          });
        }
      }
    }
  }, [watch('id')]);

  const [errorStrongMsg, setErrorStrongMsg] = useState('');
  const [strong, setStrong] = useState(false);

  useEffect(() => {
    if (watch('password')) {
      if (
        validator.isStrongPassword(watch('password'), {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1
        })
      ) {
        setErrorStrongMsg('tu contraseña es fuerte');
        setStrong(true);
      } else {
        setErrorStrongMsg('tu contraseña es débil');
        setStrong(false);
      }
    } else setErrorStrongMsg('');
  }, [watch('password'), watch('password2')]);

  const [availabledMsg, setAvailabledMsg] = useState('');
  const [availabled, setAvailabled] = useState(true);
  useEffect(() => {
    if (!item?._id || item?.email !== watch('email')) {
      setAvailabled(true);
      setAvailabledMsg('');

      items.forEach(dat => {
        if (dat.email === watch('email')) {
          setAvailabled(false);
          setAvailabledMsg('Este correo esta en uso actualmente!');
        }
      });
    }

    if (
      watch('email') != '' &&
      !/^[a-zA-Z0-9.]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(watch('email'))
    ) {
      setAvailabled(false);
      setAvailabledMsg('Escriba una dirercción de correo válida!');
    }
  }, [watch('email')]);
  const [select, setSelect] = useState('');
  const [selectClient, setSelectClient] = useState('');

  const handleChangeSelect = option => {
    if (option.label === 'super') {
      setSelectClient('');
    }
    setSelect(option);
  };
  const handleChangeSelectClient = optionMark => {
    setSelectClient(optionMark);
  };
  return (
    <Row>
      <Col>
        <Form noValidate onSubmit={handleSubmit(onSubmit)} className="px-1">
          <div id="name-row">
            <Form.Group>
              <Form.Label className="mb-1">Nombre</Form.Label>
              <Form.Control
                size="sm"
                type="text"
                id="name"
                name="name"
                placeholder="Nombre"
                isInvalid={!!errors.name}
                {...register('name', {
                  required: {
                    value: true,
                    message: 'este campo es requerido!'
                  },
                  minLength: {
                    limit: 4,
                    value: 4,
                    message: 'min. caracteres: 4!'
                  },
                  pattern: {
                    value: /^[A-Za-z .]+$/i,
                    message: 'no se permiten caracteres numéricos!'
                  }
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name && errors.name.message}
              </Form.Control.Feedback>
            </Form.Group>
          </div>

          <div id="lastname-row">
            <Form.Group>
              <Form.Label className="mb-1 mt-2">Apellidos</Form.Label>
              <Form.Control
                size="sm"
                type="text"
                id="lastname"
                name="lastname"
                placeholder="Apellidos"
                isInvalid={!!errors.lastname}
                {...register('lastname', {
                  required: {
                    value: true,
                    message: 'este campo es requerido!'
                  },
                  minLength: {
                    limit: 4,
                    value: 4,
                    message: 'min. caracteres: 10!'
                  },
                  pattern: {
                    value: /^[A-Za-z .]+$/i,
                    message: 'no se permiten caracteres numéricos!'
                  }
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.lastname && errors.lastname.message}
              </Form.Control.Feedback>
            </Form.Group>
          </div>

          <div id="email-row">
            <Form.Group>
              <Form.Label className="mb-1 mt-2">Correo</Form.Label>
              <Form.Control
                size="sm"
                type="email"
                id="email"
                name="email"
                placeholder="Dirección electrónica"
                isInvalid={!!errors.email}
                {...register('email', {
                  required: {
                    value: true,
                    message: 'este campo es requerido!'
                  },
                  minLength: {
                    value: 10,
                    message: 'min. caracteres: 10!'
                  }
                })}
              />

              <Form.Control.Feedback type="invalid">
                {errors.email && errors.email.message}
              </Form.Control.Feedback>
              {availabled ? null : (
                <h6 className="text-danger fs--2 mt-2 mb-0">{availabledMsg}</h6>
              )}
            </Form.Group>
          </div>

          <div id="password-row">
            <Form.Group>
              <Form.Label className="mb-1 mt-2">Contraseña</Form.Label>
              <Form.Control
                size="sm"
                type="password"
                name="password"
                id="password"
                placeholder="Contraseña"
                isInvalid={!!errors.password}
                {...register('password', {
                  required: {
                    value: true,
                    message: 'este campo es requerido!'
                  },
                  minLength: {
                    value: 8,
                    message: 'min. caracteres: 8!'
                  }
                })}
              />

              {errors.password && !errorStrongMsg && (
                <Form.Control.Feedback type="invalid">
                  {errors.password.message}
                </Form.Control.Feedback>
              )}
              {errorStrongMsg === '' ? (
                !errors.password && (
                  <h6 className="text-500 fs--2 mt-2 mb-0">
                    Requerido (caracteres: 8, mayúscula: 1, minúsculas: 1,
                    número: 1, símbolo: 1)
                  </h6>
                )
              ) : (
                <h6
                  className={`${
                    strong ? 'text-primary' : 'text-danger'
                  } fs--2 mt-2 mb-0`}
                >
                  {errorStrongMsg}
                </h6>
              )}
            </Form.Group>
          </div>

          <div id="password2-row" className="mb-2">
            <Form.Group>
              <Form.Label className="mb-1 mt-2">
                Confirmar Contraseña
              </Form.Label>
              <Form.Control
                size="sm"
                type="password"
                placeholder="Confirmar contraseña"
                id="password2"
                name="password2"
                isInvalid={!!errors.password2}
                {...register('password2', {
                  required: {
                    value: true,
                    message: 'este campo es requerido!'
                  },
                  minLength: {
                    value: 8,
                    message: 'min. caracteres: 8!'
                  },
                  validate: value =>
                    value === watch('password') ||
                    'las contraseñas no coinciden!'
                })}
              />

              <Form.Control.Feedback type="invalid">
                {errors.password2 && errors.password2.message}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <div id="phone-row">
            <Form.Group>
              <Form.Label className="mb-1 mt-2">Teléfono</Form.Label>
              <Form.Control
                size="sm"
                type="text"
                id="phone"
                name="phone"
                placeholder="Teléfono"
                isInvalid={!!errors.phone}
                {...register('phone', {
                  required: {
                    value: true,
                    message: 'este campo es requerido!'
                  }
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.naphoneme && errors.phone.message}
              </Form.Control.Feedback>
            </Form.Group>
          </div>

          <div id="role-row">
            <Form.Group className="mb-1">
              <Form.Label className="mb-1 mt-2">Rol (*)</Form.Label>
              <ReactSelect
                closeMenuOnSelect={true}
                options={ROLE.map(find => ({
                  value: find.value,
                  label: find.label
                }))}
                placeholder="Seleccionar...."
                classNamePrefix="react-select"
                value={select}
                id="mark"
                onChange={option => handleChangeSelect(option)}
              />
            </Form.Group>
          </div>
          {select?.label === 'admin' && exist && (
            <div id="client-row">
              <Form.Group className="mb-1">
                <Form.Label className="mb-1 mt-2">Cliente (*)</Form.Label>
                <ReactSelect
                  closeMenuOnSelect={true}
                  options={CLIENTE.map(find => ({
                    value: find.value,
                    label: find.label
                  }))}
                  placeholder="Seleccionar...."
                  classNamePrefix="react-select"
                  value={selectClient}
                  id="client"
                  onChange={option => handleChangeSelectClient(option)}
                />
              </Form.Group>
            </div>
          )}
          {selectClient?.label === 'Universidad' && exist && (
            <div id="university-row">
              <Form.Group className="mb-1">
                <Form.Label className="mb-1 mt-2">
                  Universidad Convenio (*)
                </Form.Label>
                <ReactSelect
                  closeMenuOnSelect={true}
                  options={univConv.map(find => ({
                    value: find._id,
                    label: find.name
                  }))}
                  placeholder="Seleccionar...."
                  classNamePrefix="react-select"
                  value={optionUniversity}
                  id="university"
                  onChange={option => setOptionUniversity(option)}
                />
              </Form.Group>
            </div>
          )}
          {selectClient?.label === 'E-learning' && exist && (
            <div id="mark-row">
              <Form.Group className="mb-1">
                <Form.Label className="mb-1 mt-2">Marca (*)</Form.Label>
                <ReactSelect
                  closeMenuOnSelect={true}
                  options={markCourses.map(find => ({
                    value: find._id,
                    label: find.name
                  }))}
                  placeholder="Seleccionar...."
                  classNamePrefix="react-select"
                  value={optionMark}
                  id="mark"
                  onChange={optionMark => setOptionMark(optionMark)}
                />
              </Form.Group>
            </div>
          )}

          {/* <div id="enabled-row">
            <Form.Group>
              <Form.Check
                size="sm"
                className="mb-1 mt-2"
                type="checkbox"
                id="enabled"
                label={watch('enabled') ? 'Habilitado' : 'Deshabilitado'}
                defaultChecked
                {...register('enabled')}
              />
            </Form.Group>
          </div> */}

          {/* <Button variant="primary" type="submit">
          Submit
        </Button> */}
          <Buttons
            btn={'create'}
            caption={watch('id') ? 'Actualizar Datos' : 'Guardar Datos'}
          />
        </Form>
      </Col>
    </Row>
  );
};

UserForm.propTypes = {
  submit: PropTypes.func.isRequired,
  item: PropTypes.object,
  items: PropTypes.array
};

export default UserForm;
