import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import General from 'components/dashboards/survey/General';
import Top20Program from 'components/dashboards/survey/Top20Program';
import Top20University from 'components/dashboards/survey/Top20University';
import ConfigSurveyWidgets from 'components/dashboards/survey/ConfigStats';

import { useDefault, useSurvey, http } from 'hooks';

import headers from 'config/header';
import { EModal } from 'components/templates/Modal';
import { Card, Alert, Col, Row } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import CountUp from 'react-countup';

const Survey = () => {
  const { emodal, handleEModal, cmodal, handleCModal } = useDefault();
  const { quizTotal, verified, unverified, publish, noPublish } = useSurvey();
  const [general, setGeneral] = useState([]);
  const [program, setProgram] = useState([]);
  const [cities, setCities] = useState(null);
  const [university, setUniversity] = useState([]);

  useEffect(() => {
    const General = async () => {
      try {
        let config = headers();

        if (config.headers.token?.length > 50) {
          const { data } = await http('answers/general', config);
          setGeneral(data);
        } else
          !config.headers.token?.length == 11 && toast.error('Invalid token!');
      } catch (e) {
        console.log(e);
      }
    };

    const List_Top20Program = async () => {
      try {
        let config = headers();

        if (config.headers.token?.length > 50) {
          const { data } = await http('answers/top_answers_programs', config);
          setProgram(data);
        } else
          !config.headers.token?.length == 11 && toast.error('Invalid token!');
      } catch (e) {
        console.log(e);
      }
    };

    const List_Top20University = async () => {
      try {
        let config = headers();

        if (config.headers.token?.length > 50) {
          const { data } = await http('answers/top_answers_university', config);
          setUniversity(data);
        } else
          !config.headers.token?.length == 11 && toast.error('Invalid token!');
      } catch (e) {
        console.log(e);
      }
    };
    const List_TopCities = async () => {
      try {
        let config = headers();

        if (config.headers.token?.length > 50) {
          const { data } = await http('answers/top_answers_cities', config);
          console.log(data[0]['cantidad']);
          setCities(data);
        } else
          !config.headers.token?.length == 11 && toast.error('Invalid token!');
      } catch (e) {
        console.log(e);
      }
    };

    General();
    List_Top20Program();
    List_Top20University();
    List_TopCities();
  }, []);
  if (cities !== null)
    return (
      <>
        <Row className="g-3">
          <Col xxl={12}>
            <ConfigSurveyWidgets
              items={[
                {
                  id: 1,
                  title: 'Encuestas',
                  amount: quizTotal,
                  color: 'primary',
                  icon: 'poll',
                  data: [220, 230, 150, 165, 250, 160, 70, 160]
                },
                {
                  id: 2,
                  title: 'Aprobadas',
                  amount: publish,
                  color: 'success',
                  icon: 'check',
                  data: [220, 230, 150, 175, 200, 170, 70, 160]
                },
                {
                  id: 3,
                  title: 'Pendientes',
                  amount: noPublish,
                  color: 'warning',
                  icon: 'clock'
                },
                {
                  id: 4,
                  title: 'Usuarios Verificados',
                  amount: verified,
                  color: 'success',
                  icon: 'check'
                },
                {
                  id: 5,
                  title: 'Usuarios Pendientes',
                  amount: unverified,
                  color: 'danger',
                  icon: 'clock'
                }
              ]}
            />
          </Col>
        </Row>
        <Row className="g-3">
          <Col xxl={12}>
            <General data={general} />
          </Col>
        </Row>

        <Row className="g-3 mb-3">
          <Col lg={7}>
            <Top20Program data={program} toShow={7} />
          </Col>
          <Col lg={5}>
            <Top20University data={university} toShow={5} />
          </Col>
        </Row>
        <Row className="g-3 mb-3">
          <Alert.Heading>
            Ciudades con mayor y menor número de Encuestas
          </Alert.Heading>
          <Col lg={6}>
            <Card className="h-100">
              <Card.Body>
                <Row className="flex-between-center">
                  <Col className="d-md-flex d-lg-block flex-between-center">
                    <h6 className="mb-md-0 mb-lg-2">Mayor</h6>
                    <SoftBadge bg="success" pill>
                      <FontAwesomeIcon icon="caret-up" />
                      {cities[0]['ciudad']}
                    </SoftBadge>
                  </Col>
                  <Col xs="auto">
                    <h4 className="fs-3 fw-normal text-700">
                      <CountUp
                        start={0}
                        end={cities[0]['cantidad']}
                        duration={2.75}
                        decimals={0}
                        decimal="."
                      />
                    </h4>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6}>
            <Card className="h-100">
              <Card.Body>
                <Row className="flex-between-center">
                  <Col className="d-md-flex d-lg-block flex-between-center">
                    <h6 className="mb-md-0 mb-lg-2">Menor</h6>
                    <SoftBadge bg="danger" pill>
                      <FontAwesomeIcon icon="caret-down" />
                      {cities[cities.length - 1]['ciudad']}
                    </SoftBadge>
                  </Col>
                  <Col xs="auto">
                    <h4 className="fs-3 fw-normal text-700">
                      <CountUp
                        start={0}
                        end={cities[cities.length - 1]['cantidad']}
                        duration={2.75}
                        decimals={0}
                        decimal="."
                      />
                    </h4>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <EModal
          form_={<Top20Program data={program} toShow={'f'} />}
          title={''}
          modalSize={'lg'}
          modal={emodal}
          handleModal={handleEModal}
        />

        <EModal
          form_={<Top20University data={university} toShow={'f'} />}
          title={''}
          modalSize={'lg'}
          modal={cmodal}
          handleModal={handleCModal}
        />
      </>
    );
};

export default Survey;
