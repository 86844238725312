import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useRequirements } from 'hooks';
import Buttons from '../templates/Buttons';
import PropTypes from 'prop-types';
const TYPE = ['Pregrado', 'Posgrado'];
const RequirementsForm = ({ submit, item, items }) => {
  const [availabledMsg, setAvailabledMsg] = useState('');
  const [availabled, setAvailabled] = useState(true);
  const { requirements } = useRequirements();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors }
  } = useForm({ defaultValues: { id: null } });

  //*EVENT CLICK SAVE AND UPDATE
  const onSubmit = data => {
    const formData = new FormData();
    if (item._id) {
      formData.append('_id', item._id);
    }
    if (availabled) {
      formData.append('requirements', requirements._id);
      formData.append('name', data.name);
      formData.append('level', data.level);
      submit(formData);
      reset();
    }
  };

  //* EVENT GET ALL RGISTER
  useEffect(() => {
    const fetchLogo = async () => {
      try {
        if (item._id) {
          setValue('id', item._id);
          setValue('name', item.name);
          setValue('level', item.level);
        }
      } catch (error) {
        console.error('Error al obtener el archivo:', error);
      }
    };

    fetchLogo();
  }, [watch('id')]);

  useEffect(() => {
    if (!item?._id || item?.name !== watch('name')) {
      setAvailabled(true);
      setAvailabledMsg('');
      if (items !== null) {
        items.forEach(dat => {
          if (dat.name === watch('name')) {
            setAvailabled(false);
            setAvailabledMsg('Este nombre está en uso actualmente!');
          }
        });
      }
    }
  }, [watch('name')]);

  return (
    <Row>
      <Col>
        <Form noValidate onSubmit={handleSubmit(onSubmit)} className="px-1">
          <div id="name-row" className="mb-3">
            <Form.Group>
              <Form.Label className="mb-1">Nombre (*)</Form.Label>
              <Form.Control
                size="sm"
                as="textarea"
                id="name"
                name="name"
                placeholder="Descripción del Requisito"
                rows={5}
                isInvalid={!!errors.name}
                {...register('name', {
                  required: {
                    value: true,
                    message: 'este campo es requerido!'
                  },
                  minLength: {
                    limit: 1,
                    value: 1,
                    message: 'Mínimo 1 carácter!'
                  }
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name && errors.name.message}
              </Form.Control.Feedback>
              {availabled ? null : (
                <h6 className="text-danger fs--2 mt-2 mb-0">{availabledMsg}</h6>
              )}
            </Form.Group>
          </div>
          <div id="level-row" className="mb-3">
            <Form.Group>
              <Form.Label className="mb-1 mt-2">Nivel (*)</Form.Label>
              <Form.Select
                size="sm"
                id="level"
                isInvalid={!!errors.type}
                {...register('level', { required: true })}
              >
                <option value="">--Seleccionar--</option>
                {TYPE.map(dat => (
                  <option key={dat}>{dat}</option>
                ))}
              </Form.Select>

              <Form.Control.Feedback type="invalid">
                {errors.level && errors.level.message}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <Buttons
            btn={'create'}
            caption={watch('id') ? 'Actualizar Datos' : 'Guardar Datos'}
          />
        </Form>
      </Col>
    </Row>
  );
};

RequirementsForm.propTypes = {
  submit: PropTypes.func.isRequired,
  item: PropTypes.object,
  items: PropTypes.array
};

export default RequirementsForm;
