import React, { useEffect, useState, createContext } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import headers from 'config/header';

import {
  useDefault,
  http,
  useCourse,
  usePrograms
  // useBootCamps,
  // useMentoring
} from 'hooks';
import { useLocation } from 'react-router-dom';

import Form_ from 'components/forms/ScholarshipForm';

import Buttons from 'components/templates/Buttons';
import Flex from 'components/common/Flex';

const ScholarshipContext = createContext();

const ScholarshipProvider = ({ children }) => {
  const location = useLocation();
  const splitted = location.pathname.split('-');
  let result = splitted[splitted.length - 1];
  if (result === 'mentoring') {
    result = 'mentoring';
  } else {
    result = result + 's';
  }

  const [items, setItems] = useState([]);
  let object;
  const {
    item: scholarship,
    name,
    setItems: setCourses,
    items: courses
  } = useCourse();
  const {
    item: scholarshipProgram,
    name: nameProgram,
    setItems: setPrograms,
    items: programs
  } = usePrograms();
  // const {
  //   item: scholarshipBootCamp,
  //   name: nameBootCamp,
  //   setItems: setBootCamps,
  //   items: bootCamps
  // } = useBootCamps();
  // const {
  //   item: scholarshipMentoring,
  //   name: nameMentoring,
  //   setItems: setMentoring,
  //   items: mentoring
  // } = useMentoring();
  if (result === 'courses') {
    object = scholarship;
  } else if (result === 'programs') {
    object = scholarshipProgram;
  }
  // else if (result === 'mentoring') {
  //   object = scholarshipMentoring;
  // } else if (result === 'bootcamps') {
  //   object = scholarshipBootCamp;
  // }

  useEffect(() => {
    if (scholarship) {
      setItems(scholarship.scholarships);
    }
  }, [scholarship]);
  useEffect(() => {
    if (scholarshipProgram) {
      setItems(scholarshipProgram.scholarships);
    }
  }, [scholarshipProgram]);
  // useEffect(() => {
  //   if (scholarshipBootCamp) {
  //     setItems(scholarshipBootCamp.scholarships);
  //   }
  // }, [scholarshipBootCamp]);
  // useEffect(() => {
  //   if (scholarshipMentoring) {
  //     setItems(scholarshipMentoring.scholarships);
  //   }
  // }, [scholarshipMentoring]);

  const { update, add, remove, handleEModal, handleDModal } = useDefault();

  const [item, setItem] = useState({});

  let columns;

  columns = [
    {
      accessor: 'name',
      Header: 'Nombre'
    },
    // {
    //   accessor: `type`,
    //   Header: 'Tipo'
    // },
    {
      accessor: `about`,
      Header: 'Acerca de',
      Cell: rowData => {
        const { about } = rowData.row.original;
        return (
          <div className="d-flex align-items-center">
            <div className="ms-2 text-truncate" style={{ maxWidth: '400px' }}>
              {about}
            </div>
          </div>
        );
      }
    },
    {
      accessor: 'btn',
      Header: 'Acciones',
      headerProps: { style: { width: '95px' } },
      Cell: rowData => {
        return (
          <Flex className="gap-1 mt-1.5">
            <Buttons
              btn={'update'}
              handleEvent={() => {
                rowData.row.original.pt = false;
                setItem(rowData.row.original);
                handleEModal();
              }}
            />
            <Buttons
              btn={'delete'}
              handleEvent={() => {
                setItem(rowData.row.original);
                handleDModal();
              }}
            />
          </Flex>
        );
      }
    }
  ];

  const submit = object_ => {
    object_.scholarship = object._id;
    update(`${result}/updateScholarship`, object_).then(res => {
      res && setItems(res.scholarships);
      if (result === 'courses') {
        setCourses(
          courses.map(stateObj => (stateObj._id === res._id ? res : stateObj))
        );
      } else if (result === 'programs') {
        setPrograms(
          programs.map(stateObj => (stateObj._id === res._id ? res : stateObj))
        );
      }
      // else if (result === 'mentoring') {
      //   setMentoring(
      //     mentoring.map(stateObj => (stateObj._id === res._id ? res : stateObj))
      //   );
      // } else if (result === 'bootcamps') {
      //   setBootCamps(
      //     bootCamps.map(stateObj => (stateObj._id === res._id ? res : stateObj))
      //   );
      // }
      setItem({});
    });
  };

  const removeOne = async () => {
    const deleted = {
      id: item._id,
      scholarship: object._id
    };
    try {
      let config = headers();

      if (config.headers.token.length > 50) {
        const { data } = await http.put(
          `${result}/deleteScholarship`,
          deleted,
          config
        );
        //! Async width .State.
        setItems(data.data.scholarships);
        const res = data.data;
        if (result === 'courses') {
          setCourses(
            courses.map(stateObj => (stateObj._id === res._id ? res : stateObj))
          );
        } else if (result === 'programs') {
          setPrograms(
            programs.map(stateObj =>
              stateObj._id === res._id ? res : stateObj
            )
          );
        }
        // else if (result === 'mentoring') {
        //   setMentoring(
        //     mentoring.map(stateObj =>
        //       stateObj._id === res._id ? res : stateObj
        //     )
        //   );
        // } else if (result === 'bootcamps') {
        //   setBootCamps(
        //     bootCamps.map(stateObj =>
        //       stateObj._id === res._id ? res : stateObj
        //     )
        //   );
        // }
        toast.success('Registro eliminado correctamente!');
      } else toast.error('Invalid token!');
    } catch (e) {
      console.log(e);
    } finally {
      handleDModal();
      setItem({});
    }
    // update(`${result}/deleteScholarship`, deleted).then(() => {
    //   setItems(items.filter(stateObj => stateObj._id !== item._id));
    //   setItem({});
    // });
  };

  return (
    <ScholarshipContext.Provider
      value={{
        removeOne,
        update,
        add,
        remove,
        name,
        nameProgram,
        // nameBootCamp,
        // nameMentoring,
        submit,
        item,
        setItem,
        columns,
        items,
        Form_,
        confirm: false
      }}
    >
      {children}
    </ScholarshipContext.Provider>
  );
};

ScholarshipProvider.propTypes = {
  children: PropTypes.node
};

export { ScholarshipProvider };
export default ScholarshipContext;
