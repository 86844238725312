import React, { useState, useEffect, useRef } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

export function useIdle({ signOut, idleTime }) {
  const [isIdle, setIsIdle] = useState(false);
  const s = useRef(null);
  const st = useRef(null);

  const handleOnIdle = () => {
    let i = 10;
    const toastId = toast.error(
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>{`Tu sesión se cerrará por inactividad en ${i} segundos!`}</span>
        <Button
          style={{ fontSize: '12px', whiteSpace: 'nowrap' }}
          size="sm"
          variant="warning"
          className="me-2 rounded-pill me-1 mb-1"
          onClick={() => {
            if (s.current) clearInterval(s.current);
            if (st.current) clearTimeout(st.current);
            toast.dismiss();
          }}
        >
          Cancelar
        </Button>
      </div>,
      { id: toastId }
    );

    s.current = setInterval(() => {
      i--;
      toast.update(toastId, {
        render: (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>{`Tu sesión se cerrará por inactividad en ${i} segundos!`}</span>
            <Button
              style={{ fontSize: '12px', whiteSpace: 'nowrap' }}
              size="sm"
              variant="warning"
              className="me-2 rounded-pill me-1 mb-1"
              onClick={() => {
                if (s.current) clearInterval(s.current);
                if (st.current) clearTimeout(st.current);
                toast.dismiss();
              }}
            >
              Cancelar
            </Button>
          </div>
        )
      });

      if (i === 0) {
        if (s.current) clearInterval(s.current);
        toast.dismiss();
      }
    }, 1000);

    st.current = setTimeout(() => {
      signOutIdleTimer();
    }, 10000);
  };

  const signOutIdleTimer = () => {
    setIsIdle(true);
    localStorage.setItem('idle', 'true');
    console.log(`Last Active ${getLastActiveTime()}`);
    signOut(`Last Active ${getLastActiveTime()}`);
    if (s.current) clearInterval(s.current); // Limpia el intervalo
    if (st.current) clearTimeout(st.current); // Limpia el timeout
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * idleTime,
    onIdle: handleOnIdle,
    debounce: 500
  });

  useEffect(() => {
    return () => {
      if (s.current) clearInterval(s.current);
      if (st.current) clearTimeout(st.current);
    };
  }, []);

  return {
    getRemainingTime,
    getLastActiveTime,
    isIdle
  };
}

useIdle.propTypes = {
  signOut: PropTypes.func.isRequired,
  idleTime: PropTypes.number.isRequired
};
