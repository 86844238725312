import React from 'react';
import PropTypes from 'prop-types';
import { useDefault } from 'hooks';
import IconButton from 'components/common/IconButton';
import { Button, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';

const Buttons = ({ btn, caption, handleEvent }) => {
  const { loadBotton } = useDefault();

  if (btn === 'add') {
    return (
      <Button
        size="sm"
        //variant="falcon-default" //blanco
        variant="primary"
        className="rounded-pill me-1 mb-2 py-1"
        onClick={handleEvent}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          width={18}
          className="mr-1 mb-1"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
          />
        </svg>
        Adicionar {caption}
      </Button>
    );
  }
  if (btn === 'return') {
    return (
      <Button
        size="sm"
        //variant="falcon-default" //blanco
        variant="primary"
        className="rounded-pill me-1 mb-2 py-1"
        onClick={handleEvent}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          width={18}
          className="mr-1 mb-1"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M15 19l-7-7 7-7"
          />
        </svg>
        Regresar
      </Button>
    );
  }

  if (btn === 'create') {
    return (
      <Button
        type="submit"
        size="sm"
        //variant="outline-primary"
        variant="primary"
        disabled={loadBotton}
        className="rounded-pill me-1 mb-3 w-100 d-inline-flex flex-center gap-1 p-1"
      >
        {loadBotton && (
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        )}
        {loadBotton ? 'Cargando…' : caption}
      </Button>
    );
  }

  if (btn === 'change') {
    let d = Math.random();
    return (
      <OverlayTrigger
        overlay={
          <Tooltip style={{ position: 'fixed' }} id={d}>
            Cambiar Contraseña
          </Tooltip>
        }
      >
        <div
          className="text-800 hover:text-black cursor-pointer"
          onClick={handleEvent}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            width={20}
          >
            <path
              fillRule="evenodd"
              d="M4.5 12a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm6 0a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm6 0a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </OverlayTrigger>
    );
  }
  if (btn === 'options') {
    let d = Math.random();
    return (
      <OverlayTrigger
        overlay={
          <Tooltip style={{ position: 'fixed' }} id={d}>
            Ver más
          </Tooltip>
        }
      >
        <div
          className="text-800 hover:text-black cursor-pointer"
          onClick={handleEvent}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            width={20}
          >
            <path
              fillRule="evenodd"
              d="M4.5 12a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm6 0a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm6 0a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </OverlayTrigger>
    );
  }

  if (btn === 'update') {
    let d = Math.random();
    return (
      <OverlayTrigger
        overlay={
          <Tooltip style={{ position: 'fixed' }} id={d}>
            Editar
          </Tooltip>
        }
      >
        <div
          className="text-gray-400 hover:text-black cursor-pointer"
          onClick={handleEvent}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            width={15}
          >
            <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z" />
          </svg>
        </div>
      </OverlayTrigger>
    );
  }

  if (btn === 'delete') {
    let d = Math.random();
    return (
      <OverlayTrigger
        overlay={
          <Tooltip style={{ position: 'fixed' }} id={d}>
            Remover
          </Tooltip>
        }
      >
        <div
          className="text-gray-400 hover:text-black cursor-pointer"
          onClick={handleEvent}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            width={15}
          >
            <path
              fillRule="evenodd"
              d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </OverlayTrigger>
    );
  }

  if (btn === 'search') {
    return (
      <IconButton
        className="rounded-pill"
        variant="falcon-default"
        size="sm"
        icon="search"
        transform="shrink-3"
        onClick={handleEvent}
      ></IconButton>
    );
  }
};

Buttons.propTypes = {
  btn: PropTypes.string.isRequired,
  caption: PropTypes.string,
  handleEvent: PropTypes.func
};

export default Buttons;
