import React, { useEffect, useState, createContext } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import headers from 'config/header';

import { useDefault, useUniversity, useUniversityProfile, http } from 'hooks';

import Form_ from 'components/forms/CampusForm';

import Buttons from 'components/templates/Buttons';
import Flex from 'components/common/Flex';

const CampusContext = createContext();

const CampusProvider = ({ children }) => {
  const [items, setItems] = useState([]);
  let routes = '';
  let campus = null;
  let setCampus = null;
  let list = null;
  let name = null;
  const {
    item: campus1,
    setItems: setCampus1,
    items: list1,
    name: name1
  } = useUniversity();
  const {
    item: campus2,
    setItems: setCampus2,
    items: list2,
    name: name2
  } = useUniversityProfile();
  if (campus1) {
    campus = campus1;
    setCampus = setCampus1;
    list = list1;
    name = name1;
    routes = 'universities';
  } else {
    if (campus2) {
      campus = campus2;
      setCampus = setCampus2;
      list = list2;
      name = name2;
      routes = 'university';
    }
  }

  useEffect(() => {
    if (campus) {
      setItems(campus.campus);
    }
  }, [campus]);

  const { update, add, remove, handleEModal, handleDModal } = useDefault();

  const [item, setItem] = useState({});

  let columns;

  columns = [
    {
      accessor: 'name',
      Header: 'Nombre'
    },
    {
      accessor: 'btn',
      Header: 'Acciones',
      headerProps: { style: { width: '95px' } },
      Cell: rowData => {
        return (
          <Flex className="gap-1 mt-1.5">
            <Buttons
              btn={'update'}
              handleEvent={() => {
                rowData.row.original.pt = false;
                setItem(rowData.row.original);
                handleEModal();
              }}
            />
            <Buttons
              btn={'delete'}
              handleEvent={() => {
                setItem(rowData.row.original);
                handleDModal();
              }}
            />
          </Flex>
        );
      }
    }
  ];

  const submit = object_ => {
    object_.campus = campus._id;
    update(`universities/updateCampus`, object_).then(res => {
      res && setItems(res.campus);
      setCampus(
        list.map(stateObj => (stateObj._id === res._id ? res : stateObj))
      );
      setItem({});
    });
  };

  const removeOne = async () => {
    const deleted = {
      id: item._id,
      campus: campus._id
    };
    try {
      let config = headers();

      if (config.headers.token.length > 50) {
        const { data } = await http.put(
          `universities/deleteCampus`,
          deleted,
          config
        );
        //! Async width .State.
        setItems(data.data.campus);
        setCampus(
          list.map(stateObj =>
            stateObj._id === data.data._id ? data.data : stateObj
          )
        );
        toast.success('Registro eliminado correctamente!');
      } else toast.error('Invalid token!');
    } catch (e) {
      console.log(e);
    } finally {
      handleDModal();
      setItem({});
    }
  };

  return (
    <CampusContext.Provider
      value={{
        removeOne,
        update,
        add,
        remove,
        name,
        submit,
        item,
        setItem,
        columns,
        items,
        routes,
        Form_,
        caption: 'Sede',
        captions: 'Sedes',
        confirm: false
      }}
    >
      {children}
    </CampusContext.Provider>
  );
};

CampusProvider.propTypes = {
  children: PropTypes.node
};

export { CampusProvider };
export default CampusContext;
