import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Navigate, useLocation } from 'react-router-dom';
import CourseFilters from 'components/dashboards/e-learning/CourseFilters';
import CampaignPerfomance from 'components/dashboards/analytics/campaign-perfomance/CampaignPerfomance';
import PropTypes from 'prop-types';
// import { totalSales } from 'data/dashboard/default';
import TopLeads from 'components/dashboards/default/TopLeads';
import BackDrop from 'components/templates/BackDrop';
import TableModal from 'components/dashboards/futura/TableModal';
import { EModal } from 'components/templates/Modal';
import { useDefault } from 'hooks';
import Datatable from 'components/templates/datatable/Datatable';
import Avatar from 'components/common/Avatar';

const Pricing = ({ authorized }) => {
  if (!authorized) return <Navigate to="/dashboard" />;
  const location = useLocation();
  const { list, emodal, handleEModal } = useDefault();

  const [levels, setLevels] = useState([]);
  const [modalitys, setModalitys] = useState([]);
  const [cities, setCities] = useState([]);
  const [universities, setUniversities] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [listPrograms, setListPrograms] = useState([]);

  const [columns, setColumns] = useState([]);
  const [allStudents, setallStudents] = useState([]);
  const [title, setTitle] = useState('');

  const [items, setItems] = useState();
  const [priceUniversities, setPriceUniversities] = useState([]);
  const [priceLevels, setPriceLevels] = useState([]);
  const [priceModalitys, setPriceModalitys] = useState([]);
  const [priceLevelsAll, setPriceLevelsAll] = useState([]);
  const [priceModalitysAll, setPriceModalitysAll] = useState([]);
  const [priceCities, setPriceCities] = useState([]);
  const [pricePrograms, setPricePrograms] = useState([]);

  useEffect(() => {
    if (location.pathname === '/dashboard/pricing') getUser_university();
  }, [location]);

  const getUser_university = () => {
    list('dashboard_university/data_university_price').then(data => {
      data && setItems(data);
      setPriceUniversities(data.universities);
      setPriceLevels(data.levels);
      setPriceLevelsAll(data.levels);
      setPriceModalitys(data.modalitys);
      setPriceModalitysAll(data.modalitys);
      setPriceCities(data.cities);
      setPricePrograms(data.programs);

      setLevels(data.levels.slice(0, 4));
      setModalitys(data.modalitys.slice(0, 4));
      setCities(data.cities.slice(0, 4));
      setUniversities(data.universities.slice(0, 4));
      // const test = [['product', 'Marca']];
      // data.programs.slice(0, 9).forEach(item => {
      //   test.push([item.project, item.team]);
      // });
      // setPrograms(test);
      setListPrograms([]);
    });
  };

  const columns2 = [
    {
      accessor: 'project',
      Header: 'SNIES - Nombre',
      Cell: rowData => {
        const { project } = rowData.row.original;
        return (
          <div className="d-flex align-items-center">
            <Avatar size="l" name={project} />
            <div className="ms-2">{project}</div>
          </div>
        );
      }
    },
    {
      accessor: 'team',
      Header: 'Precio',
      Cell: rowData => {
        const { team } = rowData.row.original;
        return (
          <div className="d-flex align-items-center">
            <div className="ms-2">${team}</div>
          </div>
        );
      }
    },
    {
      accessor: 'university',
      Header: 'Universidad'
    },

    {
      accessor: 'city',
      Header: 'Cuidad'
    },
    {
      accessor: 'modality',
      Header: 'Modalidad'
    },
    {
      accessor: 'education_level',
      Header: 'Nivel de Formación'
    }
  ];

  if (items === undefined) return <BackDrop param={' '} />;
  if (
    items !== undefined
    // numUniversities?.cities?.length > 0
  )
    return (
      <>
        <Row className="g-3 mb-3">
          <Col xl={3}>
            <CourseFilters
              data={items}
              setLevels={setLevels}
              setPriceLevels={setPriceLevels}
              priceLevelsAll={priceLevelsAll}
              setPriceModalitys={setPriceModalitys}
              priceModalitysAll={priceModalitysAll}
              setModalitys={setModalitys}
              setCities={setCities}
              setUniversities={setUniversities}
              setPrograms={setPrograms}
              setListPrograms={setListPrograms}
              pricePrograms={pricePrograms}
            />
          </Col>
          <Col xl={9}>
            <Row>
              <Col xl={12}>
                <CampaignPerfomance
                  title="Precio Promedio Por Universidad"
                  data={universities}
                  data2={priceUniversities}
                  setColumns={setColumns}
                  allStudents={allStudents}
                  setallStudents={setallStudents}
                  handleEModal={handleEModal}
                  setTitle={setTitle}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: '10px' }}>
              <Col xl={6}>
                <CampaignPerfomance
                  title="Precio Promedio Por Ciudad"
                  data={cities}
                  data2={priceCities}
                  setColumns={setColumns}
                  allStudents={allStudents}
                  setallStudents={setallStudents}
                  handleEModal={handleEModal}
                  setTitle={setTitle}
                />
              </Col>
              <Col xl={6}>
                <CampaignPerfomance
                  title="Precio Promedio Por Modalidad"
                  data={modalitys}
                  data2={priceModalitys}
                  setColumns={setColumns}
                  allStudents={allStudents}
                  setallStudents={setallStudents}
                  handleEModal={handleEModal}
                  setTitle={setTitle}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: '10px' }}>
              <Col xl={12}>
                <CampaignPerfomance
                  title="Precio Promedio Por Nivel de Formación"
                  data={levels}
                  data2={priceLevels}
                  setColumns={setColumns}
                  allStudents={allStudents}
                  setallStudents={setallStudents}
                  handleEModal={handleEModal}
                  setTitle={setTitle}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: '10px' }}>
              <Col xl={12}>
                <Datatable
                  title={`Listado de Programas`}
                  data={listPrograms}
                  columns={columns2}
                />
              </Col>
            </Row>
            {programs.length > 0 && (
              <Row style={{ marginTop: '10px' }}>
                <Col xl={12}>
                  <TopLeads data={programs} title={'Programas seleccionados'} />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        <EModal
          form_={
            <TableModal items={allStudents} title={title} columns={columns} />
          }
          title={''}
          modalSize={'lg'}
          modal={emodal}
          handleModal={handleEModal}
        />
      </>
    );
};

Pricing.propTypes = {
  authorized: PropTypes.bool
};

export default Pricing;
