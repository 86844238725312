import { useContext } from 'react';
import DefaultContext from 'context/DefaultProvider';
import AuthContext from 'context/AuthProvider';

import UniversityContext from 'context/UniversityProvider';
import UniversityProfileContext from 'context/UniversityProfileProvider';
import CourseContext from 'context/CourseProvider';
import BankContext from 'context/BankProvider';
import BootCampsContext from 'context/BootCampsProvider';
import OrientationContext from 'context/OrientationProvider';
import MentoringContext from 'context/MentoringProvider';
import ScholarshipContext from 'context/ScholarshipProvider';
import ApplicationsContext from 'context/ApplicationsProvider';
import ReferralContext from 'context/ReferralProvider';
import SurveyContext from 'context/SurveyProvider';

import SectorsContext from 'context/SectorsProvider';
import DepartmentsContext from 'context/DepartmentsProvider';
import CitiesContext from 'context/CitiesProvider';
import ProgramsContext from 'context/ProgramsProvider';
import ModalitysContext from 'context/ModalitysProvider';
import KnowledgeAreaContext from 'context/KnowledgeAreaProvider';
import BasicCoresContext from 'context/BasicCoresProvider';
import LevelsEducationContext from 'context/LevelsEducationProvider';
import LevelsContext from 'context/LevelsProvider';
import PeriodsContext from 'context/PeriodsProvider';
import BankRangeContext from 'context/BankRangeProvider';
import BankFeaturesContext from 'context/BankFeaturesProvider';
import BankRequirementsContext from 'context/BankRequirementsProvider';
import MarksCoursesContext from 'context/MarksCoursesProvider';
import MarksBootCampsContext from 'context/MarksBootCampsProvider';
import MarksMentoringContext from 'context/MarksMentoringProvider';
import RolesContext from 'context/RolesProvider';
import CampusContext from 'context/CampusProvider';
import AgreementsContext from 'context/AgreementsProvider';
import WelfareContext from 'context/WelfareProvider';
import BondsContext from 'context/BondsProvider';
import RectorsContext from 'context/RectorsProvider';
import ImagesContext from 'context/GalleryProvider';
import AgreementsProgramContext from 'context/AgreementsProgramProvider';
import ChargesContext from 'context/ChargesProvider';
import SpreadsContext from 'context/SpreadsProvider';
import TeachersContext from 'context/TeachersProvider';
import RequirementsContext from 'context/RequirementsProvider';
import DimensionsContext from 'context/DimensionsProvider';
// import PricingContext from 'context/PricingProvider';

export * from './useAxios';
export * from './useIdleTimer';

export const useDefault = () => useContext(DefaultContext);
export const useAuth = () => useContext(AuthContext);

export const useUniversity = () => useContext(UniversityContext);
export const useUniversityProfile = () => useContext(UniversityProfileContext);
export const useCourse = () => useContext(CourseContext);
export const useBank = () => useContext(BankContext);
export const useBootCamps = () => useContext(BootCampsContext);
export const useOrientation = () => useContext(OrientationContext);
export const useMentoring = () => useContext(MentoringContext);
export const useScholarship = () => useContext(ScholarshipContext);
export const useApplications = () => useContext(ApplicationsContext);
export const useReferral = () => useContext(ReferralContext);
export const useSurvey = () => useContext(SurveyContext);

export const useSectors = () => useContext(SectorsContext);
export const useDepartments = () => useContext(DepartmentsContext);
export const useCities = () => useContext(CitiesContext);
export const usePrograms = () => useContext(ProgramsContext);
export const useModalitys = () => useContext(ModalitysContext);
export const useKnowledgeArea = () => useContext(KnowledgeAreaContext);
export const useBasicCores = () => useContext(BasicCoresContext);
export const useLevelsEducation = () => useContext(LevelsEducationContext);
export const useLevels = () => useContext(LevelsContext);
export const usePeriods = () => useContext(PeriodsContext);
export const useBankRange = () => useContext(BankRangeContext);
export const useBankFeatures = () => useContext(BankFeaturesContext);
export const useBankRequirements = () => useContext(BankRequirementsContext);
export const useMarksCourses = () => useContext(MarksCoursesContext);
export const useMarksBootCamps = () => useContext(MarksBootCampsContext);
export const useMarksMentoring = () => useContext(MarksMentoringContext);
export const useRoles = () => useContext(RolesContext);
export const useCampus = () => useContext(CampusContext);
export const useAgreements = () => useContext(AgreementsContext);
export const useWelfare = () => useContext(WelfareContext);
export const useBonds = () => useContext(BondsContext);
export const useRectors = () => useContext(RectorsContext);
export const useImages = () => useContext(ImagesContext);
export const useCharges = () => useContext(ChargesContext);
export const useSpreads = () => useContext(SpreadsContext);
export const useTeachers = () => useContext(TeachersContext);
export const useRequirements = () => useContext(RequirementsContext);
export const useAgreementsProgram = () => useContext(AgreementsProgramContext);
export const useDimensions = () => useContext(DimensionsContext);
// export const usePricing = () => useContext(PricingContext);
