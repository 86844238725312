import React, { useEffect } from 'react';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useDefault } from 'hooks';
import Buttons from 'components/templates/Buttons';

import { EModal, DModal, CModal } from 'components/templates/Modal';
import Datatable from 'components/templates/datatable/Datatable';

const University = ({ authorized, route, useHook }) => {
  if (!authorized) {
    return <Navigate to="/dashboard" />;
  }

  const navigate = useNavigate();

  const { emodal, handleEModal, dmodal, handleDModal, cmodal, handleCModal } =
    useDefault();
  const {
    setItem,
    item,
    items,
    submit,
    removeOne,
    changeStatus,
    columns,
    name,
    routes,
    Form_,
    caption,
    captions,
    modalSize,
    confirm
  } = useHook();
  useEffect(() => {
    if (items === undefined) {
      navigate(`/cms/${routes}`, { replace: true });
    }
  }, [navigate, route, items]);
  // useEffect(() => {
  //   console.log('hi2');
  //   if (name === null) navigate(`/cms/${routes}`, { replace: true });
  // }, [navigate, route, name, items, nameBootCamp, nameMentoring]);

  //* FOR CLEAR FORM ON-CLOSE MODAL'S
  useEffect(() => {
    !emodal && setItem({});
  }, [emodal]);

  useEffect(() => {
    !dmodal && setItem({});
  }, [dmodal]);

  useEffect(() => {
    !cmodal && setItem({});
  }, [cmodal]);

  return (
    <>
      <Link to={`/cms/${routes}`}>
        <Buttons btn={'return'} />
      </Link>
      {Form_ && (
        <Buttons btn={'add'} caption={caption} handleEvent={handleEModal} />
      )}
      {/* {items !== undefined && ( */}
      <Datatable
        title={`${captions} de la Universidad ${name}`}
        data={items || []}
        columns={columns}
      />
      {/* )} */}
      {Form_ && (
        <EModal
          form_={<Form_ submit={submit} item={item} items={items} />}
          title={`${caption}`}
          modal={emodal}
          modalSize={modalSize}
          handleModal={handleEModal}
        />
      )}
      <DModal
        item={item}
        modal={dmodal}
        handleModal={handleDModal}
        handleClick={removeOne}
      />
      {confirm && (
        <CModal
          item={item}
          modal={cmodal}
          handleModal={handleCModal}
          handleClick={changeStatus}
        />
      )}
    </>
  );
};

University.propTypes = {
  route: PropTypes.string,
  authorized: PropTypes.bool,
  useHook: PropTypes.func.isRequired
};

export default University;
