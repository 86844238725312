import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import BackDrop from 'components/templates/BackDrop';
import { useDefault, useAuth } from 'hooks';
import MainLayout from 'pages/template/Default';

const RouteProtect = () => {
  const { loading } = useDefault();
  const { auth = {} } = useAuth();
  const [authAttempted, setAuthAttempted] = useState(false);

  // /* const {getLastActiveTime} = */ useIdle({ signOut, idleTime: 4.5 });
  useEffect(() => auth && setAuthAttempted(true), [auth]);

  if (loading || !authAttempted) return <BackDrop param={''} />;
  if (!auth?._id) return <Navigate to="/" />;
  return <MainLayout />;
};

export default RouteProtect;
