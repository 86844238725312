import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Card, Row, Col } from 'react-bootstrap';
// import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import ReactSelect from 'react-select';
import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import team2 from 'assets/img/team/avatar.png';
import TableModal from 'components/dashboards/futura/TableModal';
import { EModal } from 'components/templates/Modal';

const ActiveUsers = ({ users, end = 5 }) => {
  const [all, setAll] = useState([]);
  const [allStudents, setallStudents] = useState([]);
  const [cant, setCant] = useState(0);
  const [option, setOption] = useState(null);
  const [umodal, setUModal] = useState(false);

  const buscar = () => {
    return users?.find(objeto => objeto?.bankId == option?.value);
  };

  const llenarcant = () => {
    setCant(
      users?.reduce((total, objeto) => total + objeto?.students.length, 0)
    );
  };

  const cargarAllStudents = () => {
    setallStudents([]);
    if (option && option?.value !== null) {
      const n = buscar();
      setCant(n?.students.length);
      setallStudents(n?.students);
    } else {
      llenarcant();
      setallStudents(all);
    }
  };

  useEffect(() => {
    if (users) {
      const studentArrays = users?.map(item => item?.students);
      const a = [].concat(...studentArrays);
      setAll(a);
      setallStudents(a);
    }
    llenarcant();
  }, [users]);

  useEffect(() => {
    cargarAllStudents();
  }, [option]);

  const handleUModal = () => {
    setUModal(umodal ? false : true);
  };

  return (
    <>
      <Card>
        <FalconCardHeader
          light
          title={'Usuarios por Banco: (' + cant + ')'}
          titleTag="h6"
          className="py-2"
          endEl={
            <ReactSelect
              closeMenuOnSelect={true}
              options={[{ value: null, label: 'De Todos los Bancos' }].concat(
                users?.map(find => ({
                  value: find?.bankId,
                  label: find?.bankName
                }))
              )}
              placeholder="De Todos los Bancos"
              classNamePrefix="react-select"
              value={option}
              onChange={option => setOption(option)}
            />
          }
        />
        <Card.Body className="py-2">
          {allStudents
            ?.slice(0, option?.value === null ? end : allStudents?.length)
            .map(find => (
              <ActiveUser
                key={find?._id}
                user={find}
                avatar={{
                  src: team2,
                  size: '2xl',
                  status: 'online'
                }}
              />
            ))}
        </Card.Body>
        <FalconCardFooterLink
          title="Ver todos los usuarios"
          onClick={() => {
            handleUModal();
          }}
          size="sm"
        />
      </Card>

      <EModal
        form_={
          <TableModal
            items={all}
            title="Usuarios por Banco"
            columns={[
              {
                accessor: 'name',
                Header: 'Nombre'
              },
              {
                accessor: 'lastname',
                Header: 'Apellidos'
              },
              {
                accessor: 'email',
                Header: 'Correo'
              },
              {
                accessor: 'phone',
                Header: 'Telefono'
              },
              {
                accessor: 'bank',
                Header: 'Banco'
              }
            ]}
          />
        }
        title={''}
        modalSize={'lg'}
        modal={umodal}
        handleModal={handleUModal}
      />
    </>
  );
};

const ActiveUser = ({ user, avatar }) => (
  <Row md={12} xxl={12}>
    <Col md={3} xxl={3}>
      <div className="d-flex align-items-center">
        <Avatar {...avatar} />
        <div className="ms-2">
          {user?.name} {user?.lastname}
        </div>
      </div>
    </Col>
    <Col md={4} xxl={4}>
      <div className="ms-3">
        <p className="text-500 fs--2">movil: {user?.phone}</p>
      </div>
      <div className="ms-3">
        <p className="text-500 fs--2">email: {user?.email}</p>
      </div>
    </Col>
    <Col md={4} xxl={4}>
      <div className="ms-3">
        <p className="text-500 fs--2">Banco:{user?.bank}</p>
      </div>
    </Col>
  </Row>
);

ActiveUser.propTypes = {
  user: PropTypes.object,
  avatar: PropTypes.object
};

ActiveUsers.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape(ActiveUser.propTypes)),
  end: PropTypes.object
};

export default ActiveUsers;
