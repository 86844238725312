import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import BasicECharts from 'components/common/BasicEChart';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent
} from 'echarts/components';

import { getColor, rgbaColor } from 'helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import CountUp from 'react-countup';

echarts.use([TitleComponent, TooltipComponent, GridComponent, LineChart]);

const getOptions = data => ({
  xAxis: {
    show: false,
    boundaryGap: false
  },
  series: [
    {
      data: data,
      type: 'line',
      symbol: 'none',
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getColor('primary'), 0.25)
            },
            {
              offset: 1,
              color: rgbaColor(getColor('primary'), 0)
            }
          ]
        }
      }
    }
  ],
  grid: { right: '0px', left: '0px', bottom: '0px', top: '0px' }
});
const General = ({ data }) => {
  const total = data[0]?.total[0] ? data[0]?.total[0].total : 0;
  const pregrado = data[0]?.pregrado[0]
    ? data[0]?.pregrado[
        data[0]?.pregrado[0] && data[0]?.pregrado[0].name === 'Pregrado' ? 0 : 1
      ].total
    : 0;
  const pregradoPorcent = Math.round((pregrado * 100) / total, 2);
  const posgrado = data[0]?.pregrado[1]
    ? data[0]?.pregrado[
        data[0]?.pregrado[0] && data[0]?.pregrado[0].name === 'Posgrado' ? 1 : 0
      ].total
    : 0;
  // const posgrado =
  //   data[0]?.pregrado[data[0]?.pregrado[1].name === 'Posgrado' ? 1 : 0].total;
  const postgradoPorcent = Math.round((posgrado * 100) / total, 2);

  return (
    <>
      <Row className="g-3 mb-3">
        <Col>
          <Row className="g-3">
            <Col md={4} xxl={4}>
              <Card className="h-100">
                <Card.Body>
                  <Row className="flex-between-center g-0">
                    <Col xs={6} className="d-lg-block flex-between-center">
                      <h6 className="mb-2 text-900">Total Encuestas</h6>
                      <h4 className="fs-3 fw-normal text-700 mb-0">{total}</h4>
                    </Col>
                    <Col xs="auto" className="h-100">
                      <BasicECharts
                        echarts={echarts}
                        options={getOptions([3, 7, 6, 8, 5, 12, 11])}
                        style={{ height: '50px', minWidth: '80px' }}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} xxl={4}>
              <Card className="h-100">
                <Card.Body>
                  <Row className="flex-between-center">
                    <Col className="d-md-flex d-lg-block flex-between-center">
                      <h6 className="mb-md-0 mb-lg-2">Pregrado</h6>
                      <SoftBadge bg="success" pill>
                        <FontAwesomeIcon icon="caret-up" />
                        {pregradoPorcent || 0}%
                      </SoftBadge>
                    </Col>
                    <Col xs="auto">
                      <h4 className="fs-3 fw-normal text-700">
                        <CountUp
                          start={0}
                          end={pregrado}
                          duration={2.75}
                          decimals={0}
                          decimal="."
                        />
                      </h4>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} xxl={4}>
              <Card className="h-100">
                <Card.Body>
                  <Row className="flex-between-center">
                    <Col className="d-md-flex d-lg-block flex-between-center">
                      <h6 className="mb-md-0 mb-lg-2">Posgrado</h6>
                      <SoftBadge bg="info" pill>
                        <FontAwesomeIcon icon="caret-up" />
                        {postgradoPorcent || 0}%
                      </SoftBadge>
                    </Col>
                    <Col xs="auto">
                      <h4 className="fs-3 fw-normal text-700">
                        <CountUp
                          start={0}
                          end={posgrado}
                          duration={2.75}
                          decimals={0}
                          decimal="."
                        />
                      </h4>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

General.propTypes = {
  data: PropTypes.array
};

export default General;
