import React, { useEffect, useState } from 'react';
import { Col, Form, Row, InputGroup, FormControl } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import Buttons from '../templates/Buttons';
import PropTypes from 'prop-types';

const OrientationForm = ({ submit, item }) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors }
  } = useForm({ defaultValues: { id: null } });
  const [image, setImage] = useState(null);
  const [logo, setLogo] = useState();
  const [imageUrl, setImageUrl] = useState();

  //*EVENT CLICK SAVE AND UPDATE
  const onSubmit = data => {
    const formData = new FormData();
    if (item._id) {
      formData.append('_id', item._id);
    }
    formData.append('logo', logo);
    formData.append('name', data.name);
    formData.append('price', data.price);
    formData.append('financing', data.financing);
    formData.append('about', data.about);
    setImage(null);
    saveImage(`${process.env.REACT_APP_BACKEND_URL}/storage/estandar.png`);
    submit(formData);
    reset();
  };

  const saveImage = async logoUrl => {
    setImageUrl(logoUrl);
    const response = await fetch(logoUrl);
    const blob = await response.blob();
    const file = new File([blob], 'logo.jpg', { type: 'image/jpeg' });
    setLogo(file);
  };

  const verifyImage = async logo => {
    setImage(null);
    const file = logo;
    if (file) {
      setImageUrl(URL.createObjectURL(file));
      if (file.type && file.type.startsWith('image/')) {
        try {
          if (file.size < 21000000) {
            setLogo(file);
          } else {
            setImage('El tamaño del fichero excede los 1mb!');
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        setImage('Este fichero no es una imagen!');
      }
    }
  };

  //* EVENT GET ALL RGISTER
  useEffect(() => {
    let logoUrl;
    if (item._id) {
      setValue('id', item._id);
      setValue('name', item.name);
      setValue('price', item.price);
      setValue('financing', item.financing);
      setValue('about', item.about);
      logoUrl = `${process.env.REACT_APP_BACKEND_URL}/storage/logos/${item.logo}`;
    } else {
      logoUrl = `${process.env.REACT_APP_BACKEND_URL}/storage/estandar.png`;
    }
    saveImage(logoUrl);
  }, [watch('id')]);

  return (
    <Row>
      <Col>
        <Form noValidate onSubmit={handleSubmit(onSubmit)} className="px-1">
          <div id="name-row">
            <Form.Group>
              <Form.Label className="mb-1">Nombre (*)</Form.Label>
              <Form.Control
                size="sm"
                type="text"
                id="name"
                name="name"
                placeholder="Nombre de la Orientación"
                isInvalid={!!errors.name}
                {...register('name', {
                  required: {
                    value: true,
                    message: 'este campo es requerido!'
                  },
                  minLength: {
                    limit: 1,
                    value: 1,
                    message: 'Mínimo 1 carácter!'
                  },
                  pattern: {
                    value: /^[A-Za-z .]+$/i,
                    message: 'no se permiten caracteres numéricos!'
                  }
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name && errors.name.message}
              </Form.Control.Feedback>
            </Form.Group>
          </div>

          <div id="about-row">
            <Form.Group>
              <Form.Label className="mb-1">Descripción (*)</Form.Label>
              <Form.Control
                size="sm"
                as="textarea"
                id="about"
                name="about"
                rows={7}
                placeholder="Descripción de la Orientación"
                isInvalid={!!errors.about}
                {...register('about', {
                  required: {
                    value: true,
                    message: 'este campo es requerido!'
                  },
                  maxLength: {
                    limit: 500,
                    value: 500,
                    message: 'maxLength: 500 caracteres!'
                  }
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.about && errors.about.message}
              </Form.Control.Feedback>
            </Form.Group>
          </div>

          <div id="price-row">
            <Form.Group>
              <Form.Label className="mb-1">Precio (*)</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text>$</InputGroup.Text>
                <FormControl
                  size="sm"
                  type="text"
                  id="price"
                  name="price"
                  placeholder="1.000.000"
                  isInvalid={!!errors.price}
                  {...register('price', {
                    required: {
                      value: true,
                      message: 'este campo es requerido!'
                    }
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.price && errors.price.message}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </div>

          <div id="financing-row" className="mb-3">
            <Form.Group>
              <Form.Label className="mb-1">Tipo Test (*)</Form.Label>
              <Form.Control
                size="sm"
                type="text"
                id="financing"
                name="financing"
                placeholder="Hasta 60%"
                isInvalid={!!errors.financing}
                {...register('financing', {
                  required: {
                    value: true,
                    message: 'este campo es requerido!'
                  }
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.financing && errors.financing.message}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className="w-100">
            <Form.Group>
              <Form.Label
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 'auto',
                  marginBottom: '10px',
                  marginTop: '10px',
                  color: '#000'
                }}
              >
                {' '}
                Logo
              </Form.Label>
              {imageUrl && image === null && (
                <div
                  style={{
                    width: '150px',
                    height: '150px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: 'auto'
                  }}
                >
                  <img
                    src={imageUrl}
                    alt="Preview"
                    style={{
                      objectFit: 'cover',
                      maxWidth: '100%',
                      maxHeight: '100%'
                    }}
                  />
                </div>
              )}
            </Form.Group>
            <Form.Label
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 'auto',
                marginTop: '10px',
                color: '#000'
              }}
            >
              {' '}
              Resolución Máxima: 400x400
            </Form.Label>
            <Form.Label
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 'auto',
                color: '#000'
              }}
            >
              {' '}
              Tamaño máximo: 1mb
            </Form.Label>
            <div id="logo-row" className="mb-3">
              <Form.Group>
                <Form.Label className="mb-1">Logo</Form.Label>
                <Form.Control
                  size="sm"
                  type="file"
                  id="logo"
                  name="logo"
                  onChange={e => {
                    verifyImage(e.target.files[0]);
                  }}
                />
                {image !== null && (
                  <h6 className="text-danger fs--2 mt-2 mb-0">{image}</h6>
                )}
              </Form.Group>
            </div>
          </div>

          <Buttons
            btn={'create'}
            caption={watch('id') ? 'Actualizar Datos' : 'Guardar Datos'}
          />
        </Form>
      </Col>
    </Row>
  );
};

OrientationForm.propTypes = {
  submit: PropTypes.func.isRequired,
  item: PropTypes.object
};

export default OrientationForm;
