import FalconCardHeader from 'components/common/FalconCardHeader';
import React from 'react';
import { Card, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

const BillingSettings = ({ formData }) => {
  return (
    <Card
      className={`${
        localStorage.getItem('isDark') == 'true' ? 'text-400' : 'text-700'
      } mb-3`}
    >
      <FalconCardHeader title="Perfil" />
      <Card.Body className="bg-light">
        <Form noValidate className="px-1">
          <Row className="mb-3 g-3">
            <Form.Group>
              <Form.Label>Usuario</Form.Label>
              <Form.Control
                type="text"
                style={{ cursor: 'not-allowed', color: 'gray' }}
                defaultValue={formData.email}
                disabled={true}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3 g-3">
            <Form.Group>
              <Form.Label>Rol</Form.Label>
              <Form.Control
                type="text"
                style={{ cursor: 'not-allowed', color: 'gray' }}
                defaultValue={formData.role}
                disabled={true}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3 g-3">
            <Form.Group>
              <Form.Label>Teléfono</Form.Label>
              <Form.Control
                type="text"
                style={{ cursor: 'not-allowed', color: 'gray' }}
                defaultValue={formData.phone}
                disabled={true}
              />
            </Form.Group>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

BillingSettings.propTypes = {
  formData: PropTypes.shape({
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired
  }).isRequired,
  setFormData: PropTypes.func
};

export default BillingSettings;
