import React, { useState, useEffect } from 'react';
import { EModal } from 'components/templates/Modal';
import { Row, Col } from 'react-bootstrap';

import BanckWidgets from 'components/dashboards/banck/Banck';
import UsersBanckWidgets from 'components/dashboards/banck/UsersBanck';
import { useDefault } from 'hooks';

const Dashboard = () => {
  const { emodal, handleEModal } = useDefault();

  const { list } = useDefault();
  const [items, setItems] = useState([]);
  const [banckUserCredit, setBanckUserCredit] = useState([]);

  useEffect(() => {
    getBackList();
    getBanckUserCredit();
  }, []);

  const getBackList = () => {
    list('banks/list').then(data => {
      data && setItems(data);
    });
  };

  const getBanckUserCredit = () => {
    list('dashboard_bank/list_user_credit').then(data => {
      data && setBanckUserCredit(data);
    });
  };

  return (
    <>
      <Row className="g-3">
        <Col xxl={12}>
          <BanckWidgets data={items} toShow={5} />
        </Col>
        <Col xxl={12}>
          <UsersBanckWidgets users={banckUserCredit} />
        </Col>
      </Row>

      <EModal
        form_={<BanckWidgets data={items} toShow={'f'} />}
        title={''}
        modalSize={'lg'}
        modal={emodal}
        handleModal={handleEModal}
      />
    </>
  );
};

export default Dashboard;
