import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDefault, useSurvey } from 'hooks';

import { CModal, EModal } from 'components/templates/Modal';
import DataTable_ from 'components/survey/datatable/Datatable';
import ConfigurationForm from 'components/forms/ConfigurationForm';
import ConfigSurveyWidgets from 'components/dashboards/survey/ConfigStats';
import Tabs from 'components/survey/Tabs';
import CommentForm from 'components/forms/CommentForm';

const SurveyList = ({ authorized }) => {
  if (!authorized) return <Navigate to="/dashboard" />;

  const { emodal, handleEModal, cmodal, handleCModal, fmodal, handleFModal } =
    useDefault();
  const {
    itemList,
    itemsList,
    captionList,
    published,
    searchQuery,
    searchQueryDate,
    quizTotal,
    verified,
    unverified,
    publish,
    noPublish,
    config,
    configuration,
    updateComment,
    comment,
    columnsList,
    tabs,
    modalSize,
    mmodal,
    vmodal,
    handleMModal,
    handleVModal,
    verifiedF
    // modifaid
  } = useSurvey();

  return (
    <>
      <ConfigSurveyWidgets
        items={[
          {
            id: 1,
            title: 'Encuestas',
            amount: quizTotal,
            color: 'primary',
            icon: 'poll',
            data: [220, 230, 150, 165, 250, 160, 70, 160]
          },
          {
            id: 2,
            title: 'Aprobadas',
            amount: publish,
            color: 'success',
            icon: 'check',
            data: [220, 230, 150, 175, 200, 170, 70, 160]
          },
          {
            id: 3,
            title: 'Pendientes',
            amount: noPublish,
            color: 'warning',
            icon: 'clock'
          },
          {
            id: 4,
            title: 'Usuarios Verificados',
            amount: verified,
            color: 'success',
            icon: 'check'
          },
          {
            id: 5,
            title: 'Usuarios Pendientes',
            amount: unverified,
            color: 'danger',
            icon: 'clock'
          }
        ]}
      />

      <DataTable_
        title={`Listado de ${captionList}`}
        columns={columnsList}
        data={itemsList}
        searchQuery={searchQuery}
        searchQueryDate={searchQueryDate}
        handleModal={handleEModal}
      />

      <EModal
        form_={<ConfigurationForm submit={configuration} item={config} />}
        title={'Configurar-Encuestas'}
        modal={emodal}
        handleModal={handleEModal}
      />

      <EModal
        form_={<CommentForm submit={updateComment} item={comment} />}
        title={'Modelar-Comentarios'}
        modal={mmodal}
        handleModal={handleMModal}
      />

      <EModal
        form_={<Tabs tabs={tabs} />}
        title={'Encuesta'}
        modal={vmodal}
        modalSize={modalSize}
        handleModal={handleVModal}
      />

      <CModal
        item={itemList?.university || {}}
        modal={cmodal}
        handleModal={handleCModal}
        handleClick={published}
      />

      <CModal
        item={itemList?.university || {}}
        modal={fmodal}
        handleModal={handleFModal}
        handleClick={verifiedF}
      />
    </>
  );
};

SurveyList.propTypes = {
  authorized: PropTypes.bool
};

export default SurveyList;
