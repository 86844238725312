import React from 'react';
import PropTypes from 'prop-types';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import { Card, Col, Row } from 'react-bootstrap';
import MostLeadsChart from './MostLeadsChart';
// import { leadsData } from '../../../data/dashboard/crm';
import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import classNames from 'classnames';
import FalconCardHeader from 'components/common/FalconCardHeader';

import email from 'assets/img/icons/email.svg';
import social from 'assets/img/icons/social.svg';
import call from 'assets/img/icons/call.svg';
import Avatar from 'components/common/Avatar';
export const leadsData = [
  {
    id: 1,
    title: 'Email',
    target: '5200 vs 1052',
    img: email,
    amount: 12
  },
  {
    id: 2,
    title: 'Social',
    target: '5623 vs 4929',
    img: social,
    amount: 25
  },
  {
    id: 3,
    title: 'Call',
    target: '2535 vs 1486',
    img: call,
    amount: 63
  }
];
const LeadItem = ({ item, isLast }) => {
  return (
    <Flex
      justifyContent="between"
      alignItems="center"
      className={classNames('border-bottom py-3', {
        'border-bottom-0 pb-0': isLast
      })}
    >
      <Flex>
        <Avatar size="l" name={item.title} />
        {/* <img src={item.img} alt="..." width={16} height={16} className="me-2" /> */}

        <h6
          style={{ marginTop: '5px', marginLeft: '5px' }}
          className="text-700 mb-2"
        >
          {item.title}
        </h6>
      </Flex>
      <p className="fs-10 text-500 mb-0 fw-semibold">{item.target}</p>
      <h6 className="text-700 mb-0">{item.amount}%</h6>
    </Flex>
  );
};

const MostLeads = ({
  title,
  total,
  projectsTable,
  setTitle,
  setColumns,
  setallStudents,
  handleEModal
}) => {
  const items = [];
  const data = projectsTable.slice(0, 4);
  data.map(item => items.push({ value: item.amount, name: item.title }));

  return (
    <Card className="h-100">
      <FalconCardHeader
        title={title}
        titleTag="h6"
        className="border-200 border-bottom py-2"
        endEl={<CardDropdown />}
      />
      <Card.Body as={Row}>
        <Col md={5} xxl={12} className="mb-xxl-1">
          <MostLeadsChart total={total} data={items} />
        </Col>
        <Col xxl={12} md={7}>
          <hr className="mx-ncard mb-0 d-md-none d-xxl-block" />
          {data.map((item, index) => (
            <LeadItem
              key={item.id}
              item={item}
              isLast={index === data.length - 1}
            />
          ))}
        </Col>
      </Card.Body>
      {projectsTable.length > 3 && (
        <a
          onClick={() => {
            handleEModal();
          }}
          className="fw-semi-bold fs--1 text-nowrap cursor-pointer"
        >
          <FalconCardFooterLink
            title="Ver más..."
            size="sm"
            to="#!"
            onClick={() => {
              setTitle(title);
              setColumns([
                {
                  accessor: 'title',
                  Header: 'Nombre'
                },
                {
                  accessor: 'target',
                  Header: 'Cantidad'
                },
                {
                  accessor: 'amount',
                  Header: 'Porciento',
                  Cell: rowData => {
                    const { amount } = rowData.row.original;
                    return <p> {amount} %</p>;
                  }
                }
              ]);
              setallStudents(projectsTable);
              handleEModal();
            }}
          />
        </a>
      )}
    </Card>
  );
};

LeadItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    title: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired
  }),
  isLast: PropTypes.bool.isRequired
};

MostLeads.propTypes = {
  projectsTable: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  total: PropTypes.number,
  setTitle: PropTypes.func,
  setColumns: PropTypes.func,
  setallStudents: PropTypes.func,
  handleEModal: PropTypes.func
};

export default MostLeads;
