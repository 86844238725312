import React, { useEffect, useState, createContext } from 'react';

import { useDefault, useAuth } from 'hooks';

import Buttons from 'components/templates/Buttons';
import Form_ from 'components/forms/BankFeaturesForm';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import Avatar from 'components/common/Avatar';
import dayjs from 'helpers/dayjs';
import { useLocation } from 'react-router-dom';

const BankFeaturesContext = createContext();

const BankFeaturesProvider = ({ children }) => {
  const { list, update, add, remove, handleEModal, handleDModal } =
    useDefault();

  const [item, setItem] = useState({});
  const [items, setItems] = useState([]);
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  const columns = [
    {
      accessor: 'name',
      Header: 'Nombre',
      Cell: rowData => {
        const { name } = rowData.row.original;
        return (
          <div className="d-flex align-items-cente">
            <Avatar size="l" name={name} />
            <div className="ms-2 text-truncate" style={{ maxWidth: '500px' }}>
              {name}
            </div>
          </div>
        );
      }
    },
    {
      accessor: 'bank',
      Header: 'Banco',
      Cell: rowData => {
        const { bank } = rowData.row.original;
        return bank?.name + '-' + bank?.range.range;
      }
    },
    {
      accessor: 'updatedAt',
      Header: 'Actualizado',
      Cell: rowData => {
        const { updatedAt } = rowData.row.original;
        return dayjs(updatedAt).fromNow();
      }
    },
    {
      accessor: 'btn',
      Header: 'Acciones',
      headerProps: { style: { width: '95px' } },
      Cell: rowData => {
        return (
          <Flex className="gap-1 mt-1.5">
            <Buttons
              btn={'update'}
              handleEvent={() => {
                rowData.row.original.pt = false;
                setItem(rowData.row.original);
                handleEModal();
              }}
            />
            <Buttons
              btn={'delete'}
              handleEvent={() => {
                setItem(rowData.row.original);
                handleDModal();
              }}
            />
          </Flex>
        );
      }
    }
  ];

  //* FUNTION FOR SAVE OR MODIFY REGISTER
  const submit = object_ => {
    if (object_.id) {
      object_._id = object_.id;
      update('banksfeatures/update', object_).then(res => {
        res &&
          setItems(
            items.map(stateObj => (stateObj._id === res._id ? res : stateObj))
          );
        setItem({});
      });
    } else
      add('banksfeatures/add', object_).then(
        res => res && setItems([...items, res])
      );
  };

  //* GET ALL REGISTER
  useEffect(() => {
    if (location.pathname === '/cms/banksfeatures') {
      list('banksfeatures/list').then(res => res && setItems(res));
    }
  }, [location, isAuthenticated]);

  //* DELETE
  const removeOne = () => {
    remove(`banksfeatures/delete/${item._id}`).then(() => {
      setItems(items.filter(stateObj => stateObj._id !== item._id));
      setItem({});
    });
  };

  return (
    <BankFeaturesContext.Provider
      value={{
        item,
        items,
        setItem,
        setItems,
        columns,
        Form_,
        submit,
        removeOne,
        confirm: false,
        caption: 'Característica',
        captions: 'Características'
      }}
    >
      {children}
    </BankFeaturesContext.Provider>
  );
};

BankFeaturesProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { BankFeaturesProvider };
export default BankFeaturesContext;
