import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDefault, useAuth } from 'hooks';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';

const AuthForm = ({ hasLabel }) => {
  const { loadBotton } = useDefault();
  const { signIn } = useAuth();
  // State
  const [formData, setFormData] = useState({
    email: '',
    password: ''
    //remember: false
  });

  //get a closed session by inactivity
  useEffect(() => {
    localStorage.removeItem('idle');
  }, []);

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    signIn(formData);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3 text-700">
        {hasLabel && <Form.Label>Dirección de correo</Form.Label>}
        <Form.Control
          size="sm"
          type="text"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleFieldChange}
          placeholder={!hasLabel ? 'Dirección de correo' : ''}
        />
      </Form.Group>

      <Form.Group className="mb-3 text-700">
        {hasLabel && <Form.Label>Contraseña</Form.Label>}
        <Form.Control
          size="sm"
          type="password"
          id="password"
          name="password"
          placeholder={!hasLabel ? 'Contraseña' : ''}
          value={formData.password}
          onChange={handleFieldChange}
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={e =>
                setFormData({
                  ...formData,
                  remember: e.target.checked
                })
              }
            />
            <Form.Check.Label className="mb-0 text-700">
              Recuérdame
            </Form.Check.Label>
          </Form.Check>
        </Col>

        <Col xs="auto">
          <Link className="fs--1 mb-0" to={`forgot-password`}>
            Olvidaste tu contraseña?
          </Link>
        </Col>
      </Row>

      <Form.Group>
        <Button
          type="submit"
          size="sm"
          color="primary"
          className="mt-3 w-100"
          disabled={loadBotton || !formData.email || !formData.password}
        >
          {loadBotton && (
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          {loadBotton ? 'Cargando…' : 'Iniciar Sesión'}
        </Button>
      </Form.Group>
    </Form>
  );
};

AuthForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

AuthForm.defaultProps = {
  layout: 'simple'
};

export default AuthForm;
