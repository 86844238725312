import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ProfileSettings from './ProfileSettings';
import BillingSettings from './BillingSettings';
import ChangePassword from './ChangePassword';

const Settings = () => {
  const [formData, setFormData] = useState({
    _id: 0,
    name: '',
    lastname: '',
    email: '',
    role: '',
    phone: '',
    document: '',
    doctype: null,
    department: null,
    city: null,
    university_profile: null
  });

  return (
    <>
      <Row className="g-3">
        <Col lg={8}>
          <ProfileSettings formData={formData} setFormData={setFormData} />
        </Col>
        <Col lg={4}>
          <div>
            <BillingSettings formData={formData} setFormData={setFormData} />
            <ChangePassword />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Settings;
