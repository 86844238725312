import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useReferral } from 'hooks';
import ReferralWidgets from 'components/dashboards/referral/ReferralWidgets';
import Avatar from 'components/common/Avatar';
import Datatable from 'components/templates/datatable/Datatable';
const Referral = ({ authorized }) => {
  const columns = [
    {
      accessor: 'name',
      Header: 'Nombre',
      Cell: rowData => {
        const { name } = rowData.row.original;
        return (
          <div className="d-flex align-items-center">
            <Avatar size="l" name={name} />
            <div className="ms-2">{name}</div>
          </div>
        );
      }
    },
    {
      accessor: 'nickname',
      Header: 'Nickname'
    },
    {
      accessor: 'email',
      Header: 'Correo'
    },
    {
      accessor: 'phone',
      Header: 'Teléfono'
    },
    {
      accessor: 'code',
      Header: 'Código'
    },
    {
      accessor: 'referral',
      Header: 'Referidos',
      Cell: rowData => {
        const { referral } = rowData.row.original;
        return (
          <span
            className={`${
              referral > 0 ? 'text-primary fw-bold' : 'text-500 fw-semibold'
            } text-sm`}
          >
            {referral > 0 ? referral : 'sin referidos'}
          </span>
        );
      }
    }
  ];
  if (!authorized) return <Navigate to="/dashboard" />;

  const { items } = useReferral();
  return (
    <>
      <ReferralWidgets items={items} />
      <Datatable
        title={`Listado de Referidos`}
        data={items}
        columns={columns}
      />
    </>
  );
};

Referral.propTypes = {
  authorized: PropTypes.bool
};

export default Referral;
