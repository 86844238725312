import React, { useEffect, useState, createContext } from 'react';

import { useDefault } from 'hooks';

import Buttons from 'components/templates/Buttons';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import Avatar from 'components/common/Avatar';

import { toast } from 'react-toastify';
import { http } from 'hooks';
import headers from 'config/header';
import { useLocation, useNavigate } from 'react-router-dom';

import { SwitchDt } from 'components/SwitchDt';
import { Dropdown } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import Form_ from 'components/forms/ProgramForm';

const ProgramsContext = createContext();

const ProgramsProvider = ({ children }) => {
  const location = useLocation();
  const {
    list,
    update,
    add,
    remove,
    handleEModal,
    handleFModal,
    handleDModal
  } = useDefault();
  const modalSize = 'xl';

  const navigate = useNavigate();
  const [name, setName] = useState(null);

  const [item, setItem] = useState({});
  const [items, setItems] = useState();
  const handleClick = row => {
    setItem(row);
    setName(row.name);
    navigate('/cms/scholarship-program', { replace: true });
  };
  const handleClickProgram = (row, url) => {
    setItem(row);
    setName(row.name);
    navigate(`/cms/programs/${url}`, { replace: true });
  };

  const columns = [
    {
      accessor: 'name',
      Header: 'Nombre',
      Cell: rowData => {
        const { name } = rowData.row.original;
        return (
          <div className="d-flex align-items-center">
            <Avatar size="l" name={name} />
            <div className="ms-2 text-truncate" style={{ maxWidth: '300px' }}>
              {name}
            </div>
          </div>
        );
      }
    },
    {
      accessor: 'modality.name',
      Header: 'Modalidad'
    },
    {
      accessor: 'code',
      Header: 'SNIES'
    },
    {
      accessor: 'university.name',
      Header: 'Universidad'
    },
    {
      accessor: 'scholarship',
      Header: 'Beca Futura',
      Cell: rowData => {
        const { _id, scholarship } = rowData.row.original;
        return (
          <div className="d-flex align-items-center">
            <SwitchDt
              id={_id}
              status={scholarship}
              pt={false}
              tx={'Deshacer beca futura'}
              tx0={'Hacer beca futura'}
              handledEvent={() => {
                setItem(rowData.row.original);
                handleFModal();
              }}
            />
          </div>
        );
      }
    },
    {
      accessor: 'ops',
      Header: '',
      Cell: rowData => {
        // const { scholarship } = rowData.row.original;
        return (
          <>
            <CardDropdown>
              <div className="py-2">
                {/* {scholarship && ( */}
                {/* )} */}
                {/* <Dropdown.Item
                  onClick={() =>
                    handleClickProgram(rowData.row.original, 'agreements')
                  }
                >
                  Convenios
                </Dropdown.Item> */}
                <Dropdown.Item
                  onClick={() =>
                    handleClickProgram(rowData.row.original, 'charges')
                  }
                >
                  Cargos
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    handleClickProgram(rowData.row.original, 'spreads')
                  }
                >
                  Diferenciales
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleClick(rowData.row.original)}
                >
                  Becas o Auxilios
                </Dropdown.Item>
                {/* <Dropdown.Item
                  onClick={() =>
                    handleClickProgram(rowData.row.original, 'teachers')
                  }
                >
                  Profesores
                </Dropdown.Item> */}
              </div>
            </CardDropdown>
          </>
        );
      }
    },
    {
      accessor: 'btn',
      Header: 'Acciones',
      headerProps: { style: { width: '95px' } },
      Cell: rowData => {
        return (
          <Flex className="gap-1 mt-1.5">
            <Buttons
              btn={'update'}
              handleEvent={() => {
                rowData.row.original.pt = false;
                setItem(rowData.row.original);
                handleEModal();
              }}
            />
            <Buttons
              btn={'delete'}
              handleEvent={() => {
                setItem(rowData.row.original);
                handleDModal();
              }}
            />
          </Flex>
        );
      }
    }
  ];

  //* FUNTION FOR SAVE OR MODIFY REGISTER
  const submit = object_ => {
    if (object_.get('_id')) {
      object_._id = object_.get('_id');
      update('programs/update', object_).then(res => {
        res &&
          setItems(
            items.map(stateObj => (stateObj._id === res._id ? res : stateObj))
          );
        setItem({});
      });
    } else
      add('programs/add', object_).then(
        res => res && setItems([...items, res])
      );
  };

  const changeFutura = async () => {
    try {
      let config = headers();

      if (config.headers.token.length > 50) {
        const { data } = await http.put(
          '/programs/update',
          { _id: item._id, scholarship: !item.scholarship },
          config
        );

        //! Async width .State.
        const updateObj = items.map(stateObj =>
          stateObj._id === data.data._id ? data.data : stateObj
        );
        setItems(updateObj);
        toast.success(data.message);
      } else toast.error('Invalid token!');
    } catch (e) {
      console.log(e);
    } finally {
      handleFModal();
      setItem({});
    }
  };

  //* GET ALL REGISTER
  useEffect(() => {
    if (location.pathname === '/cms/programs')
      list('programs/list').then(res => {
        res && setItems(res);
      });
  }, [location]);

  //* DELETE
  const removeOne = () => {
    remove(`programs/delete/${item._id}`).then(() => {
      setItems(items.filter(stateObj => stateObj._id !== item._id));
      setItem({});
    });
  };

  return (
    <ProgramsContext.Provider
      value={{
        item,
        items,
        setItem,
        setItems,
        name,
        columns,
        Form_: Form_,
        submit,
        changeFutura,
        removeOne,
        confirm: true,
        caption: 'Programa',
        captions: 'Programas',
        modalSize
      }}
    >
      {children}
    </ProgramsContext.Provider>
  );
};

ProgramsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { ProgramsProvider };
export default ProgramsContext;
