import React from 'react';
import PropTypes from 'prop-types';
import CountUp from 'react-countup';
import { Card, Col, Row } from 'react-bootstrap';

import SoftBadge from '../../common/SoftBadge';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent
} from 'echarts/components';
import Background from '../../common/Background';
import bg1 from 'assets/img/icons/spot-illustrations/corner-1.png';
import classNames from 'classnames';
import bg2 from 'assets/img/icons/spot-illustrations/corner-2.png';
import bg3 from 'assets/img/icons/spot-illustrations/corner-3.png';

echarts.use([TitleComponent, TooltipComponent, GridComponent, LineChart]);

const ReferralWidgets = ({ items }) => {
  return (
    <>
      {/* Total visit */}
      <Row className="g-3 mb-3">
        {/* Registed */}
        <Col md={4}>
          <Card
            className={classNames('text-warning', 'overflow-hidden')}
            style={{ minWidth: '12rem' }}
          >
            <Background image={bg1} className="bg-card" />
            <Card.Body className="position-relative">
              <h6>
                Registrados
                <SoftBadge bg={'warning'} pill className="ms-2">
                  100%
                </SoftBadge>
              </h6>
              <div
                className={classNames(
                  'text-warning display-4 fs-4 mb-2 fw-normal font-sans-serif'
                )}
              >
                <CountUp
                  start={0}
                  end={items.length}
                  duration={2.75}
                  suffix={''}
                  prefix={' '}
                  decimals={0}
                  decimal="."
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
        {/* totals referrals */}
        <Col md={4}>
          <Card
            className={classNames('text-warning', 'overflow-hidden')}
            style={{ minWidth: '12rem' }}
          >
            <Background image={bg2} className="bg-card" />
            <Card.Body className="position-relative">
              <h6>
                Total Referidos
                <SoftBadge bg={'info'} pill className="ms-2">
                  {Math.round(
                    (items.reduce((acum, act) => acum + act.referral, 0) *
                      100) /
                      items.length || 0
                  )}{' '}
                  %
                </SoftBadge>
              </h6>
              <div
                className={classNames(
                  'text-info display-4 fs-4 mb-2 fw-normal font-sans-serif'
                )}
              >
                <CountUp
                  start={0}
                  end={items.reduce((acum, act) => acum + act.referral, 0)}
                  duration={2.75}
                  suffix={''}
                  prefix={' '}
                  decimals={0}
                  decimal="."
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
        {/* top referrals */}
        <Col md={4}>
          <Card
            className={classNames('text-success', 'overflow-hidden')}
            style={{ minWidth: '12rem' }}
          >
            <Background image={bg3} className="bg-card" />
            <Card.Body className="position-relative">
              <h6>
                Top Referidos
                <SoftBadge bg={'success'} pill className="ms-2">
                  {items[0]?.name}
                </SoftBadge>
              </h6>
              <div
                className={classNames(
                  'text-700 display-4 fs-4 mb-2 fw-normal font-sans-serif'
                )}
              >
                <CountUp
                  start={0}
                  end={items[0]?.referral || 0}
                  duration={2.75}
                  suffix={''}
                  prefix={' '}
                  decimals={0}
                  decimal="."
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

ReferralWidgets.propTypes = {
  items: PropTypes.array
};

export default ReferralWidgets;
