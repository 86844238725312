import React, { useContext, useState, useEffect } from 'react';
import { Button, Col, Form, Row, Spinner, Tab, Tabs } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useCities, useDefault, useSectors } from 'hooks';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import AppContext from 'context/Context';
import { Editor } from '@tinymce/tinymce-react';
import { getColor } from 'helpers/utils';
import { toast } from 'react-toastify';

const University = ({ submit, item, items, modal }) => {
  const {
    config: { isDark, isRTL }
  } = useContext(AppContext);

  const { loadBotton } = useDefault();
  const [availabledMsg, setAvailabledMsg] = useState('');
  const [availabled, setAvailabled] = useState(true);
  const [availabledMsgCities, setAvailabledMsgCities] = useState('');
  const [availabledCities, setAvailabledCities] = useState(true);
  const [availabledMsgSectors, setAvailabledMsgSectors] = useState('');
  const [availabledSectors, setAvailabledSectors] = useState(true);
  const [optionCities, setOptionCities] = useState(null);
  const [optionSector, setOptionSector] = useState(null);
  const { items: cities } = useCities();
  const { items: sectors } = useSectors();
  const [image, setImage] = useState(null);
  const [logo, setLogo] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [availabledImage, setAvailabledImage] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState();
  const [backgroundImageUrl, setBackgroundImageUrl] = useState();
  const [accredited, setAccredited] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
    clearErrors
  } = useForm({ defaultValues: { id: null } });

  let pt = false;
  useEffect(() => {
    if (modal && !pt) {
      pt = true;
      toast.warning(
        'La opcíón de Guardar no estará habilitada hasta tanto los campos señalados como obligatorio (*) estén llenos.'
      );
    }
  }, [modal]);

  //*EVENT CLICK SAVE AND UPDATE
  const onSubmit = data => {
    const formData = new FormData();
    if (item._id) {
      formData.append('_id', item._id);
    }
    if (
      optionCities !== null &&
      optionSector !== null &&
      availabled &&
      !image &&
      !availabledImage
    ) {
      formData.append('logo', logo);
      formData.append('background_image', backgroundImage);
      formData.append('cities', optionCities.value);
      formData.append('sectors', optionSector.value);
      formData.append('about', data.about);
      formData.append('name', data.name);
      formData.append('year_foundation', data.year_foundation);
      formData.append('graduates', data.graduates);
      formData.append(
        'virtual_program_information',
        data.virtual_program_information
      );
      formData.append('accredited', accredited);
      formData.append('title_information', data.title_information);
      formData.append('study_pace', data.study_pace);
      formData.append('video_about', data.video_about);
      formData.append(
        'url_facebook',
        data.url_facebook !== null ? data.url_facebook : ''
      );
      formData.append(
        'url_linkedin',
        data.url_linkedin !== null ? data.url_linkedin : ''
      );
      formData.append(
        'url_instagram',
        data.url_instagram !== null ? data.url_instagram : ''
      );

      submit(formData);
      setImage(null);
      setAvailabledImage(null);
      saveImage(`${process.env.REACT_APP_BACKEND_URL}/storage/estandar.png`);
      saveImageBackground(
        `${process.env.REACT_APP_BACKEND_URL}/storage/fondo_test.jpg`
      );
      reset();
    } else {
      if (optionCities === null) {
        setAvailabledCities(false);
        setAvailabledMsgCities('debe seleccionar una ciudad !');
      }
      if (optionSector === null) {
        setAvailabledSectors(false);
        setAvailabledMsgSectors('debe seleccionar un sector !');
      }
    }
  };
  const handleAccreditedChange = e => {
    setAccredited(e.target.checked);
  };
  const saveImage = async logoUrl => {
    setImageUrl(logoUrl);
    const response = await fetch(logoUrl);
    const blob = await response.blob();
    const file = new File([blob], 'logo.jpg', { type: 'image/jpeg' });
    setLogo(file);
  };

  const saveImageBackground = async logoUrl => {
    setBackgroundImageUrl(logoUrl);
    const response = await fetch(logoUrl);
    const blob = await response.blob();
    const file = new File([blob], 'fondo.jpg', { type: 'image/jpeg' });
    setBackgroundImage(file);
  };

  const verifyImage = async logo => {
    setImage(null);
    const file = logo;
    if (file) {
      setImageUrl(URL.createObjectURL(file));
      if (file.type && file.type.startsWith('image/')) {
        try {
          if (file.name !== backgroundImage.name) {
            if (file.size < 21000000) {
              setLogo(file);
            } else {
              setImage('El tamaño del fichero excede los 1mb!');
            }
          } else {
            setImage('El nombre de las imágenes no pueden ser iguales!');
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        setImage('Este fichero no es una imagen!');
      }
    }
  };

  const verifyImageBackground = async image => {
    setAvailabledImage(null);
    const file = image;
    if (file) {
      setBackgroundImageUrl(URL.createObjectURL(file));
      if (file.type && file.type.startsWith('image/')) {
        try {
          if (file.name !== logo.name) {
            if (file.size < 21000000) {
              setBackgroundImage(file);
            } else {
              setAvailabledImage('El tamaño del fichero excede los 1mb!');
            }
          } else {
            setAvailabledImage(
              'El nombre de las imágenes no pueden ser iguales!'
            );
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        setAvailabledImage('Este fichero no es una imagen!');
      }
    }
  };
  //* EVENT GET ALL RGISTER
  useEffect(() => {
    let logoUrl;
    let backgroundURL;
    if (item?._id) {
      setValue('id', item._id);
      setValue('name', item.name);
      setValue('about', item.about);
      setValue('agreement', item.agreement);
      setValue('year_foundation', item.year_foundation);
      setValue('graduates', item.graduates);
      setValue('virtual_program_information', item.virtual_program_information);
      setValue('title_information', item.title_information);
      setValue('study_pace', item.study_pace);
      setValue('url_facebook', item.url_facebook);
      setValue('url_linkedin', item.url_linkedin);
      setValue('url_instagram', item.url_instagram);
      setValue('video_about', item.video_about);
      setValue('accredited ', item.accredited);
      setAccredited(item.accredited);

      const selectedOption = cities.find(find => find._id === item.cities?._id);
      if (selectedOption) {
        setOptionCities({
          value: selectedOption._id,
          label: selectedOption.name
        });
      }
      const selectedSector = sectors.find(
        find => find._id === item.sectors?._id
      );
      if (selectedSector) {
        setOptionSector({
          value: selectedSector._id,
          label: selectedSector.name
        });
      }
      logoUrl = `${process.env.REACT_APP_BACKEND_URL}/storage/logos/${item.logo}`;
      backgroundURL = `${process.env.REACT_APP_BACKEND_URL}/storage/backgrounds/${item.background_image}`;
    } else {
      logoUrl = `${process.env.REACT_APP_BACKEND_URL}/storage/estandar.png`;
      backgroundURL = `${process.env.REACT_APP_BACKEND_URL}/storage/fondo_test.jpg`;
    }
    saveImage(logoUrl);
    saveImageBackground(backgroundURL);
  }, [watch('id')]);

  useEffect(() => {
    if (!item?._id || item?.name !== watch('name')) {
      setAvailabled(true);
      setAvailabledMsg('');
      items.forEach(dat => {
        if (dat.name === watch('name')) {
          setAvailabled(false);
          setAvailabledMsg('este nombre está en uso actualmente!');
        }
      });
    }
  }, [watch('name')]);

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)} className="px-1">
      <Row>
        <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
          <Tab eventKey="home" title="Institucional (*)">
            <Row>
              <Col>
                <div className="row w-100">
                  <div id="name-row" className="mb-1">
                    <Form.Group>
                      <Form.Label className="mb-1">Nombre (*)</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Nombre de la Universidad"
                        isInvalid={!!errors.name}
                        {...register('name', {
                          required: {
                            value: true,
                            message: 'este campo es requerido!'
                          },
                          minLength: {
                            limit: 1,
                            value: 1,
                            message: 'Mínimo 1 carácter!'
                          }
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.name && errors.name.message}
                      </Form.Control.Feedback>
                      {availabled ? null : (
                        <h6 className="text-danger fs--2 mt-2 mb-0">
                          {availabledMsg}
                        </h6>
                      )}
                    </Form.Group>
                  </div>
                  <div id="cities-row">
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-1 mt-2">Ciudad (*)</Form.Label>
                      <ReactSelect
                        closeMenuOnSelect={true}
                        options={cities.map(find => ({
                          value: find._id,
                          label: find.name
                        }))}
                        placeholder="Seleccionar...."
                        classNamePrefix="react-select"
                        value={optionCities}
                        id="cities"
                        onChange={optionCity => setOptionCities(optionCity)}
                      />
                      {availabledCities ? null : (
                        <h6 className="text-danger fs--2 mt-2 mb-0">
                          {availabledMsgCities}
                        </h6>
                      )}
                    </Form.Group>
                  </div>
                </div>
              </Col>
              <Col>
                <div id="sectors-row">
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-1 mt-2">Sector (*)</Form.Label>
                    <ReactSelect
                      closeMenuOnSelect={true}
                      options={sectors.map(find => ({
                        value: find._id,
                        label: find.name
                      }))}
                      placeholder="Seleccionar...."
                      classNamePrefix="react-select"
                      value={optionSector}
                      id="sectors"
                      onChange={option => setOptionSector(option)}
                    />
                    {availabledSectors ? null : (
                      <h6 className="text-danger fs--2 mt-2 mb-0">
                        {availabledMsgSectors}
                      </h6>
                    )}
                  </Form.Group>
                </div>
                <div id="accredited-row" className="mb-1">
                  {' '}
                  <Form.Check
                    required
                    id="accredited"
                    label="Universidad Acreditada"
                    checked={accredited}
                    onChange={handleAccreditedChange}
                  />
                </div>
              </Col>
            </Row>
            <div id="about-row" className="mb-1">
              <Form.Group>
                <Form.Label className="mb-1">
                  Sobre la universidad (*)
                </Form.Label>

                <div style={{ border: errors.about ? '2px solid red' : '' }}>
                  <Editor
                    id="about"
                    value={watch('about')}
                    onEditorChange={newValue => {
                      setValue('about', newValue);
                      if (errors.about) clearErrors('about');
                    }}
                    apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                    init={{
                      height: '200px',
                      menubar: false,
                      content_style: `
                        .mce-content-body {
                          color: ${
                            isDark ? 'white' : getColor('emphasis-color')
                          };                          
                          background-color: ${getColor('tinymce-bg')};
                        }`,
                      statusbar: false,
                      plugins: 'lists directionality',
                      toolbar:
                        'styleselect | bold italic link bullist numlist image table media undo redo',

                      directionality: isRTL ? 'rtl' : 'ltr',
                      theme_advanced_toolbar_align: 'center'
                    }}
                    {...register('about', { required: true })}
                  />
                </div>
                {errors.about && (
                  <h6 className="text-danger fs--2 mt-2 mb-0">
                    este campo es requerido!
                  </h6>
                )}
                {/* <Form.Control
                  size="sm"
                  as="textarea"
                  style={{ height: '100px' }}
                  id="about"
                  name="about"
                  rows={3}
                  placeholder="Sobre la universidad"
                  isInvalid={!!errors.about}
                  {...register('about', {
                    required: {
                      value: true,
                      message: 'este campo es requerido!'
                    },
                    minLength: {
                      limit: 2,
                      value: 2,
                      message: 'Mínimo 1 carácter!'
                    }
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.about && errors.about.message}
                </Form.Control.Feedback> */}
              </Form.Group>
            </div>
          </Tab>
          <Tab eventKey="usp" title="USPs (*)">
            <Row>
              <div id="year_foundation-row" className="mb-1">
                <Form.Group>
                  <Form.Label className="mb-1">USP 1 (*)</Form.Label>
                  <Form.Control
                    size="sm"
                    style={{ height: '40px' }}
                    as="textarea"
                    id="year_foundation"
                    name="year_foundation"
                    placeholder="Año de fundación"
                    isInvalid={!!errors.year_foundation}
                    {...register('year_foundation', {
                      required: {
                        value: true,
                        message: 'este campo es requerido!'
                      },
                      minLength: {
                        limit: 1,
                        value: 1,
                        message: 'Mínimo 1 carácter!'
                      }
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.year_foundation && errors.year_foundation.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div id="virtual_program_information-row" className="mb-1">
                <Form.Group>
                  <Form.Label className="mb-1">USP 2 (*)</Form.Label>
                  <Form.Control
                    size="sm"
                    style={{ height: '40px' }}
                    as="textarea"
                    id="virtual_program_information"
                    name="virtual_program_information"
                    placeholder="Ciclos propedeuticos"
                    isInvalid={!!errors.virtual_program_information}
                    {...register('virtual_program_information', {
                      required: {
                        value: true,
                        message: 'este campo es requerido!'
                      },
                      minLength: {
                        limit: 1,
                        value: 1,
                        message: 'Mínimo 1 carácter!'
                      }
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.virtual_program_information &&
                      errors.virtual_program_information.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div id="graduates-row" className="mb-1">
                <Form.Group>
                  <Form.Label className="mb-1">USP 3 (*)</Form.Label>
                  <Form.Control
                    size="sm"
                    style={{ height: '40px' }}
                    as="textarea"
                    id="graduates"
                    name="graduates"
                    placeholder="Cantidad egresados"
                    isInvalid={!!errors.graduates}
                    {...register('graduates', {
                      required: {
                        value: true,
                        message: 'este campo es requerido!'
                      },
                      minLength: {
                        limit: 1,
                        value: 1,
                        message: 'Mínimo 1 carácter!'
                      }
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.graduates && errors.graduates.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </Row>
            <div id="title_information-row" className="mb-3">
              <Form.Group>
                <Form.Label className="mb-1">USP 4 (*)</Form.Label>
                <Form.Control
                  size="sm"
                  style={{ height: '40px' }}
                  as="textarea"
                  id="title_information"
                  name="title_information"
                  placeholder="Programas acreditados"
                  isInvalid={!!errors.title_information}
                  {...register('title_information', {
                    required: {
                      value: true,
                      message: 'este campo es requerido!'
                    },
                    minLength: {
                      limit: 1,
                      value: 1,
                      message: 'Mínimo 1 carácter!'
                    }
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.title_information && errors.title_information.message}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div id="study_pace-row" className="mb-1">
              <Form.Group>
                <Form.Label className="mb-1">USP 5 (*)</Form.Label>
                <Form.Control
                  size="sm"
                  style={{ height: '40px' }}
                  as="textarea"
                  id="study_pace"
                  name="study_pace"
                  placeholder="Modalidades de estudio"
                  isInvalid={!!errors.study_pace}
                  {...register('study_pace', {
                    required: {
                      value: true,
                      message: 'este campo es requerido!'
                    },
                    minLength: {
                      limit: 1,
                      value: 1,
                      message: 'Mínimo 1 carácter!'
                    }
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.study_pace && errors.study_pace.message}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </Tab>
          <Tab eventKey="image" title="Marca (*)">
            <Row>
              {' '}
              <Col>
                <div className="w-100">
                  <Form.Group>
                    <Form.Label
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: 'auto',
                        marginBottom: '10px',
                        marginTop: '10px',
                        color: '#000'
                      }}
                    >
                      {' '}
                      Logo
                    </Form.Label>
                    {imageUrl && image === null && (
                      <div
                        style={{
                          width: '150px',
                          height: '150px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          margin: 'auto'
                        }}
                      >
                        <img
                          src={imageUrl}
                          alt="Preview"
                          style={{
                            objectFit: 'cover',
                            maxWidth: '100%',
                            maxHeight: '100%'
                          }}
                        />
                      </div>
                    )}
                  </Form.Group>
                  <Form.Label
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: 'auto',
                      marginTop: '10px',
                      color: '#000'
                    }}
                  >
                    {' '}
                    Resolución Máxima: 400x400
                  </Form.Label>
                  <Form.Label
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: 'auto',
                      color: '#000'
                    }}
                  >
                    {' '}
                    Tamaño máximo: 1mb
                  </Form.Label>
                  <div id="logo-row" className="mb-3">
                    <Form.Group>
                      <Form.Label className="mb-1">Logo</Form.Label>
                      <Form.Control
                        size="sm"
                        type="file"
                        id="logo"
                        name="logo"
                        onChange={e => {
                          verifyImage(e.target.files[0]);
                        }}
                      />
                      {image !== null && (
                        <h6 className="text-danger fs--2 mt-2 mb-0">{image}</h6>
                      )}
                    </Form.Group>
                  </div>
                  <div id="url_facebook-row" className="mb-1">
                    <Form.Group>
                      <Form.Label className="mb-1">URL de facebook</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        id="url_facebook"
                        name="url_facebook"
                        placeholder="URL de facebook"
                        isInvalid={!!errors.url_facebook}
                        {...register('url_facebook', {
                          minLength: {
                            limit: 1,
                            value: 1,
                            message: 'Mínimo 1 carácter!'
                          },
                          pattern: {
                            value:
                              '^(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})([/\\w .-]*)*/?$',
                            message: 'URL Inválida!'
                          }
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.url_facebook && errors.url_facebook.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div id="url_linkedin-row" className="mb-1">
                    <Form.Group>
                      <Form.Label className="mb-1">URL de linkedin</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        id="url_linkedin"
                        name="url_linkedin"
                        placeholder="URL de linkedin"
                        isInvalid={!!errors.url_linkedin}
                        {...register('url_linkedin', {
                          minLength: {
                            limit: 1,
                            value: 1,
                            message: 'Mínimo 1 carácter!'
                          },
                          pattern: {
                            value:
                              '^(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})([/\\w .-]*)*/?$',
                            message: 'URL Inválida!'
                          }
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.url_linkedin && errors.url_linkedin.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="w-100">
                  <Form.Group>
                    <Form.Label
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: 'auto',
                        marginBottom: '5px',
                        marginTop: '10px',
                        color: '#000'
                      }}
                    >
                      {' '}
                      Imagen de Fondo
                    </Form.Label>
                    {backgroundImageUrl && availabledImage === null && (
                      <div
                        style={{
                          width: '150px',
                          height: '150px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          margin: 'auto'
                        }}
                      >
                        <img
                          src={backgroundImageUrl}
                          alt="Preview"
                          style={{
                            objectFit: 'cover',
                            maxWidth: '100%',
                            maxHeight: '100%'
                          }}
                        />
                      </div>
                    )}
                    <Form.Label
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: 'auto',
                        marginTop: '10px',
                        color: '#000'
                      }}
                    >
                      {' '}
                      Resolución Máxima: 1920x762
                    </Form.Label>
                    <Form.Label
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: 'auto',
                        color: '#000'
                      }}
                    >
                      {' '}
                      Tamaño máximo: 1mb
                    </Form.Label>
                  </Form.Group>
                  <div id="background_image-row" className="mb-3">
                    <Form.Group>
                      <Form.Label className="mb-1">Imagen de Fondo</Form.Label>
                      <Form.Control
                        size="sm"
                        type="file"
                        id="background_image"
                        name="background_image"
                        onChange={e => {
                          verifyImageBackground(e.target.files[0]);
                        }}
                      />
                      {availabledImage !== null && (
                        <h6 className="text-danger fs--2 mt-2 mb-0">
                          {availabledImage}
                        </h6>
                      )}
                    </Form.Group>
                  </div>
                  <div id="url_instagram-row" className="mb-1">
                    <Form.Group>
                      <Form.Label className="mb-1">URL de instagram</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        id="url_instagram"
                        name="url_instagram"
                        placeholder="URL de instagram"
                        isInvalid={!!errors.url_instagram}
                        {...register('url_instagram', {
                          minLength: {
                            limit: 1,
                            value: 1,
                            message: 'Mínimo 1 carácter!'
                          },
                          pattern: {
                            value:
                              '^(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})([/\\w .-]*)*/?$',
                            message: 'URL Inválida!'
                          }
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.url_instagram && errors.url_instagram.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div id="video_about-row" className="mb-3">
                    <Form.Group>
                      <Form.Label className="mb-1">Url Video (*)</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        id="video_about"
                        name="video_about"
                        placeholder="URL de instagram"
                        isInvalid={!!errors.video_about}
                        {...register('video_about', {
                          required: {
                            value: true,
                            message: 'este campo es requerido!'
                          },
                          minLength: {
                            limit: 1,
                            value: 1,
                            message: 'Mínimo 1 carácter!'
                          },
                          pattern: {
                            value:
                              '^(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})([/\\w .-]*)*/?$',
                            message: 'URL Inválida!'
                          }
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.video_about && errors.video_about.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </Row>

      <Button
        type="submit"
        size="sm"
        variant="primary"
        disabled={
          loadBotton ||
          Object.keys(errors).length > 0 ||
          optionCities === null ||
          optionSector === null ||
          !availabled ||
          image ||
          !watch('about') ||
          !watch('title_information') ||
          !watch('virtual_program_information') ||
          !watch('graduates') ||
          !watch('study_pace') ||
          !watch('year_foundation') ||
          !watch('video_about') ||
          availabledImage
        }
        className="rounded-pill me-1 mb-3 w-100 d-inline-flex flex-center gap-1 p-1"
      >
        {loadBotton && (
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        )}
        {loadBotton
          ? 'Cargando…'
          : watch('id')
          ? 'Actualizar Datos'
          : 'Guardar Datos'}
      </Button>
    </Form>
  );
};

University.propTypes = {
  submit: PropTypes.func.isRequired,
  item: PropTypes.object,
  items: PropTypes.array,
  modal: PropTypes.bool
};

export default University;
