import { Spinner } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import React from 'react';
import PropTypes from 'prop-types';

const BackDrop = ({ param }) => {
  return (
    <Flex className="bg-200">
      <div
        className={
          param === ''
            ? 'bg-400 align-self-center w-100 min-vh-100'
            : 'align-self-center w-100 min-vh-100'
        }
      >
        <div className="mt-10">
          <Flex className="gap-2" justifyContent="center">
            <Spinner animation="grow" />
            <h3>Cargando {param}...</h3>
          </Flex>

          <h6 className="text-center">
            &nbsp;Esta operación puede tardar algunos segundos, por favor
            espere!
          </h6>
        </div>
      </div>
    </Flex>
  );
};
BackDrop.propTypes = {
  param: PropTypes.string.isRequired
};

export default BackDrop;
