import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Collapse, Form, Image, Card } from 'react-bootstrap';
// import { courseFilters } from 'data/elearning/courseData';
import { slugifyText } from 'helpers/utils';
import Flex from 'components/common/Flex';
import SubtleBadge from 'components/common/SubtleBadge';

// export const courseFilters = [];
export const CourseFilters = ({
  setShow,
  isOffcanvas,
  data,
  setLevels,
  setPriceLevels,
  priceLevelsAll,
  setPriceModalitys,
  priceModalitysAll,
  setModalitys,
  setCities,
  setUniversities,
  setPrograms,
  setListPrograms,
  pricePrograms
}) => {
  // courseFilters[0].options = data.cities;
  // courseFilters[1].options = data.universities;
  // courseFilters[2].options = data.modalitys;
  // courseFilters[3].options = data.levels;
  // courseFilters[4].options = [];
  const [filterOptions, setFilterOptions] = useState([]);
  const [courseFilters, setCourseFilters] = useState([
    {
      label: 'Ciudades',
      options: []
    },
    {
      label: 'Universidades',
      options: []
    },
    {
      label: 'Modalidad',
      options: []
    },
    {
      label: 'Nivel de Formación',
      options: []
    },
    {
      label: 'Programas',
      options: []
    }
  ]);
  useEffect(() => {
    setCourseFilters([
      {
        label: 'Ciudades',
        options: data.cities
      },
      {
        label: 'Universidades',
        options: data.universities
      },
      {
        label: 'Modalidad',
        options: data.modalitys
      },
      {
        label: 'Nivel de Formación',
        options: data.levels
      },
      {
        label: 'Programas',
        options: []
      }
    ]);
  }, []);

  const handleFilterOptions = e => {
    const { type, name, value, checked } = e.target;
    if (type === 'checkbox') {
      let options = [...filterOptions];
      options = options.filter(option => option.value !== value);
      const aux = options.filter(option => option.name === 'university');
      if (aux.length === 0)
        options = options.filter(option => option.name !== 'program');
      checked && options.push({ name, value });
      setFilterOptions(options);
    }
  };
  useEffect(() => {
    const listUni = data.universities;
    if (filterOptions.length > 0) {
      let city = false;
      let cities = [];
      let citiesFilter = [];
      filterOptions.forEach(element => {
        if (element.name === 'cities') {
          const item = data.cities.filter(item => item.value === element.value);
          cities.push(item[0]);
          listUni.forEach(uni => {
            if (uni.city === item[0].value) {
              citiesFilter.push(uni);
            }
          });
          // let filter;
          // if (level) {
          //   filter = programsFilter.filter(i => i.city === item[0]?.label);
          //   filter.forEach(i => {
          //     citiesFilter.push(i);
          //   });
          // } else {
          // filter = pricePrograms.filter(i => i.city === item[0]?.label);
          // filter.forEach(i => {
          //   programsFilter.push(i);
          // });
          // }
          // if (filter.length > 0) {
          //   city = true;
          // }
          setCities(cities);
        }
      });
      if (citiesFilter.length > 0) {
        courseFilters[1].options = citiesFilter;
      }
      if (cities.length === 0) {
        courseFilters[1].options = listUni;
        setCities(data.cities.slice(0, 4));
      }

      let universities = [];
      let universityFilter = [];
      let programs = [];
      let programsFilter = [];

      filterOptions.forEach(element => {
        if (element.name === 'university') {
          const item = data.universities.filter(
            item => item.value === element.value
          );
          universities.push(item[0]);
          let filter;
          if (city) {
            filter = programsFilter.filter(
              i => i.university === item[0]?.label
            );
            filter.forEach(i => {
              universityFilter.push(i);
              programs.push({
                id: i.id,
                label: i.project,
                type: 'checkbox',
                value: i.project,
                team: i.team,
                name: 'program',
                modality: i.modality,
                education_level: i.education_level
              });
            });
          } else {
            filter = pricePrograms.filter(i => i.university === item[0]?.label);
            filter.forEach(i => {
              programsFilter.push(i);
              programs.push({
                id: i.id,
                label: i.project,
                type: 'checkbox',
                value: i.project,
                team: i.team,
                name: 'program',
                modality: i.modality,
                education_level: i.education_level
              });
            });
          }
          setUniversities(universities);
        }
      });
      let modalityFilter = [];
      let levelFilter = [];
      if (programs.length > 0) {
        data.modalitys.forEach(element => {
          const programsM = programs.filter(
            item => item.modality === element.value
          );
          let totalPrice = 0;
          let priceProm = 0;
          if (programsM.length > 0) {
            programsM.forEach(i => {
              if ((i.team === null) & (i.team == undefined)) {
                totalPrice += 0;
              } else {
                const price = i.team.replace(/\./g, '');
                if (isNaN(price)) {
                  totalPrice += 0;
                } else {
                  totalPrice += Number(price);
                }
              }
              // totalPrice += i.price;
            });
            priceProm = totalPrice / programsM.length;
          }
          let price = priceProm.toString().split('.');
          price = Number(price[0]);
          price = price.toLocaleString('es-ES');
          // if (price !== '0')
          modalityFilter.push({
            id: element.id,
            label: element.label,
            name: element.name,
            team: price,
            type: element.type,
            value: element.value
          });
        });
        data.levels.forEach(element => {
          const programsM = programs.filter(
            item => item.education_level === element.value
          );
          let totalPrice = 0;
          let priceProm = 0;
          if (programsM.length > 0) {
            programsM.forEach(i => {
              if ((i.team === null) & (i.team == undefined)) {
                totalPrice += 0;
              } else {
                const price = i.team.replace(/\./g, '');
                if (isNaN(price)) {
                  totalPrice += 0;
                } else {
                  totalPrice += Number(price);
                }
              }
              // totalPrice += i.price;
            });
            priceProm = totalPrice / programsM.length;
          }
          let price = priceProm.toString().split('.');
          price = Number(price[0]);
          price = price.toLocaleString('es-ES');
          // if (price !== '0')
          levelFilter.push({
            id: element.id,
            label: element.label,
            name: element.name,
            team: price,
            type: element.type,
            value: element.value
          });
        });
        setModalitys(modalityFilter.slice(0, 4));
        setPriceModalitys(modalityFilter);
        setLevels(levelFilter.slice(0, 4));
        setPriceLevels(levelFilter);
        courseFilters[4].options = programs;
        courseFilters[2].options = modalityFilter;
        courseFilters[3].options = levelFilter;
      }
      if (universityFilter.length > 0) {
        programsFilter = universityFilter;
      }
      if (universities.length === 0) {
        courseFilters[4].options = [];
        setUniversities(data.universities.slice(0, 4));
      }
      let pFilter = [];
      const test = [['product', 'Marca']];
      filterOptions.forEach(element => {
        if (element.name === 'program') {
          programsFilter.forEach(item => {
            if (item.project === element.value) {
              if (test.length <= 4) {
                const price = item.team.replace(/\./g, '');
                if (isNaN(price)) {
                  test.push([item.project, 0]);
                } else {
                  test.push([item.project, price]);
                }
              }
              pFilter.push(item);
            }
          });
        }
      });
      if (pFilter.length > 0) {
        programsFilter = pFilter;
        setPrograms(test);
      } else {
        setPrograms([]);
        setListPrograms(programsFilter);
      }
      setListPrograms(programsFilter);
      let pAux = [];
      let modalitys = [];

      filterOptions.forEach(element => {
        if (element.name === 'modality') {
          let item;
          if (modalityFilter.length > 0) {
            item = modalityFilter.filter(item => item.value === element.value);
          } else {
            item = data.modalitys.filter(item => item.value === element.value);
          }
          modalitys.push(item[0]);
          let filter;
          filter = programsFilter.filter(i => i.modality === item[0]?.label);
          filter.forEach(i => {
            pAux.push(i);
          });
          setModalitys(modalitys);
        }
      });
      if (pAux.length > 0) {
        programsFilter = pAux;
        programs = [];
        programsFilter.forEach(i => {
          programs.push({
            id: i.id,
            label: i.project,
            type: 'checkbox',
            value: i.project,
            team: i.team,
            name: 'program',
            modality: i.modality,
            education_level: i.education_level
          });
        });
        courseFilters[4].options = programs;
        setListPrograms(programsFilter);
      }
      let levels = [];
      pAux = [];
      filterOptions.forEach(element => {
        if (element.name === 'levels') {
          let item;
          if (levelFilter.length > 0) {
            item = levelFilter.filter(item => item.value === element.value);
          } else {
            item = data.levels.filter(item => item.value === element.value);
          }
          levels.push(item[0]);
          let filter;
          filter = programsFilter.filter(
            i => i.education_level === item[0]?.label
          );
          filter.forEach(i => {
            pAux.push(i);
          });
          setLevels(levels);
        }
      });
      if (pAux.length > 0) {
        programsFilter = pAux;
        programs = [];
        programsFilter.forEach(i => {
          programs.push({
            id: i.id,
            label: i.project,
            type: 'checkbox',
            value: i.project,
            team: i.team,
            name: 'program',
            modality: i.modality,
            education_level: i.education_level
          });
        });
        courseFilters[4].options = programs;
        setListPrograms(programsFilter);
      }
      if (levels.length === 0) {
        if (levelFilter.length === 0) {
          // setLevels(data.levels.slice(0, 4));
        }
      }

      // if (modalityFilter.length > 0) {
      //   programsFilter = modalityFilter;
      // }
      // if (modalitys.length === 0) {
      //   if (modalityFilter.length === 0) {
      //     // setModalitys(data.modalitys.slice(0, 4));
      //   }
      // }
    } else {
      setPrograms([]);
      setListPrograms([]);
      courseFilters[1].options = listUni;
      courseFilters[2].options = data.modalitys;
      courseFilters[3].options = data.levels;
      courseFilters[4].options = [];
      setPriceLevels(priceLevelsAll);
      setPriceModalitys(priceModalitysAll);
      setLevels(data.levels.slice(0, 4));
      setModalitys(data.modalitys.slice(0, 4));
      setCities(data.cities.slice(0, 4));
      setUniversities(data.universities.slice(0, 4));
    }
  }, [filterOptions]);

  return (
    <Card className="course-filter">
      <SimpleBarReact style={{ height: '100%' }}>
        <Card.Header as={Flex} className="flex-between-center pt-x1">
          <Flex className="gap-2 flex-xl-grow-1 align-items-center justify-content-xl-between">
            <h5 className="mb-0 text-700 fs-9 d-flex align-items-center">
              <FontAwesomeIcon icon="filter" className="fs-10 me-1" />
              <span>Filtro</span>
            </h5>
            <Button
              variant="outline-secondary"
              size="sm"
              className="ms-2 mt-0 mb-0"
              style={{ fontSize: '12px' }}
              onClick={() => setFilterOptions([])}
            >
              <FontAwesomeIcon icon="redo-alt" className="me-1 fs-11" />
              Reiniciar
            </Button>
          </Flex>
          {isOffcanvas && (
            <Button
              onClick={() => setShow(false)}
              className="btn-close text-reset"
              size="sm"
              variant="link"
            ></Button>
          )}
        </Card.Header>
        <Card.Body className="py-0">
          {filterOptions.length > 0 && (
            <Flex wrap="wrap" className="gap-2 mb-3">
              {filterOptions.map(tag => (
                <>
                  <div
                    style={{
                      width: '100%',
                      overflow: 'auto',
                      whiteSpace: 'normal'
                    }}
                  >
                    <SubtleBadge
                      key={tag.value}
                      className={`${
                        localStorage.getItem('isDark') === 'true'
                          ? 'text-capitalize bg-300 text-700 py-0 text-400'
                          : 'text-capitalize bg-300 text-700 py-0 text-700'
                      }`}
                    >
                      {tag.value}
                      <Button
                        size="sm"
                        variant="link"
                        className="p-0 text-700"
                        onClick={() =>
                          setFilterOptions(
                            filterOptions.filter(
                              ({ value }) => value !== tag.value
                            )
                          )
                        }
                      >
                        <FontAwesomeIcon icon="times" className="ms-1 fs-11" />
                      </Button>
                    </SubtleBadge>
                  </div>
                </>
              ))}
            </Flex>
          )}
          <ul className="list-unstyled">
            {courseFilters.map((filter, index) => (
              <FilterItem
                key={slugifyText(filter.label)}
                index={index}
                courseFilters={courseFilters}
                filter={filter}
                filterOptions={filterOptions}
                setFilterOptions={setFilterOptions}
                handleFilterOptions={handleFilterOptions}
              />
            ))}
          </ul>
        </Card.Body>
      </SimpleBarReact>
    </Card>
  );
};

CourseFilters.propTypes = {
  setShow: PropTypes.func,
  isOffcanvas: PropTypes.bool,
  data: PropTypes.object.isRequired,
  setLevels: PropTypes.func,
  setPriceLevels: PropTypes.func,
  setPriceModalitys: PropTypes.func,
  setModalitys: PropTypes.func,
  setCities: PropTypes.func,
  setUniversities: PropTypes.func,
  setPrograms: PropTypes.func,
  setListPrograms: PropTypes.func,
  pricePrograms: PropTypes.array,
  priceLevelsAll: PropTypes.array,
  priceModalitysAll: PropTypes.array
};

const FilterItem = ({
  filter,
  courseFilters,
  index,
  filterOptions,
  handleFilterOptions
}) => {
  const [open, setOpen] = useState(false);

  return (
    <li className={`${courseFilters.length - 1 !== index && 'border-bottom'}`}>
      <Button
        variant="link"
        onClick={() => setOpen(!open)}
        aria-controls={`${slugifyText(filter.label)}-collapse`}
        aria-expanded={index === 4 ? !open : open}
        className="collapse-indicator-plus w-100 fs-11 fw-medium text-start text-600 text-decoration-none py-3 px-0"
      >
        {filter.label}
      </Button>
      <Collapse
        in={index === 4 ? !open : open}
        id={`${slugifyText(filter.label)}-collapse`}
      >
        <ul className="list-unstyled">
          {filter.options &&
            filter.options.map(option => (
              <li key={slugifyText(option.label)}>
                <Form.Check
                  type={option.type}
                  className="form-check d-flex ps-0"
                >
                  <Form.Check.Label
                    className="fs-10 flex-1 text-truncate"
                    htmlFor={`filter-${slugifyText(filter.label)}-${slugifyText(
                      option.label
                    )}`}
                  >
                    {option.icon && (
                      <FontAwesomeIcon
                        icon={option.icon}
                        className={`me-3 ${
                          option.iconShrink ? 'fs-11' : 'fs-10'
                        }`}
                      />
                    )}
                    {option.svg && (
                      <Image
                        src={option.svg}
                        width={13}
                        alt=""
                        className="me-3"
                      />
                    )}
                    <div
                      style={{
                        width: '90%',
                        overflow: 'auto',
                        whiteSpace: 'normal'
                      }}
                      className={`${
                        localStorage.getItem('isDark') === 'true'
                          ? 'text-400'
                          : 'text-700'
                      }`}
                    >
                      {option.label}
                    </div>
                  </Form.Check.Label>

                  <Form.Check.Input
                    type={option.type}
                    onChange={e => handleFilterOptions(e)}
                    checked={
                      option.type === 'checkbox'
                        ? filterOptions.some(
                            ({ value }) => option.value === value
                          )
                        : undefined
                    }
                    id={`filter-${slugifyText(filter.label)}-${slugifyText(
                      option.label
                    )}`}
                    name={option.name}
                    value={option.value}
                  />
                </Form.Check>
              </li>
            ))}
        </ul>
      </Collapse>
    </li>
  );
};

FilterItem.propTypes = {
  index: PropTypes.number,
  courseFilters: PropTypes.array,
  filter: PropTypes.shape({
    label: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        name: PropTypes.string,
        type: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      })
    )
  }),
  handleFilterOptions: PropTypes.func,
  filterOptions: PropTypes.array
};

export default CourseFilters;
